import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import axios from "axios";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { configureStore, history } from './store';
import ThemeColors from './config/ThemeColors'

import './scss/index.scss';
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 
// 'https://f701-192-200-115-226.ngrok-free.app' : 
'http://localhost:3080' : 
'http://dev.acan.group:3080';


const store = configureStore();
const theme = createTheme({
  palette: {
    primary: ThemeColors.primary,
    secondary: ThemeColors.secondary,
    success: ThemeColors.success,
    error: ThemeColors.error,
    contrast: ThemeColors.contrast,
  },
  typography: {
    fontSize: 14,
  },
  shape: {
    borderRadius: 0
  },
  props: {
    MuiTextField: {
      variant: "outlined",
      size: "small",
      fullWidth: true
    },
    MuiButton: {
      variant: "contained",
      color: "primary"
    }
  }
});


ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField, MenuItem, Button, Box, Stack, Typography } from '@mui/material';

import './style.scss'

/**
 * edit video setting
 * @augments {Component<Props, State>}
 */
class VideoStream extends Component {
  _isMounted = false;

  state = {
    videoStream: {}
  }


  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState()
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = (props = this.props) => {
    if (!this._isMounted) return;
    const { videoStream } = props;

    this.setState({ videoStream: this.changeLogic(videoStream, props) });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeValue = field => evt => {
    const { videoStream } = this.state;
    videoStream[field] = evt.target.value;
    if (field === 'bitrate') videoStream['vbv-maxrate'] = evt.target.value
    this.setState({ videoStream: this.changeLogic(videoStream) }, this.onChange)
  }
  changeLogic = (videoStream, props = this.props) => {
    // const {videoInput} = props;
    // ['pal', 'ntsc'].includes(videoInput)
    //   ? videoStream.profile = 'main'
    //   : videoStream.profile = 'high';
    // videoStream.profile === "high" ? videoStream.level = 4.1 : videoStream.level = 3.2
    // console.log(videoStream);
    return videoStream;
  }

  onChange = () => this.props.onChange && this.props.onChange(this.state.videoStream);

  render() {
    const { videoStream } = this.state;
    const { videoInput } = this.props;
    const keys = [
      {
        label: 'format', field: 'format', type: 'select',
        children: [
          { value: 'avc', label: 'H.264' },
        ]
      },
      { label: 'bitrate', field: 'bitrate', },
      { label: 'Key int', field: 'keyint', },
      { label: 'vbv bufsize', field: 'vbv-bufsize', },
      { label: 'profile', field: 'profile', type: 'select', children: ['main', 'high'] },
      { label: 'vbv maxrate', field: 'vbv-maxrate', },
      { label: 'level', field: 'level' },
      { label: 'aspect-ratio', field: 'aspect-ratio', type: 'select', children: ['16:9', '4:9'] },
      {
        label: 'width', field: 'width', type: 'select',
        children: ['pal', 'ntsc'].includes(videoInput)
          ? ['720', '1024']
          : videoInput && videoInput.startsWith('720p')
            ? ['960', '1280']
            : ['1280', '1920']
      },
      { label: 'threads', field: 'threads', },
      { label: 'PID', field: 'pid' },
    ]

    const renderItem = ({ label, field, type = 'text', children = [] }) => (
      <Stack key={field} direction="column" spacing={1}>
        <Typography variant='body1'>{label}</Typography>
        <Box display="flex">
          {
            type === 'select'
              ? <TextField
                fullWidth select
                value={videoStream[field] || ''}
                onChange={this.changeValue(label)} >
                {
                  children.map((child, index) => <MenuItem value={child.value || child} key={child.value || child}>{child.label || child}</MenuItem>)
                }
              </TextField>
              : <TextField
                fullWidth
                type={type}
                value={videoStream[field] || ''}
                onChange={this.changeValue(field)} />
          }
          {
            field === 'bitrate' ?
              <Button variant='contained' size='small' sx={{ textTransform: 'none' }} onClick={() => this.props.getRate && this.props.getRate()}>Caculate from mux</Button>
              : null
          }
        </Box>
      </Stack>
    );
    return (
      <Box
        my={3}
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      >
        {
          keys.map(element => renderItem({ ...element }))
        }
      </Box>
    )
  }
}

VideoStream.propTypes = {
  videoStream: PropTypes.object.isRequired,
  videoInput: PropTypes.string,
  getRate: PropTypes.func
}

export default VideoStream;
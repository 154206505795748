import React, { useRef, useEffect} from 'react'
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {setLoading} from '../../action'
import {connect} from "react-redux";

const useSearchSuggestStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    autocomplete: {
        position: 'absolute',
        border: '1px solid #f1f1f1',
        borderBottom: 'none',
        borderTop: 'none',
        zIndex: 99,
        /*position the autocomplete items to be the same width as the container:*/
        top: '100%',
        left: 0,
        right: 0,
    },
    autocompleteItems: {
        padding: '10px',
        cursor: 'pointer',
        backgroundColor: '#fff',
        borderBottom: '1px solid #d4d4d4',
        "&:hover": {
            backgroundColor: 'DodgerBlue !important',
            color: '#ffffff'
        }
    }
}));


const SearchSuggest = (props) => {
    const classes = useSearchSuggestStyles();
    const {suggests, handleSearchSelect, type, display, closeSuggest} = props;

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    closeSuggest();
                }
            }
            // Bind the event listener
            document.addEventListener("click", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("click", handleClickOutside);
            };
        }, [ref]);
    }

    return (
        <>
            <div className={classes.root}>
                {display ? (
                    <div className={classes.autocomplete} ref={wrapperRef}>
                        {
                            suggests.map((row, index) => {
                                return (
                                    <div className={classes.autocompleteItems}
                                         onClick={() => handleSearchSelect(row, type)}
                                         key={row.id}>{row.title}</div>
                                )
                            })
                        }
                    </div>
                ) : ''}

            </div>
        </>
    );
};

SearchSuggest.propTypes = {
    suggests: PropTypes.array.isRequired,
    handleSearchSelect: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    display: PropTypes.bool.isRequired,
    closeSuggest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {setLoading}

export default connect(mapStateToProps, mapDispatchToProps)(SearchSuggest)
// export default EnhancedTableToolbar;

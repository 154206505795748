import React, { useCallback, useState } from "react";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import StatusChip from "../Common/statusLabel";
import TextField from '@mui/material/TextField';
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { paramPut } from "../../apis/CommonApi";
import { ToastsStore } from "react-toasts";

const ChannelName = ({ channelId, name, title, status, isAlive, url, onTitleUpdate, onClickChannelTitle, sx }) => {
    const [channelTitle, setChannelTitle] = useState(title);
    const [isEditing, setIsEditing] = useState(false);

    const onStartTitleChange = (e) => {
        e.stopPropagation();
        setIsEditing(true);
        setChannelTitle(title);
    }

    const handleTitleUpdate = useCallback(async () => {
        setIsEditing(false);
        // Update title
        try {
            const response = await paramPut(`/api/channel/${channelId}`, { title: channelTitle });
            onTitleUpdate(response.data);
            ToastsStore.success("Channel title updated successfully!");
        } catch (error) {
            console.error("Error updating title: ", error);
            ToastsStore.error("Error updating channel title!");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channelId, channelTitle]);

    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ cursor: 'pointer' }} onClick={(e) => e.stopPropagation()} onDoubleClick={(e) => onStartTitleChange(e)}>
                <FiberManualRecordIcon sx={{ color: status ? isAlive ? '#09E165' : '#E10909' : '#B0B0B0', fontSize: '12px' }} />
                {isEditing ? (
                    <Stack direction="row" spacing={1}>
                        <TextField size="small" value={channelTitle} onChange={(e) => setChannelTitle(e.target.value)} id="title" label="Title" variant="outlined" />
                        <IconButton onClick={handleTitleUpdate}>
                            <CheckCircleIcon color="success" />
                        </IconButton>
                        <IconButton onClick={() => setIsEditing(false)}>
                            <CancelIcon color="error" />
                        </IconButton>
                    </Stack>
                ) : (
                    <Typography variant="body1" sx={{ fontSize: '14px' }} onClick={onClickChannelTitle}>
                        {`${name} ${title ? ` | ${title}` : ""}`}
                    </Typography>
                )}
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
                <StatusChip label={status ? isAlive ? "Online" : "Offline" : "Disabled"} type={status ? isAlive ? "success" : "error" : "disabled"} />
                <Typography sx={{ color: "#414141", fontWeight: 200, fontSize: '14px' }}>
                    {url}
                </Typography>
            </Stack>
        </Stack>
    )
}

export default ChannelName;

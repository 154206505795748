import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getUser, getRoles} from '../../apis/Authontication';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem} from '@mui/material';
import {isEmpty, EmailValidation, loginUser} from '../../utils';
import config from "../../config"
import './style.scss'
import {noParamGet} from "../../apis/CommonApi";

/**
 * Add and edit user info
 * @augments {Component<Props, State>}
 */
class UserManagerDialog extends Component {

    _isMounted = false;

    state = {
        open: false,
        defaultRole: '',
        userInfo: {},
        roles: [],
        groups: [],
        userGroup: '',
        newPassword: '',
        confirmNewPassword: '',
    }

    async componentDidMount() {
        this._isMounted = true;
        await this.propsToState();
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        await this.propsToState(nextProps);
    }

    propsToState = async (props = this.props) => {
        const {open, userId} = props;
        const connectedUserMode = loginUser();
        if (open) {
            const userInfo = (userId && open) ? await getUser(userId) : {};
            const roles = await getRoles() || [];
            this._isMounted && this.setState({open, userInfo: userInfo || {}, roles});
            if (roles.length && !userInfo._id && this.loginUser() === 'manager') {
                this.setState({defaultRole: roles[0]._id});
                const {userInfo} = this.state;
                userInfo['mode'] = roles[0]._id;
                this.setState({userInfo});
            }
            if (connectedUserMode === 'superadmin' || connectedUserMode === 'admin') {
                const groups = await noParamGet("/api/group");
                if (userInfo._id) {
                    let userGroup = null;
                    for (const group of groups.data) {
                        const exist = group.users.find(el => el === userInfo._id);
                        if (exist) {
                            userGroup = group;
                            break;
                        }
                    }
                    if (userGroup) {
                        this.setState({userGroup: userGroup._id});
                    }
                }
                this.setState({groups: groups.data || []});
            }
        } else {
            this._isMounted && this.setState({open});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    // onSave = () => this.props.onSave && this.setState({newPassword: '', confirmNewPassword: ''}) && this.props.onSave(this.state.userInfo, this.state.userGroup || null);
    onSave = () => {
        // this.setState({newPassword: '', confirmNewPassword: ''});
        const rs = this.props.onSave(this.state.userInfo, this.state.userGroup || null)
        rs.then(res => {
            if (res) {
                this.setState({newPassword: '', confirmNewPassword: ''});
            }
        })
    }
    onClose = () => this.props.onClose && this.props.onClose();

    onChangeUserInfo = field => (evt, newValue) => {
        const {userInfo} = this.state;
        userInfo[field] = evt.target.value;
        this.setState({userInfo});
        if (field === 'mode') {
            this.setState({userGroup: ''});
        }
    }

    onChangeNewPassword = (type, newValue) => {
        const {userInfo} = this.state;
        if (type === 'newPassword') {
            this.setState({newPassword: newValue});
            userInfo.newPassword = newValue;
        } else {
            this.setState({confirmNewPassword: newValue});
            userInfo.newConfirmPassword = newValue;
        }

        this.setState({userInfo});
    }

    onChangeUserGroup = (evt) => {
        this.setState({userGroup: evt.target.value});
    }

    loginUser = () => {
        let user = localStorage.getItem(config.userLocalStorage)
        if (user) {
            user = JSON.parse(user)
            return user.mode.name
        }
        return null
    }

    connectedUser = () => {
        let user = localStorage.getItem(config.userLocalStorage)
        if (user) {
            user = JSON.parse(user)
            return user
        }
        return null
    }

    checkRoleIsManager = (roleId = null) => {
        if (roleId) {
            const {roles, userInfo, groups} = this.state;
            const role = roles.find(el => el._id === userInfo.mode);
            if (role) {
                return role.name === 'manager';
            }
        }
    }

    roleList = () => {
        const user = this.loginUser()
        const userRoleInfo = this.state.userInfo
        if (this.state.roles.length) {
            return (
                this.state.roles.map(function (role, index) {
                        if (user === "superadmin") {
                            if (role.name !== 'monitor' && role.name !== 'editor') {
                                return <MenuItem value={role._id} key={role._id}>{role.name}</MenuItem>
                            }
                            return;
                        } else if (user === "manager") {
                            return <MenuItem value={role._id} key={role._id}>{role.name}</MenuItem>
                        } else {
                            return
                        }
                    }
                )
            )
        }
    }

    render() {
        const {open, userInfo, roles, defaultRole, userGroup, groups, newPassword, confirmNewPassword} = this.state;
        const connectedUserMode = loginUser();
        const connectedUser = this.connectedUser();
        let managerUpdateAnotherManager = false;
        if (connectedUserMode === 'manager') {
            if (userInfo?.modeInfo?.name === 'manager') {
                managerUpdateAnotherManager = true;
            }
        }
        return (
            <Dialog open={open} className="panda-edit-user-dialog" fullWidth>
                <DialogTitle>{userInfo._id ? 'Edit user' : 'Add user'}</DialogTitle>
                <DialogContent>
                    <div className="container">
                        {
                            userInfo._id && <TextField value={userInfo._id} label="User Id" style={{display: 'none'}}/>
                        }
                        <TextField
                            label="First name"
                            value={userInfo.firstName || ''}
                            onChange={this.onChangeUserInfo('firstName')}
                            error={isEmpty(userInfo.firstName)}
                        />
                        <TextField
                            label="Last name"
                            value={userInfo.lastName || ''}
                            onChange={this.onChangeUserInfo('lastName')}
                            error={isEmpty(userInfo.lastName)}
                        />
                        <TextField
                            label="Email"
                            value={userInfo.email || ''}
                            onChange={this.onChangeUserInfo('email')}
                            error={EmailValidation(userInfo.email)}
                        />
                        {
                            !userInfo._id ? // Used to add a user
                                <TextField
                                    label="Password"
                                    value={userInfo.password || ''}
                                    onChange={this.onChangeUserInfo('password')}
                                    error={isEmpty(userInfo.password)}
                                />
                                :
                                <>
                                    <TextField // used to edit a user
                                        label="Update Password"
                                        value={newPassword || ''}
                                        onChange={e => this.onChangeNewPassword('newPassword', e.target.value)}
                                    />
                                    {
                                        newPassword.length > 0 &&
                                        <TextField // used to edit a user
                                            label="Confirm New Password"
                                            value={confirmNewPassword || ''}
                                            onChange={e => this.onChangeNewPassword('confirmNewPassword', e.target.value)}
                                        />
                                    }
                                </>
                        }
                        {
                            (connectedUserMode === 'superadmin' || connectedUserMode === 'admin' || connectedUserMode === 'manager')
                            && connectedUser._id !== userInfo._id && !managerUpdateAnotherManager &&
                            <TextField
                                select
                                label="Role"
                                value={userInfo.mode || defaultRole}
                                onChange={this.onChangeUserInfo('mode')}
                            >
                                {this.roleList()}

                            </TextField>
                        }
                        {(connectedUserMode === 'superadmin' || connectedUserMode === 'admin') && this.checkRoleIsManager(userInfo?.mode) ? (
                            <TextField
                                select
                                label="Group"
                                value={userGroup}
                                onChange={this.onChangeUserGroup}
                                // disabled={userInfo._id ? true : false}

                            >
                                {groups.map((group, index) => {
                                    return <MenuItem value={group._id} key={group._id}>{group.title}</MenuItem>
                                })}

                            </TextField>
                        ) : ''}
                    </div>
                </DialogContent>
                <DialogActions sx={{px: '23px'}}>
                    <Button variant="contained" color="primary"
                            onClick={this.onSave}>{userInfo._id ? 'Edit user' : 'Add user'}</Button>
                    <Button variant="contained" color="secondary" onClick={() => {
                        this.setState({userGroup: '', newPassword: '', confirmNewPassword: ''})
                        this.onClose()
                    }}>{'close'}</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

UserManagerDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    userId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func
}

export default UserManagerDialog
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AudioImg from "../../assets/icons/audio.png";
import VideoImg from "../../assets/icons/video.png";
import Box from "@mui/material/Box";

const ChannelInOut = ({video, audio, resolution, textSX}) => {
    return (
        <Stack direction="column" spacing={1} >
          <Stack direction="row" spacing={1} alignItems="flex-start">
            <Stack direction="row" spacing={1} alignItems="center">
              <Box component={"img"} src={VideoImg} alt="video" sx={{ width: "13px", height: "16px" }} />
              <Typography variant="body1" sx={{ color: '#A39C9C', fontSize: '14px'}}>Video: </Typography>
            </Stack>
            <Stack direction="column" textAlign="right" spacing={1}>
              <Typography variant="body1" sx={{ fontSize: '14px' }}>
                  {video}
              </Typography>
              {resolution && (
                <Typography sx={{ fontSize: '14px' }}>
                  {resolution}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="flex-start">
            <Stack direction="row" spacing={1} alignItems="center">
              <Box component={"img"} src={AudioImg} alt="audio" sx={{ width: "13px", height: "16px" }} />
              <Typography variant="body1" sx={{ color: '#A39C9C', fontSize: '14px'}}>Audio: </Typography>
            </Stack>
            <Typography variant="body1" textAlign="right" sx={{ fontSize: '14px', ...textSX }} >
                {audio}
            </Typography>
          </Stack>
        </Stack>
    )
}

export default ChannelInOut;

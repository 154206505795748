import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField, MenuItem, Button, Stack, Box, Typography } from '@mui/material';

import './style.scss'

/**
 * edit video setting
 * @augments {Component<Props, State>}
 */
class Muxer extends Component {
  _isMounted = false;

  state = {
    muxer: {},
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState()
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = (props = this.props) => {
    if (!this._isMounted) return;
    const { muxer } = props;
    this.setState({ muxer });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeValue = field => evt => {
    const { muxer } = this.state;
    if (field === 'pmt-period') {
      muxer[field] = "100";
      return;
    }
    muxer[field] = evt.target.value;
    this.setState({ muxer }, this.onChange)
  }

  onChange = () => this.props.onChange && this.props.onChange(this.state.muxer);

  render() {
    const { muxer } = this.state;

    const keys = [
      { label: 'TS Type', field: 'ts-type', type: 'select', children: ['generic', 'dvb', 'cablelabs', 'atsc'] },
      { label: 'TS ID', field: 'ts-id' },
      { label: 'PMT PID', field: 'pmt-pid' },
      { label: 'PCR Period', field: 'pcr-period' },
      { label: 'PMT Period', field: 'pmt-period', value: "100" },
      { label: 'PCR PID', field: 'pcr-pid' },
      { label: 'Program num', field: 'program-num' },
      { label: 'TS Muxrate', field: 'ts-muxrate' },

    ]

    const renderItem = ({ label, field, type = 'text', children = [], value }) => (
      <Stack direction="column" spacing={1} key={field}>
        <Typography variant='body1'>{label}</Typography>
        <Box display="flex">
          {
            type === 'select'
              ? <TextField
                fullWidth select
                value={muxer[field] || ''}
                onChange={this.changeValue(label)} >
                {
                  children.map((child, index) => <MenuItem value={child} key={child}>{child}</MenuItem>)
                }
              </TextField>
              : <TextField
                fullWidth
                type={type}
                value={value || muxer[field] || ''}
                onChange={this.changeValue(field)} />
          }
          {
            field === 'ts-muxrate' ?
              <Button variant='contained' size='small' onClick={() => this.props.getRate && this.props.getRate()}>update</Button>
              : null
          }
        </Box>
      </Stack>
    );
    return (
      <Box
        my={3}
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      >
        {
          keys.map(element => renderItem({ ...element }))
        }
      </Box>
    )
  }
}

Muxer.propTypes = {
  muxer: PropTypes.object.isRequired
}

export default Muxer;
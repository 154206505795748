// /*********************
//  * Auth user actions *
//  *********************/
import {
  LOGIN_USER,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  SIGNUP_USER,
  SIGNUP_USER_FAILURE,
  SIGNUP_USER_SUCCESS,
} from '../types';
import { ToastsStore } from 'react-toasts';

import { signupApi, signinApi, jwtLoginApi } from "../../apis/Authontication"
import config from '../../config';
// /***************************************************
//  * Redux action to sign in with email and password *
//  ***************************************************/
// export const loginWithEmail = (email, password) => dispatch => {

// };

const dispathEvent = (type, payload) => ({ type: type, payload });

export const signup = payload => async dispatch => {
  try {
    dispatch(dispathEvent(SIGNUP_USER));
    const response = await signupApi(payload);
    dispatch(dispathEvent(SIGNUP_USER_SUCCESS, response.data));
    ToastsStore.success('Success add your info')
  } catch (error) {
    ToastsStore.error(error.response.data.error);
    dispatch(dispathEvent(SIGNUP_USER_FAILURE));
  }
}

export const signin = payload => async dispatch => {
  try {
    dispatch(dispathEvent(LOGIN_USER));
    const response = await signinApi(payload);
    dispatch(dispathEvent(LOGIN_USER_SUCCESS, response.data));
    return true;
  } catch (error) {
    // NotificationManager.error("Please check your email and password", 'Login fail');
    ToastsStore.error(error.response.data.error || 'Please check your email and password');
    dispatch(dispathEvent(LOGIN_USER_FAILURE));
    return false;
  }
}

export const jwtLogin = () => async dispatch => {
  try {
    if (localStorage.getItem(config.userLocalStorage)) {
      const response = await jwtLoginApi();
      dispatch(dispathEvent(LOGIN_USER_SUCCESS, response.data));
    } else {
      dispatch(dispathEvent(LOGIN_USER_FAILURE));
    }
  } catch (error) {
    error.response && ToastsStore.error(error.response.data.error);
    dispatch(dispathEvent(LOGIN_USER_FAILURE));
  }
}

export const logout = () => dispath => {
  dispath({
    type: LOGOUT_USER
  });
}
/**
 * check for user permistion
 * @param {string} owner 
 * @param {string} permistion 
 */
export const checkPermision = (owner, permistion) => (_, getState) => {
  const { user } = getState().authUser.user;
  return user.Role[owner] && user.Role[owner][permistion];
}

export const isAdmin = () => (_, getState) => {
  try {
    const { user } = getState().authUser.user;
    return user.Role.role === 'Admin';
  } catch (error) {
    return false;
  }
}
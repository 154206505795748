import React from 'react';
import Chip from '@mui/material/Chip';

const StatusChip = (props) => {
  const { label, type, sx } = props;
  let color = '';
  let bgColor = '';
  switch (type) {
    case 'success':
      color = '#0D9547';
      bgColor = '#CBF0DB';
      break;
    case 'error':
      color = '#EC1010';
      bgColor = '#EC10101A';
      break;
    case 'warning':
      color = '#FFC107';
      bgColor = '#FFC1071A';
      break;
    case 'sync':
      color = '#000';
      bgColor = '#fff';
      break;
    case 'disabled':
      color = '#B0B0B0';
      bgColor = '#F5F5F5';
      break;
    default:
      color = '#0D9547';
      bgColor = '#CBF0DB';
  }
  return (
    <Chip
      label={label}
      size='small' 
      sx={{ bgcolor: bgColor, color, ...sx }}
    />
  );
}

export default StatusChip;

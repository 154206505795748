import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {  MenuItem, Button, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import ActionButton from '../../../components/Common/action-button';

import './style.scss'
/**
 * edit audio setting
 * @augments {Component<Props, State>}
 */
class Output extends Component {
  static propTypes = {
    output: PropTypes.array.isRequired,
    onChange: PropTypes.func
  }

  state = {
    output: [],
    protocal: 'udp'
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { output } = nextProps;
    return {
      output: output || [],
    }
  }

  changeValue = (index, protocal, ip = '0.0.0.0', port = 0, ttl = 0) => {
    const { output } = this.state;
    output[index].type = protocal;
    output[index].target = `${protocal}://${ip}:${port}?ttl=${ttl}`;
    this.setState({ output }, this.onChange);
  }

  changeProtocal = (evt, protocal) => {
    this.setState({ protocal })
  }

  removeProtocol = index => {
    const { output } = this.state;
    output.splice(index, 1);
    this.setState({ output }, this.onChange);
  }

  addNewProtocal = () => {
    const { output } = this.state;
    output.push({
      type: 'udp',
      target: 'udp://0.0.0.0:5000?ttl=12'
    })
    this.setState({ output }, this.onChange);
  }
  onChange = () => this.props.onChange && this.props.onChange(this.state.output);

  render() {
    const { output } = this.state;
    const renderEdit = index => {
      const str = output[index].target;
      const ips = /[^/][\d.]+(?=:)/g.exec(str);
      const ports = /[^:][\d.]+(?=\?)/g.exec(str);
      const ttls = /[^=][\d.]+$/g.exec(str);
      const protocal = str.substring(0, 3);
      const ip = ips && ips[0];
      const port = ports && ports[0];
      const ttl = ttls && ttls[0];
      return (
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            className="protocal"
            select
            value={protocal}
            onChange={evt => this.changeValue(index, evt.target.value, ip, port, ttl)}
          >
            <MenuItem value='udp'>UDP</MenuItem>
            <MenuItem value='rtp'>RTP</MenuItem>
          </TextField>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2} alignItems={{ xs: "start", sm: "center" }}>
            <span>Target IP:</span>
            <TextField fullWidth value={ip} onChange={evt => this.changeValue(index, protocal, evt.target.value, port, ttl)} />
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2} alignItems={{ xs: "start", sm: "center" }}>
            <span>Port:</span>
            <TextField fullWidth value={port} onChange={evt => this.changeValue(index, protocal, ip, evt.target.value, ttl)} type="number" />
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2} alignItems={{ xs: "start", sm: "center" }}>
            <span>TTL</span>
            <TextField fullWidth value={ttl} onChange={evt => this.changeValue(index, protocal, ip, port, evt.target.value)} type="number" />
          </Stack>
          <ActionButton handleClick={() => this.removeProtocol(index)} type="delete" />
        </Stack>
      )
    }
    return (
      <div className="output">
        <div className="actions">
          <Button variant='contained' onClick={this.addNewProtocal}>Add New Output</Button>
        </div>
        {
          output.map((element, index) => (
            <React.Fragment key={index}>
              <p>{element.target}</p>
              {renderEdit(index)}
            </React.Fragment>
          ))
        }
      </div>
    )
  }
}

export default Output
import React, { memo, useCallback, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button  from "@mui/material/Button";
import Switch from '@mui/material/Switch';
import { makeStyles } from "@mui/styles";
import ReloadImg from "../../assets/icons/refresh.png";

import { paramPost } from "../../apis/CommonApi";
import { ToastsStore } from "react-toasts";
import {connect} from 'react-redux';
import { setLoading } from "../../action";

export const switchUseStyles = makeStyles({
  root: {
    width: "56px",
    height: "25px",
    padding: "0px"
  },
  switchBase: {
    color: "#0D9547",
    padding: "1px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "#EC1010"
      }
    }
  },
  thumb: {
    color: "white",
    width: "21px",
    height: "21px",
    margin: "0.8px"
  },
  track: {
    borderRadius: "20px",
    backgroundColor: "#0D9547",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "11px",
      position: "absolute",
      top: "5px"
    },
    "&:after": {
      content: "'Off'",
      left: "8px"
    },
    "&:before": {
      content: "'On'",
      right: "7px"
    }
  },
  checked: {
    color: "#EC1010 !important",
    transform: "translateX(31px) !important"
  }
});

const ChannelActions = ({sx, mainSX, runningStatus, channelName, serverInfo, isManager, onChangeStatus, setLoading}) => {
  const classes = switchUseStyles();
  const [state, setState] = React.useState(true);

  const handleChange = useCallback (async (event) => {
    event.stopPropagation();
    if (!isManager) {
      ToastsStore.error('You are not authorized to perform this action!');
      return;
    }
    const currentChecked = event.target.checked;
    setState(currentChecked);
    //
    try {
      setLoading(true);
      const res = await paramPost(
        "/api/channel/change_stream_status", 
        { serverId: serverInfo._id, streamName: channelName, data: { disabled: !currentChecked } },
      );
      setLoading(false);
      // Show toast message
      if (res.status < 300) {
        onChangeStatus(res.data.stream);
        ToastsStore.success('Status updated successfully!');
      } else {
        ToastsStore.error('Something went wrong! Please try again.');
        setState(!currentChecked);
      }
    } catch (e) {
      setLoading(false);
      ToastsStore.error('Something went wrong! Please try again.');
      setState(!currentChecked);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManager, serverInfo._id, channelName]);

  const handleServerRestart = useCallback(async (event) => {
    event.stopPropagation();
    if (!isManager) {
      ToastsStore.error('You are not authorized to perform this action!');
      return;
    }
    //
    try {
      setLoading(true);
      const res = await paramPost(
        `/api/channel/stop_stream`, 
        { serverId: serverInfo._id, streamName: channelName },
      );
      setLoading(false);
      // Show toast message
      ToastsStore.success('Server restarted successfully!');
    } catch (e) {
      setLoading(false);
      ToastsStore.error('Failed to restart the server!');
    }
  }, [isManager, setLoading, serverInfo._id, channelName]);

  useEffect(() => {
    setState(runningStatus);
  }, [runningStatus]);

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{...mainSX}}>
      <Button sx={{ minHeight: 0, minWidth: 0, padding: 0, transition: 'transform .2s', "&:hover": {transform: 'scale(1.3)'} }} onClick={(e) => handleServerRestart(e)} >
        <Box component="img" src={ReloadImg} alt="start" sx={{ width: { xs: "2rem", md: "2.5rem"}, ...sx }} />
      </Button>
      <Switch
        sx={{transition: 'transform .2s', "&:hover": {transform: 'scale(1.2)'}}}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        checked={state}
        onClick={(e) => e.stopPropagation()}
        onChange={handleChange}
        name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    </Stack>
  );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {setLoading}

export default connect(mapStateToProps, mapDispatchToProps)(memo(ChannelActions));

import React from 'react'
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'
import {Button, IconButton, Icon} from '@mui/material';
import {editEncoder, logout, setLoading} from '../../action'
import config from '../../config';

import './style.scss'

/**
 * Video Preview Component
 * @augments {Component<Props, State>}
 */
const Header = (props) => {

    const dashboard = () => {
        window.open('/', '_self')
    }
    const userInfo = () => {
        let user = localStorage.getItem(config.userLocalStorage)
        if (user) {
            user = JSON.parse(user)
            return user.mode.name
        }
        return null
    }

    return (
        <div className="admin-header">
            <Button onClick={dashboard}
                    className={props.location.pathname === '/' ? 'active' : ''}>Dashboard</Button>
            {(userInfo() === "superadmin" || userInfo() === "admin") && (
                <>
                    <Button onClick={() => props.history.push('/dashboard/server')}
                            className={props.location.pathname === '/dashboard/server' ? 'active' : ''}>Servers</Button>
                    <Button onClick={() => props.history.push('/dashboard/group')}
                            className={props.location.pathname === '/dashboard/group' ? 'active' : ''}>Groups</Button>
                </>
            )}
            {(userInfo() === "superadmin" || userInfo() === "admin" || userInfo() === "manager" || userInfo() === "editor" || userInfo() === "monitor") && (
                <Button onClick={() => props.history.push('/dashboard/userManager')}
                        className={props.location.pathname === '/dashboard/userManager' ? 'active' : ''}>Access</Button>
            )}
            <Button onClick={() => props.history.push('/dashboard/managerEncoder')}
                    className={props.location.pathname === '/dashboard/managerEncoder' ||
                    props.location.pathname.includes('editEncoder') ? 'active' : ''}>Encoders</Button>
            {(userInfo() === "superadmin" || userInfo() === "admin" || userInfo() === "manager") && (
                <Button onClick={() => props.history.push('/dashboard/contribution')}
                        className={props.location.pathname === '/dashboard/contribution'
                        || props.location.pathname.includes('contribution')
                        || props.location.pathname.includes('output')
                            ? 'active' : ''}>Contributions</Button>
            )}
            <IconButton className="close-button" onClick={props.logout}>
                <Icon>exit_to_app</Icon>
            </IconButton>
        </div>
    )
}
Header.propTypes = {}

const mapStateToProps = ({overloading}) => ({});
export default withRouter(connect(mapStateToProps, {
    editEncoder, logout, setLoading
})(Header));
import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Paper,
  InputBase,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Button,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import PropTypes from "prop-types";
import FilterListIcon from "@mui/icons-material/FilterList";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { loginUser } from "../../utils";

const testTags = [
  { title: "Orange TV", year: 1 },
  { title: "RCS Ghana", year: 2 },
  { title: "aCANGroup", year: 3 },
  { title: "TNT", year: 4 },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  gridContainer: {
    padding: "2rem",
  },
  newButton: {
    float: "right",
  },
  title: {
    textAlign: "center",
    fontSize: "1.2rem",
    color: "#5a97fa",
    fontWeight: "600",
  },
  searchBar: {
    margin: "2rem 0 1rem",
  },
  searchForm: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "90%",
    },
    height: "40px",
    border: "1px solid rgb(203,203,203)",
    boxShadow: "none",
    borderRadius: "4px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

function SearchBar(props) {
  const {
    onChange,
    pageType,
    hasGroupSearch,
    hasSearchInput,
    hasTagSearch,
    hasAddNew,
    hasServerSelector,
    newButtonTitle,
    hasForceRefresh,
    onRefresh,
    onCreateNew,
    groups,
    channelServerList,
    isRequestedRefresh,
  } = props;
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const [channelServers, setChannelServers] = useState(channelServerList || []);
  // Selected Status
  const [selectedServer, setSelectedServer] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);

  // Group Search
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickGroupMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const changeLocalStorageId = useCallback(
    (value, type) => {
      // type: 0 - remove, 1 - set, 2 - get
      const key = `${pageType}-group-id`;
      if (type === 2) {
        return localStorage.getItem(key);
      }
      if (type === 1) {
        localStorage.setItem(key, value);
        return;
      }
      if (type === 0) {
        localStorage.removeItem(key);
        return;
      }
    },
    [pageType]
  );

  const handleCloseGroupMenu = useCallback(
    (_event, groupId) => {
      setAnchorEl(null);
      let serverId = selectedServer;
      if (groupId) {
        if (groupId === "all") {
          setSelectedGroup(null);
          changeLocalStorageId(null, 0);
        } else {
          setSelectedGroup(groupId);
          changeLocalStorageId(groupId, 1);
        }
        if (hasServerSelector) {
          // Find servers which contains groupId in his groupIds
          const servers = channelServerList.filter((server) =>
            server.groupIds.includes(groupId)
          );
          setChannelServers(servers);
          if (servers.length > 0) {
            serverId = servers[0]._id;
            setSelectedServer(serverId);
            localStorage.setItem("channel-server-id", serverId);
          }
        }
        onChange({
          searchText: searchValue,
          serverId,
          groupId: groupId === "all" ? null : groupId,
          tags: selectedTags,
        });
      }
    },
    [
      changeLocalStorageId,
      channelServerList,
      hasServerSelector,
      onChange,
      searchValue,
      selectedServer,
      selectedTags,
    ]
  );
  //

  // Channel Server Search
  const [anchorChannel, setAnchorChannel] = React.useState(null);
  const openChannelMenu = Boolean(anchorChannel);
  const handleClickChannelServerMenu = (event) => {
    setAnchorChannel(event.currentTarget);
  };
  const handleCloseChannelServerMenu = useCallback(
    (_event, serverId) => {
      setAnchorChannel(null);
      if (serverId) {
        if (serverId === "all") {
          setSelectedServer(null);
          localStorage.removeItem("channel-server-id");
        } else {
          localStorage.setItem("channel-server-id", serverId);
          setSelectedServer(serverId);
        }
        onChange({
          searchText: searchValue,
          serverId: serverId === "all" ? null : serverId,
          groupId: selectedGroup,
          tags: selectedTags,
        });
      }
    },
    [onChange, searchValue, selectedGroup, selectedTags]
  );
  //

  const handleSearch = async (e) => {
    const letter = e.target.value;
    setSearchValue(letter);
    if (letter.length > 3) {
      onChange({
        searchText: letter,
        serverId: selectedServer,
        groupId: selectedGroup,
        tags: selectedTags,
      });
      setIsSearching(true);
    }
    if (letter.length === 0) {
      await clearBar();
    }
  };

  const handleSearchForm = async (e) => {
    e.preventDefault();
    onChange({
      searchText: searchValue,
      serverId: selectedServer,
      groupId: selectedGroup,
      tags: selectedTags,
    });
    setIsSearching(true);
  };

  const handleCancel = async (e) => {
    e.preventDefault();
    await clearBar();
  };

  const clearBar = async () => {
    setSearchValue("");
    setIsSearching(false);
    onChange({
      searchText: "",
      serverId: selectedServer,
      groupId: selectedGroup,
      tags: selectedTags,
    });
  };

  const renderGroupMenu = useMemo(() => {
    if (groups && groups.length > 0) {
      return groups.map((group, index) => (
        <MenuItem
          value={group._id}
          key={index}
          onClick={(event) => handleCloseGroupMenu(event, group._id)}>
          {group.title || ""}
        </MenuItem>
      ));
    }
    return null;
  }, [groups, handleCloseGroupMenu]);

  const renderChannelServerMenu = useMemo(() => {
    if (channelServers && channelServers.length > 0) {
      return channelServers.map((server, index) => (
        <MenuItem
          value={server._id}
          key={index}
          onClick={(event) => handleCloseChannelServerMenu(event, server._id)}>
          {server.title || ""}
        </MenuItem>
      ));
    }
    return null;
  }, [channelServers, handleCloseChannelServerMenu]);

  const setInitialSection = useCallback(() => {
    let groupId = changeLocalStorageId(null, 2);
    let serverId = localStorage.getItem("channel-server-id");
    setSelectedGroup(groupId);
    setSelectedServer(serverId);
    if (hasGroupSearch) {
      if (groups.length && loginUser() === "manager") {
        if (groupId) {
          setSelectedGroup(groupId);
        } else {
          groupId = groups[0]._id;
          setSelectedGroup(groupId);
          changeLocalStorageId(groupId, 1);
        }
      }
    }
    // if (hasServerSelector) {
    //   console.log('Geeting channale')
    //   if (channelServers.length > 0) {
    //     if (serverId) {
    //       setSelectedServer(serverId);
    //     } else {
    //       serverId = channelServers[0]._id;
    //       setSelectedServer(serverId);
    //       localStorage.setItem('channel-server-id', serverId);
    //     }
    //   }
    // }
    if (hasServerSelector && !serverId) return;
    onChange({ searchText: "", serverId, groupId, tags: null });
  }, [
    changeLocalStorageId,
    groups,
    hasGroupSearch,
    hasServerSelector,
    onChange,
  ]);

  const handleRefresh = useCallback(() => {
    if (hasForceRefresh) {
      const groupId = changeLocalStorageId(null, 2);
      const serverId = localStorage.getItem("channel-server-id");
      onChange({
        searchText: searchValue,
        serverId,
        groupId,
        tags: null,
      });
    } else {
      onRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelServers, hasServerSelector]);

  useEffect(() => {
    setInitialSection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isRequestedRefresh) {
      handleRefresh();
    }
  }, [handleRefresh, isRequestedRefresh]);

  useEffect(() => {
    setChannelServers(channelServerList);
    if (hasServerSelector) {
      let currentServerId = localStorage.getItem("channel-server-id");
      if (channelServerList.length > 0) {
        if (currentServerId) {
          setSelectedServer(currentServerId);
        } else {
          currentServerId = channelServerList[0]._id;
          localStorage.setItem("channel-server-id", currentServerId);
          setSelectedServer(currentServerId);
        }
        onChange({
          searchText: "",
          serverId: currentServerId,
          groupId: selectedGroup,
          tags: null,
        });
      } else {
        localStorage.removeItem("channel-server-id");
        setSelectedServer(null);
      }
    }
  }, [channelServerList, hasServerSelector, onChange, selectedGroup]);

  return (
    <Grid container sx={{ px: { xs: 3 }, mt: 5 }}>
      <Grid item xs={12} md={12} lg={5}>
        {hasSearchInput && (
          <Paper
            component="form"
            className={classes.searchForm}
            onSubmit={handleSearchForm}>
            <InputBase
              className={classes.input}
              style={{ paddingLeft: "1rem" }}
              value={searchValue}
              onChange={handleSearch}
              placeholder="Search..."
              inputProps={{ "aria-label": "search google maps" }}
            />
            {!isSearching ? (
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search">
                <SearchIcon />
              </IconButton>
            ) : (
              <IconButton
                type="button"
                onClick={(e) => handleCancel(e)}
                className={classes.iconButton}
                aria-label="search">
                <CancelIcon />
              </IconButton>
            )}
          </Paper>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        lg={5}
        display={"flex"}
        gap={3}
        mt={{ xs: 2, lg: 0 }}>
        {hasGroupSearch && (
          <>
            <Button
              variant="contained"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{ height: "40px", textTransform: "none" }}
              onClick={handleClickGroupMenu}>
              <FilterListIcon />
              <Typography
                sx={{ fontSize: { xs: "13px", sm: "14px" } }}
                variant="subtitle2"
                pl={1}>
                {selectedGroup && groups.length > 0
                  ? groups.find((group) => group._id === selectedGroup)
                      ?.title || ""
                  : "Select Group"}
              </Typography>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={(event) => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}>
              <MenuItem
                key="all"
                value="all"
                onClick={(e) => handleCloseGroupMenu(e, "all")}>
                Select All
              </MenuItem>
              {renderGroupMenu}
            </Menu>
          </>
        )}
        {hasTagSearch && (
          <Autocomplete
            multiple
            id="tags-outlined"
            size="small"
            options={testTags}
            getOptionLabel={(option) => option.title}
            defaultValue={[testTags[0]]}
            filterSelectedOptions
            ChipProps={{ color: "primary", variant: "outlined" }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Select tag" placeholder="Tags" />
            )}
          />
        )}
        {hasServerSelector && (
          <>
            <Button
              variant="contained"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{ height: "40px", textTransform: "none" }}
              onClick={handleClickChannelServerMenu}>
              <FilterListIcon />
              <Typography
                sx={{ fontSize: { xs: "13px", sm: "14px" } }}
                variant="subtitle2"
                pl={1}>
                {selectedServer && channelServers.length > 0
                  ? channelServers.find(
                      (server) => server._id === selectedServer
                    )?.title || ""
                  : "Select Server"}
              </Typography>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorChannel}
              open={openChannelMenu}
              onClose={(event) => setAnchorChannel(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}>
              {renderChannelServerMenu}
            </Menu>
          </>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        lg={2}
        textAlign={"right"}
        mt={{ xs: 2, lg: 0 }}>
        <Button
          variant="outlined"
          sx={{ height: "40px" }}
          onClick={handleRefresh}>
          <AutorenewIcon />
        </Button>
        {hasAddNew && (
          <Button
            variant="contained"
            sx={{ height: "40px", ml: 1, textTransform: "none" }}
            onClick={onCreateNew}>
            {newButtonTitle}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

SearchBar.defaultProps = {
  hasSearchInput: true,
  isRequestedRefresh: false,
  pageType: "",
  hasGroupSearch: false,
  hasTagSearch: false,
  hasAddNew: false,
  hasServerSelector: false,
  hasForceRefresh: false,
  newButtonTitle: "Add New",
};

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
  isRequestedRefresh: PropTypes.bool,
  onRefresh: PropTypes.func,
  onCreateNew: PropTypes.func,
  hasSearchInput: PropTypes.bool,
  hasForceRefresh: PropTypes.bool,
  hasGroupSearch: PropTypes.bool,
  hasTagSearch: PropTypes.bool,
  hasServerSelector: PropTypes.bool,
  hasAddNew: PropTypes.bool,
  groups: PropTypes.array,
  channelServerList: PropTypes.array,
  newButtonTitle: PropTypes.string,
};

export default memo(SearchBar);

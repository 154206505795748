import React, { useCallback, useEffect, useState, useRef } from "react";
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import ChannelActions from "./actions";
import ChannelInOut from "./input";
import ChannelSettings from "./settings";
import { switchUseStyles } from "./actions";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import StatusChip from "../Common/statusLabel";
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { paramGet } from "../../apis/CommonApi";
import { calcVideoQuality } from "../../utils";
import { isManager } from "../../utils";
import { ToastsStore } from "react-toasts";
import { paramPost } from "../../apis/CommonApi";


const ChannelDetailDialog = ({ channel, isOpen, onClose, authUser }) => {
  const classes = switchUseStyles();
  const { serverInfo } = channel;
  const playerRef = useRef(null);

  const [channelOrigin, setChannelOrigin] = useState(null);
  const [channelDetail, setChannelDetail] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [pushStatus, setPushStatus] = useState({ first: false, second: false });
  const [playerUrl, setPlayerUrl] = useState(`${serverInfo.host}/${channel.name}/index.m3u8`);

  const userRole = authUser.user ? authUser.user.mode.name : "NO_USER";

  const handlePushStatus = useCallback(async (event, index) => {
    if (!channelDetail || !channelDetail.pushes) return;
    const currentUserIsManager = isManager(userRole);
    if (!currentUserIsManager) return;
    const currentChecked = event.target.checked;
    setPushStatus(prevState => {
      return {
        ...prevState,
        [index]: currentChecked
      }
    });
    //
    try {
      const pushes = channelDetail.pushes;
      if (!pushes.length) {
        return
      }
      const newPushes = [];
      pushes.forEach((push, i) => {
        delete push.stats;
        if (index === 'first' && i === 0) {
          newPushes.push({ ...push, disabled: !currentChecked });
        }
        else if (index === 'second' && i === 1) {
          newPushes.push({ ...push, disabled: !currentChecked });
        }
        else {
          newPushes.push(push);
        }
      });
      const res = await paramPost(
        "/api/channel/change_stream_status",
        { serverId: serverInfo._id, streamName: channel.name, data: { pushes: newPushes } },
      );
      // resetStreamDetail(res.data.stream);
      await getChannelDetail();
      // Show toast message
      ToastsStore.success('Status updated successfully!');
    } catch (e) {
      ToastsStore.error('Something went wrong! Please try again.');
      setPushStatus(prevState => {
        return {
          ...prevState,
          [index]: !currentChecked
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel.name, channelDetail, serverInfo._id, userRole]);

  const getChannelDetail = useCallback(async () => {
    try {
      const response = await paramGet(`/api/channel/streams`, { serverId: serverInfo._id, streamName: channel.name });
      const data = response.streamDetail;
      resetStreamDetail(data);
    } catch (error) {
      console.error("Error getting channel detail: ", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, serverInfo]);

  const handleUpdateStream = useCallback(async (stream) => {
    // if (stream.isDisabled) {
    //   setIsPlaying(false);
    // }

    // resetStreamDetail(stream);

    await getChannelDetail();
    // onClose({hasUpdated: true, channel});
  }, [getChannelDetail]);

  const resetStreamDetail = (data) => {
    // Logic to filter channel detail
    const isDisabled = data.disabled;
    /// Title
    const channelTitle = channel.title || data.title || "";
    const channelName = data.name || "";
    // Video url
    // const playerUrl = isDisabled ? "" : `http://${serverInfo.host}:${serverInfo.port}/${channelName}/index.m3u8`;
    // const playerUrl = `http://${serverInfo.host}:${serverInfo.port}/${channelName}/index.m3u8`;
    // Source Logic
    let activeSource = "";
    let inActiveSource = "";
    if (isDisabled) {
      activeSource = data.inputs[0] ? data.inputs[0].url || "" : "";
      inActiveSource = data.inputs[1] ? data.inputs[1].url : "";
    } else {
      activeSource = !data.inputs ? "" : data.inputs.find(input => input.stats.active === true)?.url || "";
      inActiveSource = !data.inputs ? "" : data.inputs.find(input => input.stats.active === false)?.url || "";
    }
    /// Input
    const stats = data.stats;
    const tracks = !data.stats.media_info ? null : data.stats.media_info.tracks;
    const runningStatus = data.disabled ? false : true;
    let videoTrack = null;
    let audioTrack = null;
    if (tracks && tracks.length > 0) {
      videoTrack = tracks.find(item => item.content === 'video');
      audioTrack = tracks.find(item => item.content === 'audio');
    }
    const videoCodec = videoTrack ? videoTrack.codec : "";
    const inputQuality = videoTrack ? calcVideoQuality(videoTrack.height) : "";
    const inputBandwidth = stats.inputs_bandwidth ? `${videoCodec} ${Math.round(stats.inputs_bandwidth / 1000)}kbps` : '';
    const inputVideoWidthAndHeight = videoTrack ? `${videoCodec} ${videoTrack.width} x ${videoTrack.height} ${inputQuality}` : "";
    const inputAudioRate = audioTrack ? `${audioTrack.codec} ${audioTrack.bitrate}kbps` : "";
    /// Settings
    const transcoderStatus = stats.running_transcoder ? "on" : "off";
    const templateQuality = data.template || "";
    const dvrStatus = stats.dvr_enabled ? "on" : "off";
    /// Output
    let outputBandwidth = "";
    let outputQuality = "";
    let outputVideoWidthAndHeight = "";
    let outputAudioRate = "";
    if (stats.running_transcoder && videoTrack && audioTrack) {
      outputBandwidth = `${videoCodec} ${videoTrack.bitrate}kbps` || '';
      outputQuality = calcVideoQuality(videoTrack.height) || "";
      outputVideoWidthAndHeight = videoTrack ? `${videoCodec} ${videoTrack.width} x ${videoTrack.height} ${outputQuality}` : "";
      outputAudioRate = audioTrack ? `${audioTrack.codec} ${audioTrack.bitrate}kbps` : "";
    } else {
      outputBandwidth = inputBandwidth;
      outputQuality = inputQuality;
      outputVideoWidthAndHeight = inputVideoWidthAndHeight;
      outputAudioRate = inputAudioRate;
    }
    /// OUTPUT at bottom
    const pushes = data.pushes;
    let outputUrl1 = "";
    let outputStatus1 = false;
    let outputUrl2 = "";
    let outputStatus2 = false;
    if (pushes && pushes.length > 1) {
      outputUrl1 = pushes[0].url;
      outputStatus1 = !pushes[0].stats ? false : pushes[0].stats.status === "running";
      outputUrl2 = pushes[1].url;
      outputStatus2 = !pushes[1].stats ? false : pushes[1].stats.status === "running";
    }

    // setIsPlaying(!isDisabled);
    // Set origin detail
    setChannelOrigin(data);
    // Set channel detail
    setChannelDetail({
      isDisabled,
      channelName,
      runningStatus,
      activeSource,
      inActiveSource,
      channelTitle,
      inputBandwidth,
      inputQuality,
      inputVideoWidthAndHeight,
      inputAudioRate,
      transcoderStatus,
      templateQuality,
      dvrStatus,
      outputBandwidth,
      outputQuality,
      outputVideoWidthAndHeight,
      outputAudioRate,
      outputUrl1,
      outputStatus1,
      outputUrl2,
      outputStatus2,
      pushes
    });
    setPushStatus({
      first: outputStatus1,
      second: outputStatus2
    });
  }

  useEffect(() => {
    getChannelDetail();
    const timer = setInterval(() => {
      getChannelDetail();
    }, 10000);
    return () => clearTimeout(timer);
  }, [getChannelDetail]);

  useEffect(() => {
    if (channelDetail) {
      const { isDisabled, channelName } = channelDetail;
      if (!isDisabled) {
        setTimeout(() => {
          setPlayerUrl(`${serverInfo.host}/${channelName}/index.m3u8`);
        }, 3000);
      } else {
        setPlayerUrl('');
      }
    }
  }, [channelDetail, serverInfo]);

  const handleFullScreen = () => {
    const player = playerRef.current;
    if (player && player.wrapper) {
      // `player.wrapper` refers to the div around the ReactPlayer
      const videoWrapper = player.wrapper;
      if (videoWrapper.requestFullscreen) {
        videoWrapper.requestFullscreen(); // Standard method
      } else if (videoWrapper.mozRequestFullScreen) { /* Firefox */
        videoWrapper.mozRequestFullScreen();
      } else if (videoWrapper.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        videoWrapper.webkitRequestFullscreen();
      } else if (videoWrapper.msRequestFullscreen) { /* IE/Edge */
        videoWrapper.msRequestFullscreen();
      }
    }
  };

  const {
    isDisabled,
    channelName,
    runningStatus,
    activeSource,
    inActiveSource,
    channelTitle,
    inputBandwidth,
    inputQuality,
    inputVideoWidthAndHeight,
    inputAudioRate,
    transcoderStatus,
    templateQuality,
    dvrStatus,
    outputBandwidth,
    outputVideoWidthAndHeight,
    outputAudioRate,
    outputUrl1,
    outputStatus1,
    outputUrl2,
    outputStatus2
  } = channelDetail || {};

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}>
      <DialogTitle textAlign="right">
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {channelDetail ? (
        <DialogContent>
          <Typography variant="h6">
            {`${channelName} ${channelTitle ? ` | ${channelTitle}` : ""}`}
          </Typography>
          <Box
            my={3}
            gap={4}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)',
              md: 'repeat(1, 1fr)',
              lg: 'repeat(2, 1fr)',
            }}
          >
            <Stack direction="column" spacing={2}>
              <Box>
                {playerUrl ? (
                  <ReactPlayer
                    key={playerUrl}
                    ref={playerRef}
                    playing={isPlaying}
                    muted={isMuted}
                    url={playerUrl}
                    onReady={() => console.log('onReady')}
                    onStart={() => console.log('onStart')}
                    onPlay={() => console.log('onPlay')}
                    onPause={() => console.log('onPause')}
                    onError={(e) => console.log('onError', e)}
                    width='100%'
                    height="auto"
                    allowFullScreen
                  />) : (
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>Channel disabled</Typography>
                )}
              </Box>
              <Stack direction="row" spacing={1}>
                <Button onClick={() => setIsPlaying(!isPlaying)} variant="contained" color="primary"
                  sx={{ minHeight: 0, minWidth: 0, padding: 0, width: "42px", height: "42px", transition: 'transform .2s', "&:hover": { transform: 'scale(1.2)' } }}>
                  {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                </Button>
                <Button onClick={() => setIsMuted(!isMuted)} variant="contained" color="primary"
                  sx={{ minHeight: 0, minWidth: 0, padding: 0, width: "42px", height: "42px", transition: 'transform .2s', "&:hover": { transform: 'scale(1.2)' } }}>
                  {!isMuted ? <VolumeUpIcon /> : <VolumeOffIcon />}
                </Button>
                <Button onClick={handleFullScreen} variant="contained" color="primary"
                  sx={{ minHeight: 0, minWidth: 0, padding: 0, width: "42px", height: "42px", transition: 'transform .2s', "&:hover": { transform: 'scale(1.2)' } }}>
                  <FullscreenIcon />
                </Button>
              </Stack>
            </Stack>
            <Stack direction="column" spacing={5}>
              <Box
                // my={3}
                gap={3}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
              >
                <Stack direction="column" spacing={1}>
                  <Typography variant="body1" fontWeight={600}>Sources</Typography>
                  <Stack direction="row" spacing={1} alignItems="center" width={"100%"}>
                    <FiberManualRecordIcon sx={{ color: `${isDisabled ? '#B0B0B0' : '#09E165'}`, fontSize: '12px' }} />
                    <Typography sx={{ color: "#414141", fontWeight: 200, wordWrap: 'break-word', wordBreak: 'break-all' }}>
                      {activeSource}
                    </Typography>
                    <StatusChip label={"current"} type={isDisabled ? "disabled" : "online"} />
                  </Stack>
                  {inActiveSource && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <FiberManualRecordIcon sx={{ color: '#B0B0B0', fontSize: '12px' }} />
                      <Typography sx={{ color: "#414141", fontWeight: 200, wordWrap: 'break-word', wordBreak: 'break-all' }}>
                        {inActiveSource}
                      </Typography>
                      <IconButton>
                        <StatusChip label={<SyncAltIcon />} type="sync" />
                      </IconButton>
                    </Stack>
                  )}
                </Stack>
                <ChannelActions
                  isManager={isManager(userRole)}
                  runningStatus={runningStatus}
                  channelName={channel.name}
                  serverInfo={channel.serverInfo}
                  sx={{ width: "3.5rem" }}
                  mainSX={{ justifyContent: { sm: 'end', xs: 'start' } }}
                  onChangeStatus={handleUpdateStream} />
              </Box>

              <Box
                // my={1}
                gap={3}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                }}
              >
                <Stack direction="column" spacing={1}>
                  <Typography color="primary" variant="body1" fontWeight={600} sx={{ textTransform: 'uppercase' }}>Input</Typography>
                  <ChannelInOut video={inputBandwidth} audio={inputAudioRate} resolution={inputVideoWidthAndHeight} />
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography color="primary" variant="body1" fontWeight={600} sx={{ textTransform: 'uppercase' }}>SETTINGS</Typography>
                  <ChannelSettings transcoderStatus={transcoderStatus} dvrStatus={dvrStatus} template={templateQuality} quality={inputQuality} />
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography color="primary" variant="body1" fontWeight={600} sx={{ textTransform: 'uppercase' }}>Output</Typography>
                  <ChannelInOut video={outputBandwidth} audio={outputAudioRate} resolution={outputVideoWidthAndHeight} />
                </Stack>
              </Box>

              <Stack direction="column" spacing={2}>
                <Typography color="primary" variant="body1" fontWeight={600} sx={{ textTransform: 'uppercase' }}>Output</Typography>
                {outputUrl1 && (<>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <StatusChip label={outputStatus1 ? "online" : "stopped"} type={outputStatus1 ? "success" : "error"} sx={{ width: "4rem" }} />
                      <Typography sx={{ color: "#414141", fontWeight: 200, fontSize: '14px' }}>
                        {outputUrl1}
                      </Typography>
                    </Stack>
                    <Switch
                      sx={{ transition: 'transform .2s', "&:hover": { transform: 'scale(1.2)' } }}
                      classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked
                      }}
                      checked={pushStatus.first}
                      onChange={(event) => handlePushStatus(event, 'first')}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </Box>
                  <Divider />
                </>)}
                {outputUrl2 && (
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <StatusChip label={outputStatus2 ? "online" : "stopped"} type={outputStatus2 ? "success" : "error"} sx={{ width: "4rem" }} />
                      <Typography sx={{ color: "#414141", fontWeight: 200, fontSize: '14px' }}>
                        {outputUrl2}
                      </Typography>
                    </Stack>
                    <Switch
                      sx={{ transition: 'transform .2s', "&:hover": { transform: 'scale(1.2)' } }}
                      classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked
                      }}
                      checked={pushStatus.second}
                      onChange={(event) => handlePushStatus(event, 'second')}
                      name="checkedB"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </Box>
                )}
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
      ) : (
        <Box p={5}>
          <LinearProgress sx={{ maxWidth: "500px", m: 'auto' }} />
        </Box>
      )}
    </Dialog>
  );
}

const mapStateToProps = ({ authUser }) => ({
  authUser
});

export default connect(mapStateToProps, {})(ChannelDetailDialog);

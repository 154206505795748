import config from '../config';
export const checkNotEmptyValues = (...params) => {
  return params.every(param => !!param)
}

export const loginUser = () => {
  let user = localStorage.getItem(config.userLocalStorage)
  if(user) {
    user = JSON.parse(user)
    return user.mode.name
  }
  return null
}

export const calcVideoQuality = (height) => {
  if (height > 720) {
    return "FHD";
  } else if (height === 720) {
    return "HD";
  } else {
    return "SD";
  }
};

export const isAdmin = (userRole) => {
  return ["superadmin", "admin"].includes(userRole);
}

export const isManager = (userRole) => {
  return ["superadmin", "admin", "manager"].includes(userRole);
}

import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Button, Stack, TextField, Typography} from "@mui/material";
import ActionButton from '../../../components/Common/action-button';

function AdvancedParams(props) {
    const {values, onConfigChange, basicSetting} = props;
    const [params, setParams] = useState([]);

    useEffect(() => {
        setParams(values)
    }, [])

    const removeParam = (index) => {
        values.splice(index, 1);
        setParams(values);
        setParams([...values], onConfigChange(values));
    }
    const addNewParam = () => {
        values.push({
            key: '',
            value: '',
        });
        setParams([...values], onConfigChange(values));
    }

    const onChange = (evt, index, type) => {
        if (type === 0) {
            values[index].key = evt.target.value.trim();
        } else {
            values[index].value = evt.target.value;
        }
        setParams([...values], onConfigChange(values));
    }

    return (
        <div className="output" style={{width: "100%"}}>
            <div className="actions">
                {
                    basicSetting.inputType === 'aCANFeeder' || basicSetting.inputType === 'SRT' ?
                        <Button variant='contained' sx={{ textTransform: 'none' }} onClick={addNewParam}>Add New Parameter</Button>
                        : ''
                }
            </div>
            {
                params.map((element, index) => (
                    <React.Fragment key={index}>
                        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                            <Stack direction={{ xs: "column", sm: "row" }} spacing={2} alignItems={{ xs: "start", sm: "center" }}>
                                <Typography variant='title1'>Name {index + 1}:</Typography>
                                <TextField className="protocal" fullWidth value={element.key || ''} onChange={evt => {
                                    onChange(evt, index, 0)
                                }}/>
                            </Stack>
                            <Stack direction={{ xs: "column", sm: "row" }} spacing={2} alignItems={{ xs: "start", sm: "center" }}>
                                <Typography variant='title1'>Value {index + 1}:</Typography>
                                <TextField className="ip" fullWidth value={element.value || ''} onChange={evt => {
                                    onChange(evt, index, 1)
                                }}/>
                            </Stack>
                            <ActionButton handleClick={() => removeParam(index)} type="delete" />
                        </Stack>
                    </React.Fragment>
                ))
            }
        </div>
    )
}

AdvancedParams.propTypes = {
    values: PropTypes.array.isRequired,
    basicSetting: PropTypes.object.isRequired,
    onConfigChange: PropTypes.func.isRequired
}

export default AdvancedParams
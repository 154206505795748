import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ServerCreatingDialog from '../../components/Servers/addDialog'
import {
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Box
} from '@mui/material'
import { paramPost, noParamGet, paramGet, paramPut, noParamDelete } from '../../apis/CommonApi'
import { ToastsStore } from 'react-toasts'
import SearchBar from '../../components/Common/searchbar'
import { showAlert, setLoading } from '../../action'
import StatusChip from '../../components/Common/statusLabel';
import ActionButton from '../../components/Common/action-button'
import './style.scss'

const Servers = (props) => {
    const [open, setOpen] = useState(false)
    const [servers, setServers] = useState([])
    const [groups, setGroups] = useState([])
    const [editServerId, setEditServerId] = useState(undefined);
    const [statusList, setStatusList] = useState([]);
    const [param, setParam] = useState({
        title: "",
        host: "",
        port: "",
        serverType: "acants",
        apiKey: "",
        description: "",
        location: "",
        groupIds: [],
        adapterIps: [],
    })

    useEffect(() => {
        props.setLoading(true)
        getServers()
        getGroups()
    }, [])

    const refresh = async () => {
        props.setLoading(true)
        await getServers()
        await getGroups()
    }

    const getServers = async () => {
        await paramGet("/api/servers", { type: "all" })
            .then(async res => {
                setServers(res);
                props.setLoading(false);
            })
            .catch(err => {
                props.setLoading(false);
                throw err
            })
    }

    const getStatus = async (servers) => {
        const status = [];
        await Promise.all(servers.map(async (server) => {
            await noParamGet(`/api/servers/status/${server.id}`)
                .then(res => {
                    if (res) {
                        status.push(server._id);
                    }
                })
                .catch(err => {
                    //
                })
        }));
        setStatusList(status);
    }

    useEffect(() => {
        if (servers.length > 0) {
            getStatus(servers);
        }
    }, [servers])


    const getGroups = async () => {
        await noParamGet("/api/group")
            .then(res => {
                setGroups(res.data)
                // props.setLoading(false);
            })
            .catch(err => {
                props.setLoading(false);
                throw err
            })
    }

    const resetParam = () => {
        setParam({
            title: "",
            host: "",
            port: "",
            serverType: "acants",
            apiKey: "",
            description: "",
            location: "",
            groupIds: [],
            adapterIps: []
        })
    }

    const handleOpen = () => {
        resetParam()
        setEditServerId(undefined)
        setOpen(!open)
    }

    const handleClose = async (val) => {
        if (val) {
            if (val.groupIds.length) {
                val.groupIds = val.groupIds.map((group) => {
                    return group.split("___")[0]
                })
            }
            if (!editServerId) {
                await paramPost("/api/servers", val)
                    .then(async res => {
                        ToastsStore.success('Server added successfully');
                        resetParam()
                        getServers()
                        setOpen(!open)
                    })
                    .catch(err => {
                        ToastsStore.error(err.data.error || 'Failed to create server');
                    })
            } else {
                await paramPut(`/api/servers/${editServerId}`, val)
                    .then(async res => {
                        ToastsStore.success('Server updated successfully');
                        resetParam()
                        await getServers()
                        setOpen(!open)
                    })
                    .catch(err => {
                        ToastsStore.error(err.data.error || 'Failed to update server');
                    })
            }
        } else {
            setOpen(!open)
        }
    }

    const editServer = (server) => {
        setEditServerId(server._id)
        let serverData = server;
        if (serverData.groupInfo && serverData.groupInfo.length) {
            const groupIds = server.groupInfo.map(group => {
                return (
                    group._id + "___" + group.title
                )
            });
            serverData.groupIds = groupIds
        }
        if (!server.serverType) {
            serverData.serverType = "acants"
        }
        setParam(serverData)
        setOpen(true);
    }

    const handleRemoveServer = async (id) => {
        await noParamDelete(`/api/servers/${id}`)
            .then(async res => {
                ToastsStore.success('Server deleted successfully')
            })
            .catch(err => {
                ToastsStore.error(err.data.error || 'Failed to delete server');
            })
    }

    const removeServer = (groupId) => {
        props.showAlert('Are you sure?', 'Are you sure you want to delete this Server?', async () => {
            await handleRemoveServer(groupId);
            await getServers();
        });
    }

    const getStatusChip = (serverId) => {
        if (statusList.includes(serverId)) {
            return <StatusChip label="Online" type="success" />;
        }
        return <StatusChip label="Offline" type="error" />;
    }

    const serverList = () => {
        if (servers.length) {
            return (
                servers.map(server => (
                    <TableRow key={server._id}>
                        <TableCell>{server.title}</TableCell>
                        <TableCell>{server.host}</TableCell>
                        <TableCell>{server.port}</TableCell>
                        <TableCell>{server.groupInfo && server.groupInfo.length ?
                            server.groupInfo.map((elem) => {
                                return elem.title;
                            }).join(", ") : ""}</TableCell>
                        <TableCell>
                            {getStatusChip(server.id)}
                        </TableCell>
                        <TableCell>
                            <ActionButton handleClick={() => editServer(server)} type='edit' label='Edit Server' />
                            <ActionButton handleClick={() => removeServer(server._id)} type='delete' label='Delete Server' />
                        </TableCell>
                    </TableRow>
                ))
            )
        }

    }

    return (
        <>
            <SearchBar
                onChange={() => console.log("searching")}
                pageType='Server'
                onRefresh={refresh}
                onCreateNew={handleOpen}
                hasSearchInput={false}
                hasAddNew
                newButtonTitle="Add Server" />
            <Box sx={{ mt: 3, px: 3 }}>
                <TableContainer>
                    <Table>
                        <TableHead sx={{ bgcolor: '#ECEFF9' }}>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Host</TableCell>
                                <TableCell>Port</TableCell>
                                <TableCell>Groups</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell width="15%">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {serverList()}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ServerCreatingDialog open={open} onClose={handleClose} params={param} groups={groups} isEdit={!!editServerId} />
            </Box>
        </>
    )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, { showAlert, setLoading })(Servers);

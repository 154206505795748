import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from "@mui/material/DialogContent"
import { DialogTitle, DialogActions } from '@mui/material'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import {FormHelperText} from "@mui/material"
import {setLoading} from "../../action";
import {connect} from "react-redux";

export const ContributionSelectingDialog = (props) => {
    const { onClose, open, contributions, addOutput } = props

    const Schema = Yup.object().shape({
        contribution: Yup.string()
            .test("validation", "Select input", (value) => value !== 1)
            .required('This field is required')
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            contribution: "1",
        },
        validationSchema: Schema,
        onSubmit: (values, {resetForm}) => {
            if(values.contribution !== '1') {
                let contributionSelected = contributions.find(item => item.id === values.contribution)
                addOutput(contributionSelected)
                handleClose(values)
            }
        },
    });

    const handleClose = (val = false) => {
        onClose(val)
    };

    const selectOptions = (items) => {
        return items.map((item, index) => {
          return (
              <MenuItem value={item.id} key={index}>{item.title}</MenuItem>
          )
        })
    }

    const {errors, touched, handleSubmit, getFieldProps, values} = formik;
    
    return (
        <Dialog disableEscapeKeyDown={true} onClose={ (event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                handleClose()
            }
        }} open={open} fullWidth>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <DialogTitle>Selcet an input</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    className='width-100'
                                    error={Boolean(touched.contribution && errors.contribution)}
                                    fullWidth
                                 >
                                    <InputLabel id="label">
                                        Select Input
                                    </InputLabel>
                                    <Select
                                        labelId="label"
                                        label="Select Input"
                                        {...getFieldProps('contribution')}
                                    >
                                        <MenuItem value={1} disabled={true}>
                                            === Select ===
                                        </MenuItem>
                                        {contributions.length && selectOptions(contributions)}
                                    </Select>
                                    <FormHelperText>{touched.contribution && errors.contribution}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className='mx-1'>
                        <Button type='submit' disabled={values.server === "1"} variant="contained" color="primary">
                            ok
                        </Button>
                        <Button type='reset' variant="contained" color="secondary" className='ml-1' onClick={() => {formik.resetForm(); handleClose()}}>
                            close
                        </Button>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}

ContributionSelectingDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    addOutput: PropTypes.func.isRequired,
    contributions: PropTypes.array.isRequired,
}
const mapStateToProps = (state) => ({})

const mapDispatchToProps = {setLoading}

export default connect(mapStateToProps, mapDispatchToProps)(ContributionSelectingDialog)
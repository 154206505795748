import React, {useState} from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from "@mui/material/DialogContent"
import { DialogTitle, DialogActions, ListItemText, Input, Checkbox, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import {FormHelperText} from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { ToastsStore } from 'react-toasts';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const ServerCreatingDialog = (props) => {
    const { onClose, open, params, groups, isEdit } = props;
    const initIps = params.title ? params.adapterIps : [];
    const [ips, setIps] = useState([]);
    const [adapterName, setAdapterName] = useState('');
    const [adapterIp, setAdapterIp] = useState('');

    React.useEffect(() => {
        setIps(initIps);
    }, [params])

    const Schema = Yup.object().shape({
        title: Yup.string().required('Input title'),
        description: Yup.string(),
        host: Yup.string().required('Input host'),
        serverType: Yup.string().required('Select type'),
        port: Yup.number().required('Input port'),
        apiKey: !isEdit ? Yup.string().required('Input api key') : Yup.string(),
        location: Yup.string(),
        groupIds: Yup.array().required('Select group'),
        // adapterIps: Yup.string().required('Input adpater ip')
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: params,
        validationSchema: Schema,
        onSubmit: (values, {resetForm}) => {
            if(!ips.length) {
                return ToastsStore.warning("Please input adapter ips")
            }
            values.adapterIps = ips
            handleClose(values)
            resetForm()
        },
    });

    const handleClose = (val = false) => {
        onClose(val)
    };

    const renderOption = (vals) => {
      let sels =  vals.map(selectedVal => {
        return (
          selectedVal.split("___")[1]
        )
      });
      return sels.join(', ');
    }

    const handleAdapters = () => {
        if(adapterName && adapterIp) {
            let updatedIps = ips;
            updatedIps.push({adapterName, adapterIp});
            setIps(updatedIps);
            setAdapterIp('');
            setAdapterName('');
        }
    }

    const handleRemoveIp = (index) => {
        let updatedIps = ips;
        updatedIps.splice(index, 1);
        setIps([...ips]);
    }

    const addedIps = () => {
        return ips.map((item, index) => {
            return (
                <div key={index} className="inline-items">
                    <Typography color='primary'>{item.adapterName} - {item.adapterIp}</Typography>
                    <DeleteIcon className='gray' onClick={() => handleRemoveIp(index)} />
                </div>
            )
        })
    }

    const {errors, touched, handleSubmit, getFieldProps, values} = formik;
    
    return (
        <Dialog 
            disableEscapeKeyDown={true} 
            onClose={(event, reason) => { 
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleClose()
                }
            }} 
            open={open}
        >
            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <DialogTitle>Manage Servers</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Title"
                                    type={"text"}
                                    variant="outlined"
                                    {...getFieldProps('title')}
                                    color={"primary"}
                                    sx={{ width: '100%' }}
                                    error={Boolean(touched.title && errors.title)}
                                    helperText={touched.title && errors.title}
                                />    
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    className='width-100'
                                    error={Boolean(touched.groupIds && errors.groupIds)}
                                 >
                                  <InputLabel id="label">
                                      Select Group Admin
                                  </InputLabel>
                                  <Select
                                      labelId="label"
                                      label="Select Group Admin"
                                      multiple
                                      input={<Input />}
                                      renderValue={(selected) => renderOption(selected)}
                                      MenuProps={MenuProps}
                                      value={values.groupIds}
                                      onChange={(e) => formik.setFieldValue('groupIds', e.target.value)}
                                  >
                                      {groups.length > 0 && groups.map((group) => (
                                        <MenuItem key={group.id} value={group.id + "___" + group.title}>
                                          <Checkbox checked={values.groupIds.indexOf(group.id + "___" + group.title) > -1} />
                                          <ListItemText primary={group.title} />
                                        </MenuItem>
                                      ))}
                                  </Select>
                                  <FormHelperText>{touched.groupIds && errors.groupIds}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    className='width-100'
                                    error={Boolean(touched.type && errors.type)}
                                 >
                                  <InputLabel id="label">
                                      Select Server Type
                                  </InputLabel>
                                  <Select
                                      labelId="label"
                                      label="Select Server Type"
                                      input={<Input />}
                                      renderValue={(selected) => selected.toUpperCase()}
                                      MenuProps={MenuProps}
                                      value={values.serverType}
                                      onChange={(e) => formik.setFieldValue('serverType', e.target.value)}
                                  >
                                      <MenuItem value="acants">Acants</MenuItem>
                                      <MenuItem value="channel">Channel</MenuItem>
                                  </Select>
                                  <FormHelperText>{touched.groupIds && errors.groupIds}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Api Key"
                                    type={"text"}
                                    sx={{ width: '100%' }}
                                    variant="outlined"
                                    {...getFieldProps('apiKey')}
                                    color={"primary"}
                                    error={Boolean(touched.apiKey && errors.apiKey)}
                                    helperText={touched.apiKey && errors.apiKey}
                                />    
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Name"
                                    type={"text"}
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    color={"primary"}
                                    value={adapterName}
                                    onChange={(e) => setAdapterName(e.target.value)}
                                />   
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    label="Adapter IP"
                                    type={"text"}
                                    sx={{ width: '100%' }}
                                    variant="outlined"
                                    color={"primary"}
                                    value={adapterIp}
                                    onChange={(e) => setAdapterIp(e.target.value)}
                                /> 
                            </Grid>
                            <Grid item xs={3} style={{textAlign: 'right'}}>
                                <Button 
                                    variant='contained'
                                    sx={{ height: '100%' }} 
                                    onClick={() => handleAdapters()}>
                                    <AddIcon />
                                </Button>
                            </Grid>
                            {ips && (
                                <Grid item xs={12}>
                                    {addedIps()}
                                </Grid>
                            )}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Host"
                                    type={"text"}
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    {...getFieldProps('host')}
                                    color={"primary"}
                                    error={Boolean(touched.host && errors.host)}
                                    helperText={touched.host && errors.host}
                                />    
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Port"
                                    type={"number"}
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    {...getFieldProps('port')}
                                    color={"primary"}
                                    error={Boolean(touched.port && errors.port)}
                                    helperText={touched.port && errors.port}
                                />    
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Location"
                                    type={"text"}
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    {...getFieldProps('location')}
                                    color={"primary"}
                                    error={Boolean(touched.location && errors.location)}
                                    helperText={touched.location && errors.location}
                                />    
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Description"
                                    type={"text"}
                                    sx={{ width: '100%' }}
                                    variant="outlined"
                                    {...getFieldProps('description')}
                                    color={"primary"}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                />    
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className='mx-1'>
                        <Button type='submit' disabled={
                            !values.title ||
                           !values.host || 
                           !values.port || 
                           (!isEdit && !values.apiKey) ||
                           !values.groupIds.length 
                          } variant="contained" color="primary">
                            {params.title ? 'edit' : 'save'}
                        </Button>
                        <Button type='reset' variant="contained" color="secondary" className='ml-1' onClick={() => {formik.resetForm(); handleClose()}}>
                            close
                        </Button>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}

ServerCreatingDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    groups: PropTypes.array.isRequired,
    isEdit: PropTypes.bool
}

export default (ServerCreatingDialog)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'

import { hideAlert } from '../../action'

class AlertDialog extends Component {

  onAction = async () => {
    this.props.hideAlert();
    await this.props.alertDialog.onAction();
  }
  render() {
    const { open, title, content } = this.props.alertDialog;
    return (
      <Dialog open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button onClick={this.onAction}>Ok</Button>
          <Button onClick={this.props.hideAlert}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ alertDialog }) => ({
  alertDialog
})

export default connect(mapStateToProps, {
  hideAlert
})(AlertDialog)

import config from "../config";

export default () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(config.userToken)}`,
      'ngrok-skip-browser-warning': true
    }
  };
};

import React from 'react'
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Typography} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, headerList, isSubHeader } = props;

  return (
    <TableHead className={isSubHeader ? "back-gray" : "default-table-header-bg"}>
      <TableRow>
        {!isSubHeader && (
        <TableCell padding="checkbox">
        </TableCell>
        )}
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headerList.map((headCell, index) => (
          <TableCell
            key={index}
            align='left'
          >
            <Typography
            >
              {headCell}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  headerList: PropTypes.array.isRequired,
  isSubHeader: PropTypes.bool.isRequired
};

export default EnhancedTableHead;
import React, {useEffect, useState} from 'react'
import {TextField, MenuItem, Box, Typography, Stack} from '@mui/material'
import PropTypes from 'prop-types'

function SRT(props) {

  const {values, onConfigChange, keys, onModeChange} = props
  const [params, setParams] = useState({})
  const [manualValue, setManualValue] = useState('select');

    useEffect(() => {
    setParams(values)
      for (let i=0; i < keys.length; i++) {
        if(keys[i].type === 'text' && values.mode === 'Push' && keys[i].field === 'host') {
          setManualValue('manual');
          break;
        } else if(keys[i].type === 'select' && values.mode === 'Push' && keys[i].field === 'host') {
          setManualValue('select');
          break;
        }
      }
  }, [values])

  const changeValue = (e, field) => {
    let param = params;
    const target = e.target.value
    param[field] = target;
    // if(field === 'adapterIp') {
    //   param.host = target
    // }
    setParams({...params}, onConfigChange(param));
    if(field === 'mode' && ['Listen', 'Push'].includes(target)) {
      onModeChange(target);
    }
  }

  const changeTypeDestinationValue = evt => {
      const value = evt.target.value;
      setManualValue(value);
      props.onDestinationTypeChange(value);
  }

  const renderItem = ({ label, field, type = 'text', children = [] }) => (
    <Stack direction="column" spacing={1} key={field}>
      {field === 'host' ? (
        <Typography variant='body1'>{params.mode === 'Listen' ? "Local IP" : "Destination Host"}</Typography>
      ) : (
        <Typography variant='body1'>{label}</Typography>
      )}
      <Box>
        {
          type === 'select'
            ? (field === 'host' && values.mode === 'Push' ? (
                <Stack direction="row">
                  <TextField
                      select
                      value={params[field] || ''}
                      fullWidth
                      onChange={(e) => changeValue(e, field)} >
                    {
                      children.map((child, index) => <MenuItem value={child.value || child} key={child.value || child}>{child.label || child}</MenuItem>)
                    }
                  </TextField>
                  <TextField
                      select
                      fullWidth
                      value={manualValue}
                      onChange={(e) => changeTypeDestinationValue(e, field)}>
                    <MenuItem value={'manual'} key={1}>Manual</MenuItem>
                    <MenuItem value={'select'} key={2}>Select</MenuItem>
                  </TextField>
                </Stack>
              ) : (
                  <TextField
                      fullWidth select
                      value={params[field] || ''}
                      onChange={(e) => changeValue(e, field)} >
                    {
                      children.map((child, index) => <MenuItem value={child.value || child} key={child.value || child}>{child.label || child}</MenuItem>)
                    }
                  </TextField>
              ))
            : (field === 'host' && values.mode === 'Push' ? (
                <Stack direction="row">
                    <TextField
                        fullWidth
                        className={field === 'host' ? 'destination-host' : ''}
                        InputProps={{
                            // readOnly: field === 'multicast',
                        }}
                        type={type}
                        value={params[field] || ''}
                        onChange={(e) => changeValue(e, field)}/>
                    <TextField
                        fullWidth select
                        value={manualValue}
                        onChange={(e) => changeTypeDestinationValue(e, field)}>
                        <MenuItem value={'manual'} key={1}>Manual</MenuItem>
                        <MenuItem value={'select'} key={2}>Select</MenuItem>
                    </TextField>
                </Stack>
              ): (
                  <TextField
                      fullWidth
                      InputProps={{
                        // readOnly: field === 'multicast',
                      }}
                      type={type}
                      value={params[field] || ''}
                      onChange={(e) => changeValue(e, field)} />
              ))
        }
      </Box>
    </Stack>
  );

  return (
    <Box
      my={3}
      gap={3}
      display="grid"
      width="100%"
      gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
      }}
    >
      {
        keys.map(element => renderItem({ ...element }))
      }
    </Box>
  )
}

SRT.propTypes = {
  values: PropTypes.object.isRequired,
  onConfigChange: PropTypes.func.isRequired,
  onModeChange: PropTypes.func.isRequired,
  keys: PropTypes.array.isRequired,
  onDestinationTypeChange: PropTypes.func
}

export default SRT
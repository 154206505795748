import React, {useEffect, useState} from 'react'
import {Grid, Button, TextField, MenuItem, Paper, AppBar, Tabs, Tab, Tooltip} from '@mui/material'
import {connect} from 'react-redux'
import Encryption from './settingTypes/Encryption';
import SRT from './settingTypes/SRT';
import {noParamGet, noParamPost, paramGet, paramPost, paramPut} from '../../apis/CommonApi';
import {ToastsStore} from 'react-toasts'
import {setLoading} from '../../action';
import './style.scss';
import FEEDER from "./settingTypes/FEEDER";
import AdvancedParams from "./settingTypes/Advanced";
import IconButton from "@mui/material/IconButton";
import {ArrowBack} from "@mui/icons-material";
import {useHistory} from "react-router-dom";


const inputTypes = [
    // 'aCANFeeder',
    'SRT',
    'RIST',
    // 'UDP',
    // 'OTT'
]

export const OutputConfig = (props) => {

    useEffect(() => {
        const {id, serverId, outputId} = props.match.params;
        setServerIdInfo(serverId);
        setIdGroup({
            serverId,
            contributionId: id,
            outputId
        });
        setEditId(outputId);
        getDestinations({page: 0, rowsPerPage: 0, searchValue: ''});
        getServer(serverId, id, outputId);
        getContributions({page: 0, rowsPerPage: 100, searchValue: "", serverId: serverId});
    }, [])

    const history = useHistory();

    const [inputContributions, setInputContributions] = useState([]);
    const [idGroup, setIdGroup] = useState({
        serverId: null,
        contributionId: null,
        outputId: null
    })
    const [basicSetting, setBasicSetting] = useState({
        name: "",
        title: "",
        // streamIndex: 0,
        inputType: "SRT",
        inputId: idGroup.contributionId,
    });
    const [tabIndex, setTabIndex] = useState(0);
    const [editId, setEditId] = useState(null);
    const [srtSettings, setSrtSettings] = useState(
        {
            // mode: 'Listen',
            mode: 'Push',
            host: '0.0.0.0',
            port: '',
            latency: '3000',
            adapterIp: '',
            maxBandWidth: '625000'
        }
    );
    const [feederSettings, setFeederSettings] = useState(
        {
            // mode: 'Listen',
            mode: 'Push',
            host: '',
            port: '',
            latency: '3000',
            adapterIp: '',
            maxBandWidth: '625000'
        }
    );
    const [ristSettings, setRistSettings] = useState({
        // mode: 'Listen',
        mode: 'Push',
        bandWidth: '',
        host: '',
        bufferMin: '',
        port: '',
        reorderBuffer: '',
        profile: '2',
        compression: 'Disabled',
        rtl: '',
        statInterval: '',
        adapterIp: '',
    });

    const [encryption, setEncryption] = useState({
        selection: 'no',
        passphrase: '',
        type: '128'
    })

    const [advanced, setAdvanced] = useState([]);

    const [srtKeys, setSrtKeys] = useState([
        // { label: 'Mode', field: 'mode', type: 'select', children: ['Listen', 'Rendez-vous', 'Push'] },
        {label: 'Mode', field: 'mode', type: 'select', children: ['Listen', 'Push']},
        {label: 'Local IP', field: 'host'},
        {label: 'Port', field: 'port', type: 'number'},
        {label: 'Latency', field: 'latency', type: 'number'},
        {label: 'Adapter IP', field: 'adapterIp', type: 'select', children: []},
        {label: 'Max BandWidth', field: 'maxBandWidth', type: 'number'},
    ]);

    const [feederKeys, setFeederKeys] = useState([
        {label: 'Mode', field: 'mode', type: 'select', children: ['Listen', 'Push']},
        {label: 'Local IP', field: 'host'},
        {label: 'Port', field: 'port', type: 'number'},
        {label: 'Latency', field: 'latency', type: 'number'},
        {label: 'Adapter IP', field: 'adapterIp', type: 'select', children: []},
        {label: 'Max BandWidth', field: 'maxBandWidth', type: 'number'},
    ]);

    const [ristKeys, setRistKeys] = useState([
        // { label: 'Mode', field: 'mode', type: 'select', children: ['Listen', 'Rendez-vous', 'Push'] },
        {label: 'Mode', field: 'mode', type: 'select', children: ['Listen', 'Push']},
        {label: 'BandWidth', field: 'bandWidth', type: 'number'},
        {label: 'Local IP', field: 'host'},
        {label: 'Buffer', field: 'bufferMin', type: 'number'},
        {label: 'Port', field: 'port', type: 'number'},
        {label: 'Reorder Buffer', field: 'reorderBuffer', type: 'number'},
        {
            label: 'Profile',
            field: 'profile',
            type: 'select',
            children: [{label: 'Main', value: '2'}, {label: 'Simple', value: '1'}]
        },
        {label: 'Compression', field: 'compression', type: 'select', children: ['Disabled', 'Enabled']},
        {label: 'RTL', field: 'rtl', type: 'number'},
        {label: 'Stats Interval', field: 'statInterval', type: 'number'},
        {label: 'Adapter Name', field: 'adapterIp', type: 'select', children: []},
    ]);

    const [serverIdInfo, setServerIdInfo] = useState('')

    const [nextPort, setNextPort] = useState('')

    const [allDestinations, setAllDestinations] = useState([]);

    const getDestinations = async (params) => {
        try {
            const dest = await paramGet("/api/contribution/destinations/lists", params);
            setAllDestinations(dest.destinations);
        } catch (error) {
            console.log(error);
        }
    }

    const getContributions = async (params) => {
        try {
            const Allcontributions = await paramGet("/api/contribution", {page: 0, rowsPerPage: 0, searchValue: ''});
            setInputContributions(Allcontributions.contributions);
        } catch (error) {
            console.log(error)
        }
    }

    const getServer = async (Id, conId, outputId) => {
        const serverInfo = await noParamGet(`/api/servers/${Id}`);
        const nextPortAvailable = await noParamPost("/api/contribution/feeder-next-input-port");
        const ips = serverInfo.data.adapterIps.length ? serverInfo.data.adapterIps : [];
        const allDest = await paramGet("/api/contribution/destinations/lists", {
            page: 0,
            rowsPerPage: 0,
            searchValue: ''
        });
        setAllDestinations(allDest.destinations);
        if (conId) {
            const contributionInfo = await noParamGet(`/api/contribution/${conId}`);
            if (serverInfo.data && contributionInfo.data) {
                const {inputType, name, encryption, advanced, config} = contributionInfo.data;

                setBasicSetting({
                    // inputType,
                    inputType: 'SRT',
                    name
                })
                if (inputType === "SRT" || inputType === 'aCANFeeder') {
                    setSrtSettings({
                        // mode: config.mode || 'Listen',
                        mode: config.mode || 'Push',
                        host: config.host || '',
                        port: config.port || '',
                        latency: config.latency,
                        adapterIp: config.adapterIp || '',
                        maxBandWidth: config.maxBandWidth || ''
                    })
                } else {
                    setRistSettings({
                        // mode: config.mode || 'Listen',
                        mode: config.mode || 'Push',
                        bandWidth: config.bandWidth || '',
                        host: config.host || '',
                        bufferMin: config.bufferMin || '',
                        port: config.port || '',
                        reorderBuffer: '',
                        profile: config.profile || 'Main',
                        compression: 'Disabled',
                        rtl: '',
                        statInterval: config.statInterval || '',
                        adapterIp: config.adapterIp || '',
                    })
                }
                setEncryption(encryption);
                setAdvanced(advanced);
            }
        }

        if (outputId) {
            const output = await noParamGet(`/api/contribution/output/${outputId}`);
            if (output.data) {
                const {inputType, title, encryption, advanced, config, name, contributionId} = output.data;
                setBasicSetting({
                    inputType, title, name, contributionId, inputId: contributionId
                })
                if (inputType === "SRT") {
                    let newArr = [...srtKeys];
                    if (config.mode === 'Listen') {
                        newArr[1].children = Array.from(ips, ip => ip.adapterIp) || [];
                        newArr[1].type = 'select';
                        newArr[1].children.unshift('Any');
                        setSrtKeys(newArr);
                        if (config.host === '') {
                            config.host = 'Any';
                        }
                    } else {
                        // delete newArr[1].type;
                        // delete newArr[1].children;
                        newArr[1].children = Array.from(allDest.destinations, desti => desti.title) || [];
                        newArr[1].type = 'select';
                        const initialDestination = allDest.destinations.find(el => el.ip === config.host);
                        if (initialDestination) {
                            config.host = initialDestination.title;
                        }
                        setSrtKeys(newArr);
                    }
                    if (config.adapterIp === '') {
                        config.adapterIp = 'Any';
                    }
                    setSrtKeys(newArr);
                    setSrtSettings(config);
                } else if (inputType === "aCANFeeder") {
                    config.host = config.host === '' ? 'Any' : config.host;
                    config.adapterIp = config.adapterIp === '' ? 'Any' : config.adapterIp;
                    setFeederSettings(config);
                    if (config.mode === 'Listen') {
                        let newArr = [...feederKeys];
                        newArr[1].children = Array.from(ips, ip => ip.adapterIp) || [];
                        newArr[1].children.unshift('Any');
                        newArr[1].type = 'select';
                        setFeederKeys(newArr);
                    }
                } else {
                    setRistSettings(config)
                }
                setEncryption(encryption);
                setAdvanced(advanced);
            }
        } else {
            setFeederSettings({
                ...feederSettings,
                host: '0.0.0.0',
                port: feederSettings.mode === 'Listen' ? nextPortAvailable : ''
            });
            setSrtSettings({
                ...srtSettings,
                host: allDest.destinations[0] ? allDest.destinations[0].title : '0.0.0.0',
                adapterIp: 'Any',
                port: srtSettings.mode === 'Listen' ? nextPortAvailable : ''
            });
            setRistSettings({
                ...ristSettings,
                host: allDest.destinations[0] ? allDest.destinations[0].title : '0.0.0.0',
                port: ristSettings.mode === 'Listen' ? nextPortAvailable : ''
            });
            setNextPort(nextPortAvailable);

            let newArr = [...srtKeys];
            newArr[1].children = Array.from(allDest.destinations, desti => desti.title) || [];
            newArr[1].type = 'select';
            setSrtKeys(newArr);

            let ristArr = [...ristKeys];
            ristArr[2].children = Array.from(allDest.destinations, desti => desti.title) || [];
            ristArr[2].type = "select"
            setRistKeys(ristArr);
        }

        await setServerIPList(ips);

        // Used to set Input Selected id
        if (!outputId) {
            let e = {target: {value: props.match.params.id}};
            changeField(e, 'inputId');
        }

        if (!outputId) {
            if(allDest.destinations.length > 0) {
                setSrtSettings({...srtSettings, host: allDest.destinations[0].title, adapterIp: 'Any'})
            }
        }
    }

    const setServerIPList = async (ips) => {
        let newArr = [...srtKeys];
        newArr[4].children = Array.from(ips, ip => ip.adapterIp) || [];
        newArr[4].children.unshift('Any');
        setSrtKeys(newArr);

        let newFeederArr = [...feederKeys];
        newFeederArr[4].children = Array.from(ips, ip => ip.adapterIp) || [];
        newFeederArr[4].children.unshift('Any');
        // setFeederKeys(newArr);
        setFeederKeys(newFeederArr);

        let ristArr = [...ristKeys];
        ristArr[10].children = Array.from(ips, ip => ip.adapterName) || [];
        setRistKeys(ristArr);
    }

    const setEmptyToAny = () => {
        let feeder = {...feederSettings}, srt = {...srtSettings}, rist = {...ristSettings};
        if (feederSettings.host === '') feeder.host = 'Any';
        if (feederSettings.adapterIp === '') feeder.adapterIp = 'Any';
        if (srtSettings.host === '') srt.host = 'Any';
        if (srtSettings.adapterIp === '') srt.adapterIp = 'Any';
        if (ristSettings.host === '') rist.host = 'Any';
        if (ristSettings.adapterIp === '') rist.adapterIp = 'Any';
        setFeederSettings(feeder);
        setSrtSettings(srt);
        setRistSettings(rist);
    }

    const handleSubmit = async () => {
        updateStateWithInputData();
        if (!serverIdInfo) {
            return ToastsStore.warning('Server is not selected');
        }
        if (advanced.length > 0) {
            const advancedNotInit = advanced.find(ele => ele.key === '' || ele.value === '')
            if (advancedNotInit) {
                return ToastsStore.warning('Advanced params are not init');
            }
        }
        let configs = basicSetting;
        configs.encryption = encryption;
        configs.advanced = advanced;
        if (idGroup.contributionId) {
            configs.contributionId = idGroup.contributionId;
        }
        if (srtSettings.adapterIp === 'Any') {
            srtSettings.adapterIp = '';
        }
        if (feederSettings.adapterIp === 'Any') {
            feederSettings.adapterIp = '';
        }
        if (srtSettings.host === 'Any') {
            srtSettings.host = '';
        }
        if (feederSettings.host === 'Any') {
            feederSettings.host = '';
        }
        if (ristSettings.host === 'Any') {
            ristSettings.host = '';
        }
        switch (basicSetting.inputType) {
            case 'SRT':
                if (srtSettings.mode === 'Push') {
                    const dest = allDestinations.find(el => el.title === srtSettings.host);
                    if (dest) srtSettings.host = dest.ip;
                }
                configs.config = srtSettings;
                break;
            case 'RIST':
                if (ristSettings.mode === 'Push') {
                    const dest = allDestinations.find(el => el.title === ristSettings.host);
                    if (dest) ristSettings.host = dest.ip;
                }
                configs.config = ristSettings;
                break;
            case 'aCANFeeder':
                configs.config = feederSettings;
                break;
            default:
                break;
        }
        props.setLoading(true);
        if (!editId) {
            await paramPost("/api/contribution/output", configs)
                .then(async res => {
                    props.setLoading(false);
                    ToastsStore.success('Output added successfully');
                    props.history.push({pathname: "/dashboard/contribution", state: {displayOutput: true}});
                })
                .catch(err => {
                    props.setLoading(false);
                    setEmptyToAny();
                    ToastsStore.error(err.data.error || 'Failed to create output');
                })
        } else {
            await paramPut(`/api/contribution/output/${editId}`, configs)
                .then(async res => {
                    props.setLoading(false);
                    ToastsStore.success('Output updated successfully');
                    props.history.push({pathname: "/dashboard/contribution", state: {displayOutput: true}});
                })
                .catch(err => {
                    props.setLoading(false);
                    setEmptyToAny();
                    ToastsStore.error(err.data.error || 'Failed to update contribution');
                })
        }
    }

    const changeField = (e, field) => {
        let val = basicSetting;
        if (field === "streamIndex") {
            val['name'] = `encoder${e.target.value}`
        }
        val[field] = e.target.value;
        setBasicSetting({...basicSetting});
        setEmptyToAny();
        if (field === 'inputId') {
            updateStateWithInputData();
        }
    }

    const updateStateWithInputData = () => {
        if (inputContributions.length > 0) {
            const contribution = inputContributions.find(elm => elm.id === basicSetting.inputId);
            setServerIdInfo(contribution.serverId);
            const outputId = idGroup.outputId;
            setIdGroup({
                serverId: contribution.serverId,
                contributionId: contribution.id,
                outputId
            })
        }
    }

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue)
    }

    const handleConfigChange = (type, values) => {
        if (type === 1) {
            if (!editId) {
                if (srtSettings.mode === 'Listen') {
                    // values.port = nextPort;
                } else {
                    // values.port = '';
                }
            }
            setSrtSettings(values)
        } else if (type === 2) {
            if (ristSettings.mode === 'Listen') {
                // values.port = nextPort;
            } else {
                // values.port = '';
            }
            setRistSettings(values)
        } else if (type === 3) {
            if (feederSettings.mode === 'Listen') {
                // values.port = nextPort;
            } else {
                // values.port = '';
            }
            setFeederSettings(values)
        } else if (type === 4) {
            setAdvanced(values)
        } else {
            setEncryption(values)
        }
    }

    const handleModeChange = async (type, val) => {
        if (type === 1) {
            let newSrtSettingArr = {...srtSettings};
            let newArr = [...srtKeys];
            newArr[1].label = val !== "Listen" ? "Destination Host" : "Local IP";
            if (val === 'Listen') {
                // newArr[1].children = srtKeys[4].children;
                newArr[1].children = ['Any'];
                newArr[1].type = 'select';
                const nextPortAvailable = await noParamPost("/api/contribution/feeder-next-input-port");
                setNextPort(nextPortAvailable);
                setSrtSettings({...srtSettings, host: 'Any', port: nextPortAvailable});
            } else {
                if (!editId) {
                    newArr[1].children = Array.from(allDestinations, desti => desti.title) || [];
                    newArr[1].type = 'text';
                    newSrtSettingArr.host = '0.0.0.0';
                    setSrtSettings(newSrtSettingArr);
                } else {
                    newArr[1].children = Array.from(allDestinations, desti => desti.title) || [];
                    newArr[1].type = 'select';
                }
            }
            setSrtKeys(newArr);
        }
        if (type === 2) {
            let newArr = [...ristKeys];
            newArr[2].label = val !== "Listen" ? "Destination Host" : "Local IP";
            if (val === 'Listen') {
                newArr[2].children = srtKeys[4].children;
                newArr[2].type = 'select';
                setRistSettings({...ristSettings, host: 'Any', port: nextPort});
            } else {
                // delete newArr[2].type;
                // delete newArr[2].children;
                newArr[2].children = Array.from(allDestinations, desti => desti.title) || [];
                newArr[2].type = 'select';
                setRistSettings({...ristSettings, host: allDestinations[0].title || '0.0.0.0', port: ''});
            }
            setRistKeys(newArr);
        }
        if (type === 3) {
            let newArr = [...feederKeys];
            newArr[1].label = val !== "Listen" ? "Destination Host" : "Local IP";
            if (val === 'Listen') {
                // newArr[1].children = srtKeys[4].children;
                newArr[1].children = ['Any'];
                newArr[1].type = 'select';
                const nextPortAvailable = await noParamPost("/api/contribution/feeder-next-input-port");
                setNextPort(nextPortAvailable);
                setFeederSettings({...feederSettings, host: 'Any', port: nextPortAvailable});
            } else {
                delete newArr[1].type;
                delete newArr[1].children;
                setFeederSettings({...feederSettings, host: '0.0.0.0', port: ''});
            }
            setFeederKeys(newArr);
        }

    }

    const handleModePushDestinationTypeChange = (value) => {
        if (value === 'manual') {
            let newArr = [...srtKeys];
            newArr[1].children = Array.from(allDestinations, desti => desti.title) || [];
            newArr[1].type = 'text';
            setSrtKeys(newArr);
            let newSettingArr = {...srtSettings};
            newSettingArr.host = '0.0.0.0';
            setSrtSettings(newSettingArr);

            let newRistArr = [...ristKeys];
            newRistArr[2].children = Array.from(allDestinations, desti => desti.title) || [];
            newRistArr[2].type = 'text';
            let newRistSettingArr = {...ristSettings};
            newRistSettingArr.host = '0.0.0.0';
            setRistSettings(newRistSettingArr);
        } else {
            let newArr = [...srtKeys];
            let newSettingArr = {...srtSettings};
            newArr[1].children = Array.from(allDestinations, desti => desti.title) || [];
            newArr[1].type = 'select';
            newSettingArr.host = allDestinations.length > 0 ? allDestinations[0].title : '';
            setSrtKeys(newArr);
            setSrtSettings(newSettingArr);

            let newRistArr = [...ristKeys];
            newRistArr[2].children = Array.from(allDestinations, desti => desti.title) || [];
            newRistArr[2].type = 'select';
            let newRistSettingArr = {...ristSettings};
            newRistSettingArr.host = allDestinations.length > 0 ? allDestinations[0].title : '';
            setRistSettings(newRistSettingArr);
        }
    }

    const tabType = () => {
        switch (basicSetting.inputType) {
            case 'SRT':
                return <SRT
                    values={srtSettings}
                    keys={srtKeys}
                    onConfigChange={(val) => handleConfigChange(1, val)}
                    onModeChange={(val) => handleModeChange(1, val)}
                    onDestinationTypeChange={handleModePushDestinationTypeChange}
                />
            case 'RIST':
                return <SRT
                    values={ristSettings}
                    keys={ristKeys}
                    onConfigChange={(val) => handleConfigChange(2, val)}
                    onModeChange={(val) => handleModeChange(2, val)}
                    onDestinationTypeChange={handleModePushDestinationTypeChange}
                />
            case 'aCANFeeder':
                return <FEEDER
                    values={feederSettings}
                    keys={feederKeys}
                    onConfigChange={(val) => handleConfigChange(3, val)}
                    onModeChange={(val) => handleModeChange(3, val)}
                />
            default:
                break;
        }
    }

    const tabs = [
        {
            label: `Output Settings`,
            chidren: tabType()
        },
        {
            label: "Encryption",
            chidren: <Encryption values={encryption} onConfigChange={(val) => handleConfigChange(5, val)}/>
        },
        {
            label: "Advanced parameters",
            chidren: <AdvancedParams basicSetting={basicSetting} values={advanced}
                                     onConfigChange={(val) => handleConfigChange(4, val)}/>
        }
    ];

    return (
        <Grid container className='config'>
            <Grid item xs={12} className="header">
                {basicSetting.name && ` | ${basicSetting.name}`}
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
                <Tooltip title="Back">
                    <IconButton
                        onClick={() => history.push({
                            pathname: "/dashboard/contribution",
                            state: {displayOutput: true}
                        })}>
                        <ArrowBack/>
                    </IconButton>
                </Tooltip>
                <Button className="add-button" variant="contained" onClick={() => handleSubmit()}>
                    {!editId ? 'Save' : 'Update'} Output Config
                </Button>
            </Grid>
            <Grid item xs={12} className="mt-3"/>
            <Grid container component={Paper} p={2} className="format-encoder">
                <Grid item md={4} xs={12} p={1}>
                    <TextField
                        fullWidth
                        label="Title"
                        value={basicSetting['title'] || ''}
                        onChange={(e) => changeField(e, 'title')}
                    />
                </Grid>
                <Grid item md={4} xs={12} p={1}>
                    <TextField
                        select fullWidth
                        label="Output Type"
                        value={basicSetting['inputType'] || ''}
                        onChange={(e) => changeField(e, 'inputType')}
                    >
                        {
                            inputTypes.map(vf =>
                                <MenuItem key={vf} value={vf}>{vf}</MenuItem>
                            )
                        }
                    </TextField>
                </Grid>
                <Grid item md={4} xs={12} p={1}>
                    <TextField
                        select fullWidth
                        label="Input"
                        value={basicSetting['inputId'] || ''}
                        onChange={(e) => changeField(e, 'inputId')}
                    >
                        {
                            inputContributions.map(vf =>
                                <MenuItem key={vf.id} value={vf.id}>{vf.title}</MenuItem>
                            )
                        }
                    </TextField>
                </Grid>
            </Grid>
            <Grid item xs={12} className="mt-3"/>
            <Grid container className="format-encoder">
                <AppBar position="static" color='default'>
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        value={tabIndex}
                        onChange={handleTabChange} aria-label="simple tabs example">
                        {tabs.map((element, index) => (
                            <Tab
                                key={index}
                                className='text-transform-none'
                                // icon={element.icon}
                                label={element.label}/>
                        ))}
                    </Tabs>
                </AppBar>
                {tabs[tabIndex].chidren}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {setLoading};

export default connect(mapStateToProps, mapDispatchToProps)(OutputConfig);
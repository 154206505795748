import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom';
import { setLoading, jwtLogin, logout } from '../../action'
import { EncoderDialog } from '../../components';
import { UserManager, EditEncoder, EncoderList, ManagerEncoder, Channels } from '../../pages';
import Group from '../../pages/Group';
import Servers from '../../pages/Servers';
import Contribution from '../../pages/Contribution';
import ContributionConfig from '../../pages/Contribution/settingConfig';
import OutputConfig from '../../pages/Contribution/outputConfig';
import Box from '@mui/material/Box';
import ThemeColors from '../../config/ThemeColors';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import LogoutIcon from '@mui/icons-material/Logout';
import config from '../../config';

const dashboardUrl = '/dashboard';

export function Dashboard(props) {
  const [loading, setLoading] = useState(true);
  const [router, setRouter] = useState('');
  const [value, setValue] = useState('1');
  const [pageTitle, setPageTitle] = useState('');
  const [userRole, setUserRole] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case '1':
        props.history.push('/');
        break;
      case '2':
        props.history.push(`${dashboardUrl}/server`);
        break;
      case '3':
        props.history.push(`${dashboardUrl}/group`);
        break;
      case '4':
        props.history.push(`${dashboardUrl}/user_manager`);
        break;
      case '5':
        props.history.push(`${dashboardUrl}/manager_encoder`);
        break;
      case '6':
        props.history.push(`${dashboardUrl}/channel`);
        break;
      case '7':
        props.history.push(`${dashboardUrl}/contribution`);
        break;
      default:
        break;
    }
  };
  

  const checkAuth = useCallback(async () => {
    const { authUser, history, location } = props;
    if (location.pathname !== router) {
      setRouter(location.pathname);
      await props.jwtLogin();
    }
    if (authUser.loading === true && !authUser.user) {
      setLoading(true);
    } else if (authUser.user) {
      setLoading(false);
    } else {
      history.push('/login');
    }
  }, [props, router]);

  const getPageTitle = useCallback(() => {
    switch (props.location.pathname) {
      case '/':
        setPageTitle('Welcome to aCAS TS');
        break;
      case `${dashboardUrl}/server`:
        setPageTitle('Servers');
        break;
      case `${dashboardUrl}/user_manager`:
        setPageTitle('Access');
        break;
      case `${dashboardUrl}/manager_encoder`:
        setPageTitle('Encoders');
        break;
      case `${dashboardUrl}/group`:
        setPageTitle('Groups');
        break;
      case `${dashboardUrl}/channel`:
        setPageTitle('Channels');
        break;
      case `${dashboardUrl}/contribution`:
        setPageTitle('Contributions');
        break;
      default:
        break;
    }
  }, [props.location.pathname]);

  useEffect(() => {
    getPageTitle();
  }, [getPageTitle]);

  useEffect(() => {
    checkAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pathName = props.location.pathname;
    if (pathName.startsWith(`${dashboardUrl}/edit_encoder/`)) {
      setValue('5');
      setPageTitle('Configure Encoder');
      return;
    }
    if (pathName.includes('/contribution/new')) {
      setValue('7');
      setPageTitle('Contribution Config');
      return;
    }
    if (pathName.includes('/output_edit/')) {
      setValue('7');
      setPageTitle('Output Configuration');
      return;
    }
    if (pathName.includes('/output/')) {
      setValue('7');
      setPageTitle('Output Configuration');
      return;
    }
    switch (props.location.pathname) {
      case '/':
        setValue('1');
        break;
      case `${dashboardUrl}/server`:
        setValue('2');
        break;
      case `${dashboardUrl}/group`:
        setValue('3');
        break;
      case `${dashboardUrl}/user_manager`:
        setValue('4');
        break;
      case `${dashboardUrl}/manager_encoder`:
        setValue('5');
        break;
      case `${dashboardUrl}/channel`:
        setValue('6');
        break;
      case `${dashboardUrl}/contribution`:
        setValue('7');
        break;
      default:
        break;
    }
  }, [props.location.pathname]);

  useEffect(() => {
    const userRole = props.authUser.user ? props.authUser.user.mode.name : null;
    setUserRole(userRole);
  }, [props.authUser.user]);

  return (
    !loading ?
      <Box sx={{ bgcolor: '#F7F7F7', minHeight: '100vh' }}>
        <Box height={256} sx={{ bgcolor: ThemeColors.primary.light, px: { xs: 3, sm: 3, md: 6 }, py: 3}} >
          <Box sx={{display: { sm: 'block', md: 'flex'}, justifyContent: 'space-between', height: '35px', alignItems: 'center', textAlign: { xs: 'center', sm: 'center', md: 'unset'}}}>
            {/* <Typography variant='h6' color="white">
              {pageTitle}
            </Typography> */}
            <Box sx={{ borderBottom: 1, borderColor: 'white' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="Header tab"
                sx={{
                  "& button.Mui-selected": {
                    color: "#A9D2F6"
                  },
                  "& button": {
                    color: '#E0E0E0',
                  },
                }}
                TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
              >
                <Tab label="Dashboard" value="1" />
                {["superadmin", "admin"].includes(userRole) && (
                    <Tab label="Servers" value="2" />
                )}
                {["superadmin", "admin"].includes(userRole) && (
                    <Tab label="Groups" value="3" />
                )}
                {["superadmin", "admin", "manager", "editor", "monitor"].includes(userRole) && (
                  <Tab label="Access" value="4" />
                )}
                <Tab label="Encoders" value="5" />
                <Tab label="Channels" value="6" />
                {["superadmin", "admin", "manager"].includes(userRole) && (
                  <Tab label="Contributions" value="7" />
                )}
              </Tabs>
            </Box>
            <Box sx={{alignItems: 'start', display: 'flex', justifyContent: { xs: 'center'}, mt: {xs: 2} }}>
              <Typography variant='subtitle2' color="white">{props.authUser && props.authUser.user ? props.authUser.user.fullName || "" : ""}</Typography>
              <IconButton sx={{py: 0}} color='white' onClick={() => props.logout() }>
                <LogoutIcon sx={{ color: 'white' }} />
            </IconButton>
            </Box>
          </Box>
        </Box>
        
        <Box sx={{ mx:  { xs: 3, sm: 3, md: 6 } , mt: { md: '-200px', xs: '-180px' }, bgcolor: 'white', minHeight: 'calc(100vh - 115px)' }}>
          <Box sx={{ mx: 3, pt: 2 }}></Box>
          <Route path={`/`} exact component={EncoderList} />
          <Route path={`${dashboardUrl}/user_manager`} component={UserManager} />
          <Route path={`${dashboardUrl}/edit_encoder/:id`} component={EditEncoder} />
          <Route path={`${dashboardUrl}/manager_encoder`} component={ManagerEncoder} />
          <Route path={`${dashboardUrl}/group`} component={Group} />
          <Route path={`${dashboardUrl}/server`} component={Servers} />
          <Route path={`${dashboardUrl}/channel`} component={Channels} />
          <Route path={`${dashboardUrl}/contribution`} exact component={Contribution} />
          <Route path={`${dashboardUrl}/:serverId/:groupId/contribution/:id`} component={ContributionConfig} />
          <Route path={`${dashboardUrl}/:serverId/output/:id`} component={OutputConfig} />
          <Route path={`${dashboardUrl}/:serverId/output_edit/:outputId`} component={OutputConfig} />
        </Box>
        <EncoderDialog />
      </Box>
      : ''
  )
}

const mapStateToProps = ({ authUser }) => ({
  authUser
});

export default connect(mapStateToProps, {
  setLoading, jwtLogin, logout
})(Dashboard);
import React, {useState, useEffect} from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import CheckBox from '@mui/material/Checkbox';
import StatusChip from "../Common/statusLabel";


const ChannelStreamsDialog = ({serverList, isOpen, onClose}) => {

  const [selectedIds, setSelectedIds] = useState([]);

  const serverRows = () => {
    if (serverList && serverList.length > 0) {
      return (
        serverList.map((server, index) => (
          <TableRow key={index}>
              <TableCell width="50px" onClick={() => handleSelectCell(server.name)}>
                <CheckBox checked={server.name && selectedIds.includes(server.name)} />
                </TableCell>
              <TableCell>{server.name || ""}</TableCell>
              <TableCell>{server.title || ""}</TableCell>
              <TableCell>{server.template || ""}</TableCell>
              <TableCell>{!server.disabled ? 
              <StatusChip label={"Active"} type="success" /> : 
              <StatusChip label={"Disabled"} type="error" />}</TableCell>
          </TableRow>
      ))
    )
    }
  }

  useEffect(() => {
    if (serverList && serverList.length > 0) {
      const names = serverList.map((server) => server.name);
      setSelectedIds(names);
    }
  }, [serverList]);

  const handleSelectAll = () => {
    if (selectedIds.length > 0) {
      setSelectedIds([]);
    } else {
      const names = serverList.map((server) => server.name);
      setSelectedIds(names);
    }
  }

  const handleSelectCell = (serverName) => {
    if (!serverName) {
      return;
    }
    if (selectedIds.includes(serverName)) {
      setSelectedIds(selectedIds.filter((name) => name !== serverName));
    } else {
      setSelectedIds([...selectedIds, serverName]);
    }
  }

  const handleSave = (isSave) => {
    onClose(isSave ? selectedIds : null);
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen} 
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}>
      <DialogTitle mt={3}>
        Select Stream Channels
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ bgcolor: '#ECEFF9' }}>
              <TableRow>
                <TableCell width="50px" onClick={handleSelectAll}>
                  <CheckBox checked={selectedIds.length > 0} />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Template</TableCell>
                <TableCell>Disable</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serverRows()}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Box sx={{ p: 2, textAlign: 'right' }}>
          <Button variant="contained" onClick={() => handleSave(true)} disabled={selectedIds.length === 0}>Save</Button>
        </Box>
        <Box sx={{ py: 2, pr: 2, textAlign: 'right' }}>
          <Button variant="contained" onClick={() => handleSave(false)} color='secondary'>Close</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default ChannelStreamsDialog;

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import EnhancedTableHead from '../../components/Contribution/EnhancedTableHead';
import EnhancedTableToolbar from '../../components/Contribution/ToolBar';
import {
    Box,
    Grid,
    Icon,
    MenuItem, Select,
    Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {noParamDelete, paramGet, paramPost} from '../../apis/CommonApi';
import {ToastsStore} from 'react-toasts'
import {setLoading, showAlert} from '../../action'
import Pagination from "@mui/material/Pagination";
import {Done} from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import DestinationDialog from "../../components/Contribution/DestinationModal";
import ActionButton from '../../components/Common/action-button';

const mainHeader = ['Title', 'Host', 'Actions']

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    gridContainer: {
        padding: '2rem'
    },
    newButton: {
        float: 'right',
        marginTop: '5px',
        marginRight: '15px',
    },
    title: {
        textAlign: 'center',
        fontSize: '1.2rem',
        color: '#5a97fa',
        fontWeight: '600'
    },
    searchBar: {
        margin: '2rem 0 1rem'
    },
    searchForm: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        marginTop: '20px',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    outputFilter: {
        marginLeft: '10px',
        background: '#5a97fa',
        color: '#fff',
        height: '22px',
        marginTop: '5px',
    },
    miniNavbar: {
        marginTop: '20px',
        marginBottom: '20px',
        background: '#5a97fa',
        display: 'flex',
        height: '40px',
    },
    miniNavButton: {
        marginRight: '10px',
        height: '39px',
        boxShadow: 'none',
    },
    miniNavButtonActive: {
        marginRight: '10px',
        height: '39px',
        boxShadow: 'none',
        background: '#1136bd',
        '&:hover': {
            background: "#1136bd",
        },
    },
    mb18: {
        marginBottom: '18px',
    },

    wrapperInlineInput: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center"
    }
}));

export const Destination = (props) => {
    let {
        openModal,
        onClose,
        addingModal,
        groups,
        servers,
        getContributions,
        onOpenDestinationModal
    } = props;

    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem('rows-per-page') ? localStorage.getItem('rows-per-page') : 12);
    const [searchValue, setSearchValue] = useState("");
    const [lists, setLists] = useState({
        contributions: [],
        total: 0
    });
    const [inlineEditInput, setInlineEditInput] = useState('');
    const [listDestinations, setListDestinations] = useState({
        destinations: [],
        total: 0
    });
    const [destination, setDestination] = useState({}); // Destination setter pour edit

    useEffect(() => {
        // const localDestinationStorage = JSON.parse(localStorage.getItem('list-destinations'));
        // if (localDestinationStorage && localDestinationStorage.destinations && localDestinationStorage.destinations.length > 0) {
        //     setListDestinations(localDestinationStorage);
        // } else {
        //     getDestinations({page, rowsPerPage, searchValue});
        // }
        getDestinations({page, rowsPerPage, searchValue});
    }, []);

    const inlineEditInputOnChange = (e) => {
        setInlineEditInput(e.target.value);
    }

    const initInlineEdit = () => {
        const allInputs = document.querySelectorAll('.inline-edit-input');
        const allTitles = document.querySelectorAll('.span-input-title');
        const allEdtiBtnAction = document.querySelectorAll('.inline-edit-input-action-btn');
        for (let i = 0; i < allInputs.length; i++) {
            allInputs[i].style.display = 'none';
            allTitles[i].style.display = 'block';
            allEdtiBtnAction[i].style.display = 'none';
        }
    }

    const inlineEditInputOnDoubleClick = (row) => {
        setInlineEditInput(row.title);
        initInlineEdit();
        const input = document.getElementById(row.id);
        const spanTitle = document.getElementById('span-input-title' + row.id);
        const inlineEditActionBtn = document.getElementById('inline-edit-input-action-btn-' + row.id);
        input.style.display = 'block';
        spanTitle.style.display = 'none';
        inlineEditActionBtn.style.display = 'flex';
    }

    const inlineEditInputOnCancel = (row) => {
        setInlineEditInput('');
        initInlineEdit();
    }

    const onSubmitInlineEditTypeInput = async (row) => {
        props.setLoading(true);
        const postData = {inputId: row.id, title: inlineEditInput, type: 'destination'};
        const rs = await paramPost('/api/contribution/update-title', postData);
        if (rs.data.status) {
            ToastsStore.success(rs.data.result);
            let inputs = listDestinations.destinations;
            for (const [index, item] of inputs.entries()) {
                if (inputs[index].id === row.id) {
                    inputs[index].title = postData.title;
                }
            }
            setListDestinations({...listDestinations, destinations: inputs});
        } else {
            ToastsStore.error("Something went wrong. Please try again.");
        }
        initInlineEdit();
        props.setLoading(false);
    }

    const getDestinations = async (params) => {
        try {
            props.setLoading(true);
            const destinations = await paramGet("/api/contribution/destinations/lists", params);
            setListDestinations({destinations: destinations.destinations, total: destinations.length});
            localStorage.setItem('list-destinations', JSON.stringify(destinations));
            props.setLoading(false);
        } catch (error) {
            props.setLoading(false);
        }
    }

    const onRowPerPageChange = async (e, type) => {
        setRowsPerPage(e.target.value);
        const newRowPerPage = e.target.value;
        localStorage.setItem('rows-per-page', newRowPerPage);
        await getDestinations({page, rowsPerPage: newRowPerPage, searchValue});
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = listDestinations.destinations.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
        await getDestinations({page: newPage, rowsPerPage, searchValue});
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    const handleRemoveDestination = async (id) => {
        return await noParamDelete(`/api/contribution/destination/${id}`)
            .then(async res => {
                if (res.data.status) {
                    ToastsStore.success(res.data.msg);
                    return true;
                }
                ToastsStore.error(res.data.msg);
                return false;
            })
            .catch(err => {
                ToastsStore.error('Something went wrong!');
                return false;
            });
    }

    const removeDestination = async (destinationId) => {
        props.showAlert('Are you sure?', 'Are you sure you want to delete?', async () => {
            const rs = await handleRemoveDestination(destinationId);
            if (rs) {
                await getDestinations({page, rowsPerPage, searchValue});
            }
        });
    }

    const editDestination = async (row) => {
        onOpenDestinationModal('edit');
        setDestination(row);
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleUpdateStateAfterBulkDelete = (selected) => {
        let destinations = listDestinations.destinations;
        for (const element of selected) {
            for (const [index, item] of destinations.entries()) {
                if (item && item.id === element) destinations.splice(index, 1);
            }
        }
        setListDestinations({...destinations, destinations, total: destinations.total - selected.length});
        setSelected([]);
    }

    return (
        <Grid item xs={12}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={selected.length} selected={selected}
                                      isDestination={true}
                                      servers={servers}
                                      destinations={listDestinations.destinations}
                                      getContributions={getContributions}
                                      contributions={lists.contributions}
                                      bulkDeleteRefresh={handleUpdateStateAfterBulkDelete}/>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={listDestinations.destinations ? listDestinations.destinations.length : 0}
                            headerList={mainHeader}
                            isSubHeader={false}
                        />
                        <TableBody>
                            {listDestinations.destinations && listDestinations.destinations.length > 0 ? listDestinations.destinations.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <React.Fragment key={row.id}>
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox"></TableCell>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    onClick={(event) => handleClick(event, row.id)}
                                                    inputProps={{'aria-labelledby': labelId}}
                                                />
                                            </TableCell>
                                            <TableCell align="left"
                                                       onDoubleClick={(e) => inlineEditInputOnDoubleClick(row)}>
                                                            <span id={'span-input-title' + row.id}
                                                                  className='span-input-title'>
                                                                {row.title}
                                                            </span>
                                                <div className={classes.wrapperInlineInput}>
                                                    <div style={{width: '60%'}}>
                                                        <input
                                                            type="text"
                                                            className='inline-edit-input'
                                                            value={inlineEditInput}
                                                            id={row.id}
                                                            style={{
                                                                display: 'none',
                                                                width: '90%',
                                                                height: '30px'
                                                            }}
                                                            onChange={(e) => inlineEditInputOnChange(e)}
                                                        />
                                                    </div>
                                                    <div style={{
                                                        display: "none",
                                                        justifyContent: "space-between",
                                                    }}
                                                         id={'inline-edit-input-action-btn-' + row.id}
                                                         className='inline-edit-input-action-btn'>
                                                        <IconButton color='secondary'
                                                                    onClick={(e) => onSubmitInlineEditTypeInput(row)}>
                                                            <Done/>
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={(e) => inlineEditInputOnCancel(row)}>
                                                            <CancelIcon/>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="left">{row.ip}</TableCell>
                                            <TableCell align="left" width="15%">
                                                <ActionButton handleClick={() => editDestination(row)} type='edit' label='Edit Destination' />
                                                <ActionButton handleClick={() => removeDestination(row.id)} type='delete' label='Delete Destination' />
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            }) : (
                                <TableRow style={{height: 53}}>
                                    <TableCell colSpan={8}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box my={2} display="flex" justifyContent="right" alignItems='center'>
                    <Box>Rows per page: &nbsp;&nbsp;
                        <Select
                            labelId="input-rows-per-page"
                            value={rowsPerPage}
                            label="Rows per page"
                            onChange={(e) => onRowPerPageChange(e, 1)}
                        >
                            <MenuItem value={12}>12</MenuItem>
                            <MenuItem value={24}>24</MenuItem>
                            <MenuItem value={36}>36</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={75}>75</MenuItem>
                            <MenuItem value={0}>All</MenuItem>
                        </Select>
                    </Box>
                    &nbsp;&nbsp;
                    <Pagination
                        color="primary"
                        count={rowsPerPage > 0 ? Math.trunc(lists.total / rowsPerPage) : Math.trunc(lists.total / lists.total)}
                        page={page > 0 ? page : 1}
                        defaultPage={1}
                        onChange={handleChangePage}
                    />
                </Box>
            </Paper>
            <DestinationDialog open={openModal} onClose={onClose} addingModal={addingModal}
                               groups={groups} getDestinations={getDestinations} setLoading={props.setLoading}
                               params={{page, rowsPerPage, searchValue}} destination={destination}/>
        </Grid>
    );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {showAlert, setLoading}

export default connect(mapStateToProps, mapDispatchToProps)(Destination)

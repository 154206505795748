import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';

/**
 * View tail
 * @augments {Component<Props, State>}
 */
class TailDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    tail: PropTypes.string
  }

  _isMounted = false;

  state = {
    open: false,
    tail: ''
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    if (!this._isMounted) return;
    const { open, tail } = props;
    this.setState({ open, tail });
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   const { open, tail } = nextProps;
  //   if (prevState.open !== open && open) {
  //     return { open, tail }
  //   }
  //   return null;
  // }

  render() {
    const { open, tail } = this.state;
    const tails = tail ? tail.split('\n') : [];
    return (
      <Dialog open={open}>
        <DialogTitle>Encoder logs</DialogTitle>
        <DialogContent>
          {tails.map(tail => <div>{tail}</div>)}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onClose && this.props.onClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default TailDialog

import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from "@mui/material/DialogContent"
import { DialogTitle, DialogActions } from '@mui/material'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import {FormHelperText} from "@mui/material"
import { loginUser } from '../../utils'
import {setLoading} from "../../action";
import {connect} from "react-redux";

export const ServerSelectingDialog = (props) => {
    const { onClose, open, servers, groups } = props

    const Schema = Yup.object().shape({
        server: Yup.string()
            .test("validation", "Select a server", (value) => value !== 1)
            .required('This field is required'),
        group: Yup.string()
            .test("validation", "Select a group", (value) => value !== 1)
            .required('This field is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            server: "1",
            group: loginUser() === "manager" && groups.length ? groups[0].id : "1"
        },
        validationSchema: Schema,
        onSubmit: (values, {resetForm}) => {
            handleClose(values)
            // resetForm()
        },
    });

    const handleClose = (val = false) => {
        onClose(val)
    };

    const selectOptions = (items) => {
        return items.map((item, index) => {
          return (
              <MenuItem value={item.id} key={index}>{item.title}</MenuItem>
          )
        })
    }

    const {errors, touched, handleSubmit, getFieldProps, values} = formik;
    
    return (
        <Dialog
            maxWidth="md" 
            disableEscapeKeyDown={true} 
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleClose()
                }
            }} 
            open={open}>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <DialogTitle>Fetch | Select a server</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    className='width-100'
                                    error={Boolean(touched.server && errors.server)}
                                 >
                                    <InputLabel id="label">
                                        Select Server
                                    </InputLabel>
                                    <Select
                                        labelId="label"
                                        label="Select Server"
                                        {...getFieldProps('server')}
                                    >
                                        <MenuItem value={1} disabled={true}>
                                            === Select ===
                                        </MenuItem>
                                        {servers.length && selectOptions(servers)}
                                    </Select>
                                    <FormHelperText>{touched.server && errors.server}</FormHelperText>
                                </FormControl>
                            </Grid>
                            {groups.length > 0 && (
                                <Grid item xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        className='width-100'
                                        error={Boolean(touched.group && errors.group)}
                                    >
                                        <InputLabel id="label">
                                            Select Group
                                        </InputLabel>
                                        <Select
                                            labelId="label"
                                            label="Select group"
                                            {...getFieldProps('group')}
                                        >
                                            <MenuItem value={1} disabled={true}>
                                                === Select ===
                                            </MenuItem>
                                            {groups.length && selectOptions(groups)}
                                        </Select>
                                        <FormHelperText>{touched.group && errors.group}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions className='mx-1'>
                        <Button type='submit' disabled={values.server === "1" || (groups.length > 0 && values.group === "1")} variant="contained" color="primary">
                            ok
                        </Button>
                        <Button type='reset' variant="contained" color="secondary" className='ml-1' onClick={() => {formik.resetForm(); handleClose()}}>
                            close
                        </Button>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}

ServerSelectingDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    servers: PropTypes.array.isRequired,
    groups: PropTypes.array.isRequired,
}
const mapStateToProps = (state) => ({})

const mapDispatchToProps = {setLoading}

export default connect(mapStateToProps, mapDispatchToProps)(ServerSelectingDialog)

// export default (ServerSelectingDialog)

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { setLoading, editEncoder } from '../../action'
import { EncoderCard } from '../../components';
import { TablePagination, Box } from '@mui/material';
import SearchBar from '../../components/Common/searchbar';
import { noParamGet, paramPost } from '../../apis/CommonApi';
import './style.scss'
class EncoderList extends Component {
  _isMounted = false;

  state = {
    encoders: [],
    groups: [],
    rowsPerPage: 12,
    page: 0,
    searchValue: '',
    total: 0,
    groupId: '',
    currentRequestList: [],
  }


  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState()
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    if (!this._isMounted) return;
    this._isMounted = false;
    this.props.setLoading(true);
    const groups = await noParamGet("/api/group");
    this.setState({ groups: groups.data || [] });
    // const { rowsPerPage, page, searchValue, groupId } = this.state;
    // await this.getEncoderLists({ rowsPerPage, page, searchValue, groupId });
    this.props.setLoading(false);
  }

  getEncoderLists = async (params) => {
    try {
      this.setState({ encoders: [], total: 0 });
      const response = await paramPost("/api/encoder/all", params);
      // Create 36 length array for pagination
      // for (let i = 0; i < 36; i++) {
      //   const encoder = response.data.result[0];
      //   encoder._id = i + encoder._id;
      //   response.data.result.push(encoder);
      // }
      if (response.data)
        this.setState({
          encoders: response.data.result || [],
          total: response.data.total
        });
    } catch (error) {
      console.log("Error", error);
    }
  }

  onUpdate = async () => {
    this._isMounted = true;
    await this.propsToState();
  }

  onAddRequest = (source) => {
    this.setState({ currentRequestList: [...this.state.currentRequestList, source] });
  }

  cancelRequest = () => {
    const sources = this.state.currentRequestList;
    this.setState({ currentRequestList: [] });
    if (sources.length > 0) {
      sources.forEach(source => source.cancel("axios request cancelled"));
    }
  }

  handleChangePage = async (event, newPage) => {
    this.setState({ page: newPage });
    const { rowsPerPage, searchValue, groupId } = this.state;
    this.cancelRequest();
    await this.getEncoderLists({ rowsPerPage, page: newPage, searchValue, groupId });
  };

  handleRowsPerPageChange = async (event) => {
    this.props.setLoading(true);
    const newRowsPerPage = event.target.value;
    this.setState({ rowsPerPage: newRowsPerPage });
    const { searchValue, groupId } = this.state;
    this.cancelRequest();
    await this.getEncoderLists({ rowsPerPage: newRowsPerPage, page: 0, searchValue, groupId });
    this.props.setLoading(false);
  };

  handleSearch = async (value) => {
    this.cancelRequest();
    this.setState({ searchValue: value.searchText, groupId: value.groupId });
    const { rowsPerPage, page } = this.state;
    // await this.getEncoderLists({ rowsPerPage, page, searchValue: value });
    if (value.searchText && value.searchText.length > 0) {
      await this.getEncoderLists({ rowsPerPage: 0, page: 0, searchValue: value.searchText, groupId: value.groupId });
    } else {
      await this.getEncoderLists({ rowsPerPage, page, searchValue: value.searchText, groupId: value.groupId });
    }
    this.props.history.push("/");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { encoders, rowsPerPage, page, total } = this.state;
    return (
      <>
        <SearchBar
          onChange={this.handleSearch}
          pageType='dashboard'
          hasAddNew
          hasGroupSearch
          hasForceRefresh
          // hasTagSearch
          groups={this.state.groups || []}
          newButtonTitle="+ Add"
          onRefresh={() => this.componentDidMount()}
          onCreateNew={() => this.props.editEncoder()}
        />
        <Box
          gap={1}
          display="grid"
          mt={3}
          mx={1}
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
            xl: 'repeat(6, 1fr)',
          }}
        >
          {
            encoders.length ? encoders.map(encoder => (
              <EncoderCard encoder={encoder} key={`${encoder._id}-${!!encoder.config}`} groupId={this.state.groupId} onDelete={this.onUpdate} onAddRequest={this.onAddRequest} />
            )) : ""
          }
        </Box>
        <TablePagination
          align='right'
          rowsPerPageOptions={[12, 28, 56, 84, 112]}
          component="div"
          count={total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={(e) => this.handleRowsPerPageChange(e)}
        />
      </>
    )
  }

}

EncoderList.propTypes = {

}
const mapStateToProps = ({ encoderReducer }) => ({
  encoderReducer
});
export default withRouter(connect(mapStateToProps, {
  setLoading, editEncoder
})(EncoderList));
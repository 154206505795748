import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from 'react-redux';
import SearchBar from "../../components/Common/searchbar";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Paper, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ChannelName from "../../components/Channel/channel-name";
import ChannelInOut from "../../components/Channel/input";
import ChannelSettings from "../../components/Channel/settings";
import ChannelActions from "../../components/Channel/actions";
import ChannelDetailDialog from "../../components/Channel/detail-dialog";
import ChannelStreamsDialog from "../../components/Channel/channel-streams-dialog";
import { ServerSelectingDialog } from "../../components/Contribution/serverSelectModal";
import { setLoading } from "../../action";
import { noParamGet, paramPost, paramGet } from "../../apis/CommonApi";
import { ToastsStore } from "react-toasts";
import { calcVideoQuality, isAdmin, isManager } from "../../utils";

const Channels = ({ setLoading, authUser }) => {
  const [open, setOpen] = useState(false);
  const [serverList, setServerList] = useState([]);
  const [streamsList, setStreamsList] = useState([]);
  const [groups, setGroups] = useState([]);
  const [openServerDialog, setOpenServerDialog] = useState(false);
  const [openStreamDialog, setOpenStreamDialog] = useState(false);
  const [selectedServer, setSelectedServer] = useState(null);
  const [tvChannels, setTvChannels] = useState([]);
  const [selectedDetailChannel, setSelectedDetailChannel] = useState(null);
  const [selectedSearchServerId, setSelectedSearchServerId] = useState(null);
  const [isRequestedRefresh, setIsRequestedRefresh] = useState(false);

  const userRole = authUser.user.mode.name;

  const getServers = async () => {
    if (!isAdmin(userRole)) return;
    setOpenServerDialog(true);
  }

  const handleCloseServerSelect = useCallback(async (val, serverData = null) => {
    if (!serverData) {
      setOpenServerDialog(false);
    }
    if (val || serverData) {
      const serverId = val.server
      const serverInfo = serverData ? serverData : serverList.find(server => server._id === serverId);
      if (serverInfo) {
        setLoading(true);
        setSelectedServer(serverInfo);
        try {
          const streams = await paramGet(`/api/channel/streams`, { serverId: serverInfo._id });
          setLoading(false);
          setStreamsList(streams.streams.streams);
          if (!serverData) {
            setOpenStreamDialog(true);
          }
        } catch (e) {
          setLoading(false);
        }
      }
    }
  }, [setLoading, serverList]);

  const updateStreamListFromCallback = useCallback(async () => {
    const streams = await paramGet(`/api/channel/streams`, { serverId: selectedSearchServerId });
    setStreamsList(streams.streams.streams);
  }, [selectedSearchServerId]);

  const handleUpdateStream = useCallback(async (stream) => {
    await updateStreamListFromCallback();
  }, [updateStreamListFromCallback]);

  const handleCloseDetailChannel = useCallback(async (val) => {

    setOpen(false);
    setSelectedDetailChannel(null);
    await updateStreamListFromCallback();
  }, [updateStreamListFromCallback]);

  const getTableRowData = useCallback((channelName) => {
    const channelDetail = streamsList.find(stream => stream.name === channelName);
    return channelDetail;
  }, [streamsList]);

  const handleShowDetail = useCallback(async (channel) => {
    setSelectedDetailChannel(channel);
    setOpen(true);
  }, []);

  const handleUpdateChannelTitle = useCallback((channelInfo) => {
    // Find channel info from tvChannels and update title
    const updatedChannel = tvChannels.find(channel => channel.name === channelInfo.name);
    if (updatedChannel) {
      updatedChannel.title = channelInfo.title;
      setTvChannels([...tvChannels]);
    }
  }, [tvChannels]);

  const renderTableRow = useMemo(() => {
    if (streamsList.length === 0 || tvChannels.length === 0) return null;
    return tvChannels.map((channel, index) => {
      const channelDetail = getTableRowData(channel.name);
      if (!channelDetail) return null;
      //
      const channelConfig = channelDetail.config_on_disk;
      const stats = channelDetail.stats;
      const channelName = channelConfig.name;
      const channelTitle = channel.title || channelDetail.config_on_disk.title || "";
      const channelStatus = channelDetail.disabled ? false : true;
      const isAlive = stats.alive;
      //

      const channelUrl =  channelDetail.inputs[0] ? channelDetail.inputs[0].url || "" : "";
      const inputBandwidth = stats.inputs_bandwidth ? Math.round(stats.inputs_bandwidth / 1000) : 0;
      const runningStatus = channelDetail.disabled ? false : true;
      //
      const tracks = stats.media_info ? stats.media_info.tracks : null;
      let videoTrack = null;
      let audioTrack = null;
      if (tracks && tracks.length > 0) {
        videoTrack = tracks.find(item => item.content === 'video');
        audioTrack = tracks.find(item => item.content === 'audio');
      }
      const videoWidthAndHeight = videoTrack ? `${videoTrack.codec} ${videoTrack.width} x ${videoTrack.height} ${calcVideoQuality(videoTrack.height)}` : "";
      const audioRate = audioTrack ? `${audioTrack.codec} ${audioTrack.bitrate}kbps` : "";
      // Settings
      const transcoderStatus = stats.running_transcoder ? "on" : "off";
      const templateQuality = channelDetail.template || "";
      const dvrStatus = stats.dvr_enabled ? "on" : "off";

      return (
        <TableRow key={index} sx={{ verticalAlign: 'top', cursor: 'pointer' }} onClick={() => handleShowDetail(channel)}>
          <TableCell>
            <ChannelName
              channelId={channel._id}
              name={channelName}
              title={channelTitle}
              status={channelStatus}
              isAlive={isAlive}
              url={channelUrl}
              onTitleUpdate={handleUpdateChannelTitle}
              onClickChannelTitle={() => handleShowDetail(channel)}
            />
          </TableCell>
          <TableCell>
            <ChannelInOut video={`h264 @${inputBandwidth}kbps`} audio={audioRate} resolution={videoWidthAndHeight} />
          </TableCell>
          <TableCell>
            {channel.serverInfo ? channel.serverInfo.title : ""}
          </TableCell>
          <TableCell>
            <ChannelSettings transcoderStatus={transcoderStatus} dvrStatus={dvrStatus} template={templateQuality} quality={videoTrack ? calcVideoQuality(videoTrack.height) : ""} />
          </TableCell>
          <TableCell sx={{ verticalAlign: "middle" }}>
            <ChannelActions runningStatus={runningStatus} channelName={channel.name} serverInfo={channel.serverInfo} isManager={isManager(userRole)} onChangeStatus={handleUpdateStream} />
          </TableCell>
        </TableRow>)
    })
  }, [getTableRowData, handleShowDetail, handleUpdateChannelTitle, handleUpdateStream, streamsList.length, tvChannels, userRole]);


  const handleSelectedStreams = async (selectedStreams) => {
    if (selectedStreams && selectedStreams.length > 0) {
      // Filter selected streams with name
      let params = []
      selectedStreams.forEach(stream => {
        const streamServer = streamsList.find(item => item.name === stream);
        if (streamServer && selectedServer) {
          params.push({
            name: streamServer.name,
            title: streamServer.title,
            serverId: selectedServer._id,
            groupIds: selectedServer.groupIds,
          });
        }
      });
      if (params.length > 0) {
        // Post selected streams to server
        try {
          await paramPost('/api/channel', params);
          ToastsStore.success('Saved streams successfully!');
          setIsRequestedRefresh(true);
        } catch (e) {
          ToastsStore.error('Something went wrong! Please try again.');
        }
      }
    }
    setOpenStreamDialog(false);
  }

  const handleSearch = useCallback(async (values) => {
    if (isRequestedRefresh) {
      setIsRequestedRefresh(false);
    }
    if (values.serverId) {
      setSelectedSearchServerId(values.serverId);
      try {
        setLoading(true);
        const channels = await paramPost('/api/channel/get_channels', values);
        if (channels.data && channels.data.length > 0) {
          setTvChannels(channels.data);
          await handleCloseServerSelect({ server: "123" }, channels.data[0].serverInfo);
        } else {
          setTvChannels([]);
          setStreamsList([]);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  }, [handleCloseServerSelect, isRequestedRefresh, setLoading]);

  const getGroupAndChannels = useCallback(async () => {
    try {
      const groups = await noParamGet('/api/group');
      const servers = await paramGet("/api/servers", { type: 'channel' });
      if (groups.data.length) {
        setGroups(groups.data);
      }
      if (servers.length) {
        setServerList(servers);
      }
    } catch (e) {
      console.log('error', e);
    }
  }, []);

  useEffect(() => {
    getGroupAndChannels();
  }, [getGroupAndChannels]);

  return (
    <>
      <SearchBar
        hasSearchInput
        pageType="Channel"
        hasGroupSearch
        hasServerSelector
        hasForceRefresh
        isRequestedRefresh={isRequestedRefresh}
        groups={groups}
        channelServerList={serverList}
        hasAddNew={isAdmin(userRole)}
        newButtonTitle={"+ Sync"}
        onChange={handleSearch}
        onCreateNew={getServers}
      />
      <Box sx={{ mt: 3, px: 3 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ bgcolor: '#ECEFF9' }}>
              <TableRow>
                <TableCell>Channel</TableCell>
                <TableCell>Input</TableCell>
                <TableCell>Server</TableCell>
                <TableCell>Settings</TableCell>
                <TableCell width="15%">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderTableRow}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {selectedDetailChannel && (
        <ChannelDetailDialog channel={selectedDetailChannel} isOpen={open} onClose={handleCloseDetailChannel} />
      )}
      <ChannelStreamsDialog serverList={streamsList} isOpen={openStreamDialog} onClose={handleSelectedStreams} />
      <ServerSelectingDialog open={openServerDialog} onClose={handleCloseServerSelect} servers={serverList} groups={[]} />
    </>
  )
}

const mapStateToProps = ({ authUser }) => ({ authUser })

export default connect(mapStateToProps, { setLoading })(Channels);

import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { lighten } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import StorageIcon from "@mui/icons-material/Storage";
import {
  Button,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import BlockIcon from "@mui/icons-material/Block";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { noParamGet, paramPost } from "../../apis/CommonApi";
import { bulkChangeServer } from "../../apis/Encoder";
import { ToastsStore } from "react-toasts";
import { setLoading } from "../../action";
import { connect } from "react-redux";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: "40px",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    selected,
    servers,
    getContributions,
    contributions,
    isOutput,
    isInput,
    isDestination,
    destinations,
    getAllDestinations,
  } = props;
  const [modal, setModal] = useState(false);
  const [destinationModal, setDestinationModal] = useState(false);
  const [serverSelected, setServerSelected] = useState("1");
  let [localIp, setLocalIp] = useState("Any");
  let [adapterId, setAdapterIp] = useState("Any");
  const [keepOrDelete, setKeepOrDelete] = useState("1");
  const [ips, setIps] = useState([]);
  let firstContributionSelected = contributions.find(
    (ele) => ele.name === selected[0]
  );

  const [destinationSelected, setDestinationSelected] = useState("");
  const [destinationHost, setDestinationHost] = useState("");

  const openModal = () => {
    setModal(true);
  };
  const openDestinationModal = async () => {
    setDestinationModal(true);
    getAllDestinations();
  };
  const handleSelectServer = async (e) => {
    setServerSelected(e.target.value);
    const serverInfo = await noParamGet(`/api/servers/${e.target.value}`);
    let res =
      serverInfo && serverInfo.data && serverInfo.data.adapterIps.length
        ? serverInfo.data.adapterIps
        : [];
    let data = Array.from(res, (ip) => ip.adapterIp) || [];
    data.unshift("Any");
    setIps(data);
  };
  const selectOptions = (servers) => {
    return servers.map((item, index) => {
      if (
        firstContributionSelected &&
        firstContributionSelected.serverInfo.id !== item.id
      ) {
        return (
          <MenuItem value={item.id} key={index}>
            {item.title}
          </MenuItem>
        );
      }
    });
  };
  const changeServer = async (e) => {
    e.preventDefault();
    const errorMessage =
      "Une erreur est survenue merci de réessayer ou de contacter notre support.";
    let newServerId = serverSelected;
    let contributions = selected;
    if (localIp === "Any") {
      localIp = "";
    }
    if (adapterId === "Any") {
      adapterId = "";
    }
    try {
      props.setLoading(true);
      bulkChangeServer({
        newServerId,
        contributions,
        localIp,
        adapterId,
        keepOrDelete,
      })
        .then(async (response) => {
          if (response) {
            ToastsStore.success("Server changed successfully..");
            window.location.reload();
            // await getContributions()
          } else {
            ToastsStore.error(errorMessage);
          }
          props.setLoading(false);
        })
        .catch((error) => {
          props.setLoading(false);
          ToastsStore.error(errorMessage);
        });
    } catch (error) {
      props.setLoading(false);
      ToastsStore.error(errorMessage);
    }
  };

  const deleteContribution = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        props.setLoading(true);
        const result = await paramPost("api/contribution/bulk-delete", {
          contriNames: selected,
        });
        props.setLoading(false);
        props.bulkDeleteRefresh(selected, 1);
        if (result && result.data.status) {
          ToastsStore.success("Input remove successfully..");
          setModal(false);
        } else {
          ToastsStore.error(result.data.result);
        }
      } catch (e) {
        props.setLoading(false);
        ToastsStore.error("Something went wrong. Please try again.");
      }
    }
  };

  const disableInput = async (isInput, isDisable) => {
    console.log("disableInput", isDisable, selected);
    props.setLoading(true);
    try {
      const url = isInput
        ? "api/contribution/bulk-update-input-status"
        : "api/contribution/output/bulk-update-output-status";
      const result = await paramPost(url, {
        names: selected,
        isDisable,
      });
      if (result && result.data.status) {
        if (isInput) {
          props.bulkDeleteRefresh(selected, 3);
        } else {
            props.bulkDeleteRefresh(selected, 3);
        }
        const text = isInput ? "Input" : "Output";
        ToastsStore.success(`${text} status updated successfully.`);
      }
      props.setLoading(false);
    } catch {
      props.setLoading(false);
      ToastsStore.error("Something went wrong. Please try again.");
    }
  };

  const deleteOutput = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        props.setLoading(true);
        const result = await paramPost("api/contribution/output/bulk-delete", {
          outputNames: selected,
        });
        if (result && result.data.status) {
          props.bulkDeleteRefresh(selected, 2);
          ToastsStore.success("Output remove successfully..");
          setModal(false);
        }
        props.setLoading(false);
      } catch (e) {
        props.setLoading(false);
        ToastsStore.error("Something went wrong. Please try again.");
      }
    }
  };

  /** SECTION DESTINATION **/

  const onCloseDestinationModal = () => {
    setDestinationHost("");
    setDestinationSelected("");
    setDestinationModal(false);
  };

  const deleteDestination = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        props.setLoading(true);
        const result = await paramPost(
          "api/contribution/destinations/bulk-delete",
          { destinationIds: selected }
        );
        if (result && result.data.status) {
          props.bulkDeleteRefresh(selected, 2);
          ToastsStore.success("Destination(s) remove successfully..");
        }
        props.setLoading(false);
      } catch (e) {
        props.setLoading(false);
        ToastsStore.error("Something went wrong. Please try again.");
      }
    }
  };

  const handleDestinationSelectServer = (evt) => {
    const value = evt.target.value;
    setDestinationSelected(value);
    const dest = destinations.find((el) => el.id === value);
    setDestinationHost(dest.ip);
  };

  const onSubmitChangeDestination = async (e) => {
    e.preventDefault();
    const data = {
      destinationId: destinationSelected,
      outputIds: selected,
    };
    if (!data.destinationId) {
      ToastsStore.error("Please select destination");
      return;
    }
    try {
      props.setLoading(true);
      const result = await paramPost(
        "api/contribution/output/bulk-change-output-destination",
        data
      );
      props.setLoading(false);
      if (result && result.data.status) {
        ToastsStore.success(result.data.msg);
        onCloseDestinationModal();
        props.refreshAfterBulkAsignDestination();
      } else {
        ToastsStore.error(result.data.msg);
      }
    } catch (e) {
      props.setLoading(false);
      ToastsStore.error("Something went wrong. Please try again.");
    }
  };

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}>
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"></Typography>
        )}

        {numSelected > 0 ? (
          <>
            {isInput ? (
              <>
                <Tooltip title="Disable">
                  <IconButton
                    aria-label="delete"
                    onClick={() => disableInput(true, true)}>
                    <BlockIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Enable">
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => disableInput(true, false)}>
                    <RestartAltIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => deleteContribution(e)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Change server">
                  <IconButton aria-label="storage" onClick={() => openModal()}>
                    <StorageIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : isOutput ? (
              <>
                <Tooltip title="Disable">
                  <IconButton
                    aria-label="delete"
                    onClick={() => disableInput(false, true)}>
                    <BlockIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Enable">
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => disableInput(false, false)}>
                    <RestartAltIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => deleteOutput(e)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Change destination">
                  <IconButton
                    aria-label="storage"
                    onClick={() => openDestinationModal()}>
                    <StorageIcon />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => deleteDestination(e)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              {/* <FilterListIcon /> */}
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
      {numSelected > 0 ? (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown={true}
          onClose={() => setModal(false)}
          open={modal}
          fullWidth
          maxWidth="md">
          <form autoComplete="off" onSubmit={changeServer}>
            <DialogTitle>SERVERS</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl variant="outlined" className="width-100">
                    <TextField
                      fullWidth
                      label="Current server"
                      value={
                        firstContributionSelected
                          ? firstContributionSelected.serverInfo.title
                          : ""
                      }
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" className="width-100">
                    <InputLabel id="server-selected">Select Server</InputLabel>
                    <Select
                      value={serverSelected.id}
                      labelId="server-selected"
                      label="Select Server"
                      onChange={handleSelectServer}>
                      <MenuItem value={"1"} disabled={true}>
                        === Select ===
                      </MenuItem>
                      {servers.length && selectOptions(servers)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" className="width-100">
                    <TextField
                      fullWidth
                      select
                      label="Local IP"
                      value={localIp || "Any"}
                      onChange={(e) => setLocalIp(e.target.value)}>
                      {ips.map((child, index) => (
                        <MenuItem value={child || ""} key={child || ""}>
                          {child || ""}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" className="width-100">
                    <TextField
                      fullWidth
                      select
                      label="Adapter IP"
                      value={adapterId || ""}
                      onChange={(e) => setAdapterIp(e.target.value)}>
                      {ips.map((child, index) => (
                        <MenuItem value={child || ""} key={child || ""}>
                          {child || ""}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" className="width-100">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={keepOrDelete}
                      onChange={(event) => setKeepOrDelete(event.target.value)}>
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Remove from current server"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Keep on current server"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="mx-1">
              <Button type="submit" variant="contained" color="primary">
                ok
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="secondary"
                className="ml-1"
                onClick={() => setModal(false)}>
                close
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      ) : (
        ""
      )}

      {numSelected > 0 ? (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown={true}
          onClose={onCloseDestinationModal}
          open={destinationModal}
          fullWidth
          maxWidth="md">
          <form autoComplete="off" onSubmit={onSubmitChangeDestination}>
            <DialogTitle>DESTINATIONS</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl variant="outlined" className="width-100">
                    <TextField
                      fullWidth
                      select
                      label="Select destination"
                      value={destinationSelected || ""}
                      onChange={handleDestinationSelectServer}>
                      {destinations
                        ? destinations.map((child, index) => (
                            <MenuItem
                              value={child.id || ""}
                              key={child.id || ""}>
                              {child.title || ""}
                            </MenuItem>
                          ))
                        : ""}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" className="width-100">
                    <TextField
                      fullWidth
                      label="Destination Host"
                      value={destinationHost || ""}
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="mx-1">
              <Button type="submit" variant="contained" color="primary">
                ok
              </Button>
              <Button
                type="reset"
                variant="contained"
                color="secondary"
                className="ml-1"
                onClick={onCloseDestinationModal}>
                close
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      ) : (
        ""
      )}
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { setLoading };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnhancedTableToolbar);
// export default EnhancedTableToolbar;

import React, {useEffect, useState} from 'react'
import {Grid, Button, TextField, MenuItem, Paper, AppBar, Tabs, Tab, Tooltip} from '@mui/material'
import {connect} from 'react-redux'
import Encryption from './settingTypes/Encryption';
import SRT from './settingTypes/SRT';
import {noParamGet, paramPost, paramPut, noParamPost} from '../../apis/CommonApi';
import {ToastsStore} from 'react-toasts';
import {setLoading} from '../../action';
import './style.scss';
import FEEDER from "./settingTypes/FEEDER";
import AdvancedParams from "./settingTypes/Advanced";
import IconButton from "@mui/material/IconButton";
import {ArrowBack} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import LOCAL from "./settingTypes/LOCAL";

const inputTypes = [
    'Local',
    'aCANFeeder',
    'SRT',
    'RIST',
    // 'OTT'
]
const initialValues = {
    local: {
        multicast: '',
        adapterIp: '',
    },
    feeder: {
        port: '', // input port
        multicast: '',
        host: '',
        adapterIp: '',
    },
    srt: {
        mode: 'Listen',
        host: '',
        port: '',
        multicast: '',
        ttl: '',
        adapterIp: '',
        maxBandWidth: '625000'
    },
    rist: {
        mode: 'Listen',
        bufferMin: '',
        host: '',
        profile: 'Main',
        port: '',
        statInterval: '',
        logLevel: 1,
        adapterIp: '',
        latency: '',

        bandWidth: ''
    },
    encryption: {
        selection: 'no',
        passphrase: '',
        type: '128'
    },
    advanced: []
}

export const ContributionConfig = (props) => {

    useEffect(() => {
        const {id, serverId, groupId} = props.match.params;
        if (id !== "new") {
            setIsNew(false);
        }
        setServerIdInfo(serverId);
        setGroupId(groupId);
        getServer(serverId, id);
    }, [])

    const history = useHistory();

    const [groupId, setGroupId] = useState("");
    const [isNew, setIsNew] = useState(true);
    const [basicSetting, setBasicSetting] = useState({
        name: "",
        title: "",
        // streamIndex: 0,
        inputType: "aCANFeeder"
    });
    const [tabIndex, setTabIndex] = useState(0);
    const [editId, setEditId] = useState(null);
    const [localSettings, setLocalSettings] = useState(
        {
            multicast: '',
            adapterIp: '',
        }
    );
    const [feederSettings, setFeederSettings] = useState(
        {
            port: '6000',
            multicast: '',
            host: '',
            adapterIp: '',
        }
    );
    const [srtSettings, setSrtSettings] = useState(
        {
            mode: 'Listen',
            host: '',
            port: '',
            multicast: '',
            latency: '3000',
            adapterIp: '',
            maxBandWidth: '625000'
        }
    );
    const [ristSettings, setRistSettings] = useState({
        mode: 'Listen',
        bufferMin: '',
        host: '',
        profile: '2',
        port: '',
        statInterval: '',
        logLevel: 1,
        adapterIp: '',
        latency: '3000',
        multicast: '',
        bandWidth: '625000'
    });

    const [encryption, setEncryption] = useState({
        selection: 'no',
        passphrase: '',
        type: '128'
    })

    const [advanced, setAdvanced] = useState([]);

    const [localKeys, setLocalKeys] = useState([
        {label: 'Multicast', field: 'multicast',},
        {label: 'Adapter IP', field: 'adapterIp', type: 'select', children: []},
    ]);

    const [feederKeys, setFeederKeys] = useState([
        {label: 'Input Port', field: 'port', type: 'number'},
        {label: 'Multicast', field: 'multicast',},
        {label: 'Local IP', field: 'host', type: 'select', children: []},
        {label: 'Adapter IP', field: 'adapterIp', type: 'select', children: []},
    ]);

    const [srtKeys, setSrtKeys] = useState([
        {label: 'Mode', field: 'mode', type: 'select', children: ['Listen']},
        // {label: 'Local IP', field: 'host'},
        {label: 'Local IP', field: 'host', type: 'select', children: []},
        {label: 'Port', field: 'port', type: 'number'},
        {label: 'Multicast', field: 'multicast',},
        {label: 'Latency', field: 'latency', type: 'number'},
        {label: 'Adapter IP', field: 'adapterIp', type: 'select', children: []},
        {label: 'Max BandWidth', field: 'maxBandWidth', type: 'number'},
    ]);

    const [ristKeys, setRistKeys] = useState([
        // { label: 'Mode', field: 'mode', type: 'select', children: ['Listen', 'Rendez-vous', 'Push'] },
        // { label: 'Mode', field: 'mode', type: 'select', children: ['Listen', 'Push'] },
        {label: 'Mode', field: 'mode', type: 'select', children: ['Listen']},
        {label: 'Buffer Min', field: 'bufferMin', type: 'number'},
        {label: 'Local IP', field: 'host', type: 'select', children: []},
        {
            label: 'Profile',
            field: 'profile',
            type: 'select',
            children: [{label: 'Main', value: '2'}, {label: 'Simple', value: '1'}]
        },
        {label: 'Port', field: 'port', type: 'number'},
        {label: 'Stat Interval', field: 'statInterval', type: 'number'},
        {label: 'Log Level', field: 'logLevel', type: 'select', children: [1, 2, 3]},
        {label: 'Adapter Name', field: 'adapterIp', type: 'select', children: []},
        {label: 'Latency', field: 'latency', type: 'number'},
        {label: 'Multicast', field: 'multicast',},
        {label: 'BandWidth', field: 'bandWidth', type: 'number'},
    ]);

    const [serverIdInfo, setServerIdInfo] = useState('')

    const setEmptyToAny = () => {
        let feeder = {...feederSettings}, srt = {...srtSettings}, rist = {...ristSettings}, local = {...localSettings};
        if (localSettings.adapterIp === '') local.adapterIp = 'Any';
        if (feederSettings.host === '') feeder.host = 'Any';
        if (feederSettings.adapterIp === '') feeder.adapterIp = 'Any';
        if (srtSettings.host === '') srt.host = 'Any';
        if (srtSettings.adapterIp === '') srt.adapterIp = 'Any';
        if (ristSettings.host === '') rist.host = 'Any';
        if (ristSettings.adapterIp === '') rist.adapterIp = 'Any';
        setLocalSettings(local);
        setFeederSettings(feeder);
        setSrtSettings(srt);
        setRistSettings(rist);
    }

    const getServer = async (Id, conId) => {
        props.setLoading(true);
        const serverInfo = await noParamGet(`/api/servers/${Id}`);
        const multicast = await noParamPost("/api/contribution/multicast");
        const port = await noParamPost("/api/contribution/feeder-next-input-port");
        setFeederSettings({...feederSettings, port});
        if (conId === 'new') {
            await updateInitialValues(serverInfo.data, multicast, port)
        } else {
            const contributionInfo = await noParamGet(`/api/contribution/${conId}`);
            if (serverInfo.data && contributionInfo.data) {
                const {inputType, name, encryption, advanced, config, title, id} = contributionInfo.data;
                setEditId(id);
                const ips = serverInfo.data.adapterIps.length ? serverInfo.data.adapterIps : [];
                setBasicSetting({
                    title, inputType, name
                })
                if (inputType === "SRT" || inputType === "aCANFeeder") {
                    setFeederSettings({
                        host: config.host || 'Any',
                        port: config.port,
                        adapterIp: config.adapterIp || 'Any',
                        multicast: config.multicast
                    });
                    setSrtSettings(config)
                    setRistSettings({...ristSettings, multicast: config.multicast})
                } else {
                    setLocalSettings(config)
                    setRistSettings(config)
                    setSrtSettings({...srtSettings, multicast: config.multicast})
                }
                setEncryption(encryption);
                setAdvanced(advanced ? advanced : []);
                initialValues.advanced = advanced;
                await setServerIPList(ips);
            }
        }
        props.setLoading(false);
    }

    const updateInitialValues = async (serverInfo, multicast, port) => {
        if (serverInfo) {
            const ips = serverInfo.adapterIps.length ? serverInfo.adapterIps : [];
            setLocalSettings({
                ...localSettings,
                adapterIp: 'Any',
                multicast,
            });
            setFeederSettings({
                ...feederSettings,
                adapterIp: 'Any',
                host: 'Any',
                multicast,
                port
            });
            setSrtSettings({
                ...srtSettings,
                adapterIp: 'Any',
                multicast,
                port
            });
            setRistSettings({
                ...ristSettings,
                adapterIp: ips[0].adapterName,
                host: 'Any',
                multicast,
                port
            })
            await setServerIPList(ips);
        }
    }

    const setServerIPList = async (ips) => {
        let newArr = [...srtKeys], newFeederArr = [...feederKeys], localArr = [...localKeys];

        newArr[5].children = Array.from(ips, ip => ip.adapterIp) || [];
        newArr[1].children = Array.from(ips, ip => ip.adapterIp) || [];
        localArr[1].children = Array.from(ips, ip => ip.adapterIp) || [];
        newArr[1].children.unshift('Any');
        newArr[5].children.unshift('Any');
        localArr[1].children.unshift('Any');
        feederKeys[2].children = Array.from(ips, ip => ip.adapterIp) || [];
        feederKeys[3].children = Array.from(ips, ip => ip.adapterIp) || [];
        feederKeys[2].children.unshift('Any');
        feederKeys[3].children.unshift('Any');
        setSrtKeys(newArr);
        setFeederKeys(feederKeys);
        setLocalKeys(localArr);
        let ristArr = [...ristKeys];
        ristArr[7].children = Array.from(ips, ip => ip.adapterName) || [];
        ristArr[2].children = Array.from(ips, ip => ip.adapterIp) || [];
        ristArr[2].children.unshift('Any');
        setRistKeys(ristArr);
    }

    const handleSubmit = async () => {
        if (!serverIdInfo) {
            return ToastsStore.warning('Server is not selected');
        }
        if (advanced.length > 0) {
            const advancedNotInit = advanced.find(ele => ele.key === '' || ele.value === '')
            if (advancedNotInit) {
                return ToastsStore.warning('Advanced params are not configured correctly');
            }
        }
        let configs = basicSetting;
        configs.encryption = encryption;
        configs.advanced = advanced;
        configs.serverId = serverIdInfo;
        configs.groupId = groupId;
        switch (basicSetting.inputType) {
            case 'SRT':
                if (srtSettings.host === 'Any') {
                    srtSettings.host = '';
                }
                if (srtSettings.adapterIp === 'Any') {
                    srtSettings.adapterIp = '';
                }
                srtSettings.port = "" + srtSettings.port;
                configs.config = srtSettings;
                break;
            case 'RIST':
                if (ristSettings.host === 'Any') {
                    ristSettings.host = '';
                }
                configs.config = ristSettings;
                break;
            case 'aCANFeeder':
                if (feederSettings.host === 'Any') {
                    feederSettings.host = '';
                }
                if (feederSettings.adapterIp === 'Any') {
                    feederSettings.adapterIp = '';
                }
                srtSettings.host = feederSettings.host;
                srtSettings.adapterIp = feederSettings.adapterIp;
                srtSettings.port = "" + feederSettings.port;
                configs.config = srtSettings;
                break;
            case 'Local':
                if (localSettings.adapterIp === 'Any') {
                    localSettings.adapterIp = '';
                }
                srtSettings.adapterIp = localSettings.adapterIp;
                configs.config = localSettings;
                break;
            default:
                break;
        }
        props.setLoading(true);
        if (!editId) {
            await paramPost("/api/contribution", configs)
                .then(async res => {
                    props.setLoading(false);
                    ToastsStore.success('Contribution added successfully');
                    props.history.push('/dashboard/contribution');
                })
                .catch(err => {
                    setEmptyToAny();
                    props.setLoading(false);
                    ToastsStore.error(err.data.error || 'Failed to create contribution');
                })
        } else {
            await paramPut(`/api/contribution/${editId}`, configs)
                .then(async res => {
                    props.setLoading(false);
                    ToastsStore.success('Contribution updated successfully');
                    props.history.push('/dashboard/contribution');
                })
                .catch(err => {
                    props.setLoading(false);
                    ToastsStore.error(err.data.error || 'Failed to update contribution');
                    setEmptyToAny();
                })
        }
    }

    const changeField = (e, field) => {
        let val = basicSetting;
        if (field === "streamIndex") {
            val['name'] = `encoder${e.target.value}`
        }
        val[field] = e.target.value;
        if (e.target.value === 'RIST') {
            setAdvanced([]);
            initialValues.advanced = [];
        }
        setBasicSetting({...basicSetting})
        if (e.target.value === 'SRT') {
            setSrtSettings({...srtSettings, host: 'Any'})
        }
    }

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue)
        setEmptyToAny();
    }

    const handleConfigChange = (type, values) => {
        if (type === 1) {
            setSrtSettings(values)
            setFeederSettings(values);
        } else if (type === 2) {
            setRistSettings(values)
        } else if (type === 0) {
            setLocalSettings(values)
        } else if (type === 3) {
            setEncryption(values)
        } else {
            setAdvanced(values);
            initialValues.advanced = values;
        }
    }

    const handleModeChange = (type, val) => {
        if (type === 1) {
            let newArr = [...srtKeys];
            newArr[1].label = val !== "Listen" ? "Destination Host" : "Local IP";
            setSrtKeys(newArr);
        }
        if (type === 2) {
            let newArr = [...ristKeys];
            newArr[2].label = val !== "Listen" ? "Destination Host" : "Local IP";
            setRistKeys(newArr);
        }

    }

    const tabType = () => {
        switch (basicSetting.inputType) {
            case 'SRT':
                return <SRT
                    values={srtSettings}
                    keys={srtKeys}
                    onConfigChange={(val) => handleConfigChange(1, val)}
                    onModeChange={(val) => handleModeChange(1, val)}
                />
            case 'RIST':
                return <SRT
                    values={ristSettings}
                    keys={ristKeys}
                    onConfigChange={(val) => handleConfigChange(2, val)}
                    onModeChange={(val) => handleModeChange(2, val)}
                />
            case 'aCANFeeder':
                return <FEEDER
                    values={feederSettings}
                    keys={feederKeys}
                    onConfigChange={(val) => handleConfigChange(1, val)}
                    onModeChange={(val) => handleModeChange(1, val)}
                />
            case 'Local':
                return <LOCAL
                    values={localSettings}
                    keys={localKeys}
                    onConfigChange={(val) => handleConfigChange(0, val)}
                    onModeChange={(val) => handleModeChange(0, val)}
                />
            default:
                break;
        }
    }

    const tabs = [
        {
            label: `${isNew ? "Input" : "Input"} Settings`,
            chidren: tabType()
        },
        {
            label: "Encryption",
            chidren: <Encryption values={initialValues.encryption}
                                 onConfigChange={(val) => handleConfigChange(3, val)}/>
        },
        {
            label: "Advanced parameters",
            chidren: <AdvancedParams basicSetting={basicSetting} values={initialValues.advanced}
                                     onConfigChange={(val) => handleConfigChange(4, val)}/>
        }
    ];

    return (
        <Grid container className='config'>
            <Grid item xs={12} className="header">
                {basicSetting.name && ` | ${basicSetting.name}`}
            </Grid>
            <Grid item xs={12} justifyContent="space-between" display="flex">
                <Tooltip title="Back">
                        <IconButton
                            onClick={() => history.push("/dashboard/contribution")}>
                            <ArrowBack/>
                        </IconButton>
                </Tooltip>
                <Button className="add-button" variant="contained" onClick={() => handleSubmit()}>
                    {isNew ? 'Save' : 'Update'} Config
                </Button>
            </Grid>
            {/* <Grid item xs={12}>
                <Tooltip title="Back">
                    <IconButton
                        onClick={() => history.push("/dashboard/contribution")}>
                        <ArrowBack/>
                    </IconButton>
                </Tooltip>
            </Grid> */}
            <Grid item xs={12} className="mt-3"/>
            <Grid container component={Paper} p={2} className="format-encoder">
                <Grid item md={6} xs={12} p={1}>
                    <TextField
                        fullWidth
                        label="Title"
                        value={basicSetting['title'] || ''}
                        onChange={(e) => changeField(e, 'title')}
                    />
                </Grid>
                <Grid item md={6} xs={12} p={1}>
                    <TextField
                        select fullWidth
                        label="Input Type"
                        value={basicSetting['inputType'] || ''}
                        onChange={(e) => changeField(e, 'inputType')}
                    >
                        {
                            inputTypes.map(vf =>
                                <MenuItem key={vf} value={vf}>{vf}</MenuItem>
                            )
                        }
                    </TextField>
                </Grid>
            </Grid>
            <Grid item xs={12} className="mt-3"/>
            <Grid container className="format-encoder">
                <AppBar position="static" color='default'>
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        value={tabIndex}
                        onChange={handleTabChange} aria-label="simple tabs example">
                        {tabs.map((element, index) => (
                            <Tab
                                key={index}
                                className='text-transform-none'
                                // icon={element.icon}
                                label={element.label}/>
                        ))}
                    </Tabs>
                </AppBar>
                {tabs[tabIndex].chidren}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {setLoading}

export default connect(mapStateToProps, mapDispatchToProps)(ContributionConfig)
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty, EmailValidation } from '../../utils'
import { Grid, Typography } from '@mui/material'
import apartment from '../../assets/images/apartment.png'
import PC from '../../assets/images/pc.png'
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import {
  TextField, Button
} from '@mui/material';

import { signin } from '../../action';
import './style.scss';
import { ToastsStore } from 'react-toasts'

class Login extends Component {

  state = {
    email: '',
    password: '',
    isLoading: false
  }

  changeValue = field => evt => this.setState({ [field]: evt.target.value });
  /**********************
   * Go to sing up page *
   **********************/
  signin = async () => {
    this.setState({ isLoading: true })
    if (this.checkValidation()) {
      try {
        let result = await this.props.signin(this.state);
        if (result) {
          this.props.history.push('/');
        }
      } catch (error) {
        ToastsStore.error('Unknown error. Cannot contact the backend server right now.');
      }
    }
    this.setState({ isLoading: false })
  }

  signup = () => {
    this.props.history.push('/signup');
    // this.props.history.push('/signup');
  }
  onKeyPress = evt => {
    if (evt.key === "Enter") {
      this.signin();
    }
  }

  checkValidation = () => {
    const { email, password } = this.state;
    if (!EmailValidation(email)) return false;
    if (isEmpty(password)) return false;
    return true;
  }
  render() {
    const { email, isLoading } = this.state;
    return (
      <div className="login-page" style={{ backgroundImage: `url(${require('../../assets/images/login.png')})` }}>
        <Grid mx={'auto'} container spacing={3} className='login-form' onKeyPress={this.onKeyPress}>
          <Grid item xs={0} md={6} className="img-wrapper">
            <div className='img-wrapper__div'>
              <img src={apartment} alt="apart" className='apart-img' />
            </div>
          </Grid>
          <Grid item xs={12} md={6} className='form-content'>
            <img src={PC} alt="pcLogo" className="form-content__img" />
            <Typography className='form-content__title mb-1'>Connect to aCAN TS</Typography>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon style={{ color: '#004DE1', fontSize: '1.5rem' }} />
                  </InputAdornment>
                ),
                style: { fontSize: 20 }
              }}
              InputLabelProps={{
                style: { fontSize: 20 }
              }}
              label='Enter your email'
              onChange={this.changeValue('email')}
              value={email}
              error={!EmailValidation(email)}
              className="mb-2 border-radius-15"
              size='large'
            />
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon style={{ color: '#004DE1', fontSize: '1.5rem' }} />
                  </InputAdornment>
                ),
                style: { fontSize: 20 }
              }}
              InputLabelProps={{
                style: { fontSize: 20 }
              }}
              className="mb-2"
              size='large'
              label='Enter your Password' type="password" onChange={this.changeValue('password')} />
            <div className='login-button'>
              <Button className="login-button__btn" variant="contained" disableElevation color='primary' onClick={this.signin} disabled={!this.checkValidation() || isLoading}>
                {isLoading ? <CircularProgress color="primary" /> : 'Sign in'}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}
const mapStateToProps = ({ authUser }) => ({
  authUser
});
export default withRouter(connect(mapStateToProps, {
  signin
})(Login));
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { TableContainer, TableHead, TableRow, TableCell, TableBody, Table, Button, Dialog, DialogTitle, DialogContent, DialogActions, Radio } from '@mui/material'

export const GroupSelectorDialog = (props) => {
    const { onClose, open, groups } = props

    const [selectedId, setSelectId] = useState("")

    const handleSelect = (id) => {
      setSelectId(id)
    }

    const handleClose = (val) => {
        if(val !== "exit" && !selectedId) {
          onClose(false)
        } else {
          onClose(selectedId || val)
        }
        setSelectId('')
    };
    
    return (
        <Dialog disableBackdropClick disableEscapeKeyDown={true} onClose={handleClose} open={open}>
          <DialogTitle>Select a group</DialogTitle>
          <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Group Name</TableCell>
                  <TableCell>Group Manager</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  groups.length && groups.map(group => (
                    <TableRow key={group._id} onClick={() => handleSelect(group._id)}>
                      <TableCell>
                        <Radio checked={selectedId === group._id} />
                      </TableCell>
                      <TableCell>{group.title || ''}</TableCell>
                      <TableCell>{(group.userInfo.length && group.userInfo[0].fullName) || ''}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
          </DialogContent>
          <DialogActions className='mx-1'>
              <Button type='submit' variant="contained" color="primary" onClick={() => handleClose()}>
                  Select
              </Button>
              <Button variant="contained" color="secondary" className='ml-1' onClick={() => handleClose('exit')}>
                  Close
              </Button>
          </DialogActions>
        </Dialog>
    )
}

GroupSelectorDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    groups: PropTypes.array.isRequired
}

export default (GroupSelectorDialog)

import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import GroupDialog from '../../components/Group/GroupDialog'
import {
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Box,
} from '@mui/material'
import {paramPost, noParamGet, paramPut, noParamDelete} from '../../apis/CommonApi'
import SearchBar from '../../components/Common/searchbar'
import {ToastsStore} from 'react-toasts'
import {showAlert, setLoading} from '../../action'
import ActionButton from '../../components/Common/action-button'
import './style.scss'

const Group = (props) => {
    const [open, setOpen] = useState(false)
    const [managers, setManagers] = useState([])
    const [groups, setGroups] = useState([])
    const [editGroupId, setEditGroupId] = useState(undefined);
    const [param, setParam] = useState({
        title: "",
        description: "",
        manager: "1"
    })

    useEffect(() => {
        getUsers()
        getGroups()
    }, [])

    const refresh = async () => {
        getUsers();
        getGroups();

    }

    const getUsers = () => {
        props.setLoading(true);
        noParamGet("/api/users/getUsers")
            .then(res => {
                setManagers(res.data)
                props.setLoading(false)
            })
            .catch(err => {
                props.setLoading(false)
            })
    }

    const getGroups = async () => {
        await noParamGet("/api/group")
            .then(res => {
                setGroups(res.data)
            })
            .catch(err => {
                console.log("Getting group Error ==", err)
            })
    }

    const handleOpen = () => {
        setParam({
            title: "",
            description: "",
            manager: "1"
        })
        setOpen(!open)
        setEditGroupId(undefined)
    }

    const handleClose = async (val) => {
        if (val) {
            if (!editGroupId) {
                await paramPost("/api/group", val)
                    .then(async res => {
                        ToastsStore.success('Group added successfully');
                        await getGroups()
                        setOpen(!open)
                    })
                    .catch(err => {
                        ToastsStore.error(err.data.error || 'Failed to create group');
                    })
            } else {
                await paramPut(`/api/group/${editGroupId}`, val)
                    .then(async res => {
                        ToastsStore.success('Group updated successfully');
                        await getGroups()
                        setOpen(!open)
                    })
                    .catch(err => {
                        ToastsStore.error(err.data.error || 'Failed to update group');
                    })
            }
        } else {
            setOpen(!open)
            clearState()
        }
    }

    const clearState = () => {
        setParam({
            title: "",
            description: "",
            manager: "1"
        })
    }

    const editGroup = (group) => {
        setEditGroupId(group._id)
        setParam({
            title: group.title,
            description: group.description,
            manager: group.userInfo.length ? group.userInfo[0]._id : "1"
        })
        setOpen(true);
    }

    const handleRemoveGroup = async (id) => {
        await noParamDelete(`/api/group/${id}`)
            .then(async res => {
                ToastsStore.success('Group deleted successfully')
            })
            .catch(err => {
                ToastsStore.error(err.data.error || 'Failed to delete group');
            })
    }

    const removeGroup = (groupId) => {
        props.showAlert('Are you sure?', 'Are you sure you want to delete this Group?', async () => {
            await handleRemoveGroup(groupId);
            await getGroups();
        });
    }

    const groupList = () => {
        if (groups.length) {
            return (
                groups.map(group => (
                    <TableRow key={group._id}>
                        <TableCell>{group.title}</TableCell>
                        <TableCell>{group.description}</TableCell>
                        <TableCell>{group.userInfo.length ? group.userInfo[0].mode.name === "manager" ? group.userInfo[0].fullName : "" : ""}</TableCell>
                        <TableCell width="15%">
                            <ActionButton handleClick={() => editGroup(group)} type='edit' label='Edit Group' />
                            <ActionButton handleClick={() => removeGroup(group._id)} type='delete' label='Delete Group' />
                        </TableCell>
                    </TableRow>
                ))
            )
        }

    }

    return (
        <div className="group">
            <SearchBar 
                onChange={() => console.log("searching")}
                pageType="Group"
                onRefresh={refresh}
                onCreateNew={handleOpen}
                hasSearchInput={false} 
                hasAddNew 
                newButtonTitle="Add Group" />
            <Box sx={{ mt:3, px:3 }}>
                <TableContainer>
                    <Table>
                        <TableHead sx={{ bgcolor: '#ECEFF9' }}>
                            <TableRow>
                                <TableCell>Group Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Group Manager</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groupList()}
                        </TableBody>
                    </Table>
                </TableContainer>
                <GroupDialog open={open} onClose={handleClose} params={param} users={managers}/>
            </Box>
        </div>
    )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, {showAlert, setLoading})(Group);
import { createStore, applyMiddleware, compose } from 'redux';
import Thunk from 'redux-thunk';
import reducers from './reducers';
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory();

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;
const middleware = [Thunk, routerMiddleware(history)]
export function configureStore(preloadedState) {
  const store = createStore(
    reducers(history),
    preloadedState,
    composeEnhancers(
      applyMiddleware(...middleware),
    )
  );
  return store;
}

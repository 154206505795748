import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import VideoImg from "../../assets/icons/video_bold.png";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const ChannelSettings = ({transcoderStatus, dvrStatus, template, quality}) => {
  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box component="img" src={VideoImg} alt="video" sx={{ width: "13px", height: "1rem" }} />
        <Typography variant="body1" sx={{ color: '#A39C9C', fontSize: '14px'}}>Transcoder: </Typography>
        <Typography variant="body1" sx={{ color: transcoderStatus === "on" ? "#09E165" : "#E10909", textTransform: "uppercase", fontSize: '14px'}}>
            {transcoderStatus}
        </Typography>
        <FiberManualRecordIcon sx={{ color:  "#CCD5F0", fontSize: '12px' }} />
        <Typography variant="body1" sx={{ color: '#A39C9C', fontSize: '14px'}}>Template: </Typography>
        <Typography variant="body1" sx={{ color: '#4C4C4C' }}>
          {template}
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body1" sx={{ color: '#A39C9C', fontSize: '14px'}}>DVR: </Typography>
        <Typography variant="body1" sx={{ color: dvrStatus === "on" ? "#09E165" : "#E10909", textTransform: "uppercase", fontSize: '14px'}}>
            {dvrStatus}
        </Typography>
        <FiberManualRecordIcon sx={{ color:  "#CCD5F0", fontSize: '12px' }} />
        <Typography variant="body1" sx={{ color: '#A39C9C', fontSize: '14px'}}>Quality: </Typography>
        <Typography variant="body1" sx={{ color: '#4C4C4C', fontSize: '14px' }}>
          {quality}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default ChannelSettings;

import React, { Component } from 'react'
import {
  deleteEncoder,
  updateEncoder,
  getServiceStatusWithCancel,
  getEncodersWithPagination,
  updateEnabledStatus
} from '../../apis/Encoder';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { editEncoder, setEncoder, showAlert, setLoading } from '../../action'

import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Box
} from '@mui/material'
import StatusChip from '../../components/Common/statusLabel';
import { AddUserDialog } from '../../components';
import './style.scss'
import { ToastsStore } from "react-toasts";
import TablePagination from "@mui/material/TablePagination";
import SearchBar from "../../components/Common/searchbar";
import ActionButton from '../../components/Common/action-button';
import { noParamGet } from '../../apis/CommonApi';
import axios from "axios";

class ManagerEncoder extends Component {
  _isMounted = false;
  sources = [];

  state = {
    encoders: [],
    status: [],
    groups: [],
    addUserDialog: false,
    selectedUser: [],
    selectedIndex: -1,

    page: 0,
    rowsPerPage: 12,
    searchValue: '',
    groupId: '',
    encodersPaginate: {
      encoders: [],
      total: 0,
    },
  }

  async componentDidMount() {
    this.props.setLoading(true);
    this._isMounted = true;
    await this.propsToState()
    this.props.setLoading(false);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    if (!this._isMounted) return;
    // const encoders = await getEncoders();
    const groups = await noParamGet('/api/group');
    this.setState({ groups: groups.data || [] });
    this.getStatus();
    // this.props.setLoading(false);
  }

  componentWillUnmount() {
    this.sources.forEach(source => source.cancel("axios request cancelled"));
    this._isMounted = false;
  }
  removeEncoder = async id => {
    const encoder = this.state.encoders.find(encoder => encoder.id === id)
    if (encoder.config && encoder.config.feeder && encoder.config.feeder.length > 0) {
      ToastsStore.error("Please remove first feeder.");
      return false;
    }
    this.props.showAlert('Are you sure?', 'Are you sure you want to delete this Encoder?', async () => {
      await deleteEncoder(id);
      this.propsToState();
    });
  }

  openAddUserDialog = index => {
    this.setState({
      addUserDialog: true,
      selectedIndex: index,
      selectedUser: this.state.encoders[index].users
    })
  }

  addUser = async selectedUser => {
    const { selectedIndex, encoders } = this.state;
    if (selectedIndex !== -1) {
      const encoder = encoders[selectedIndex];
      encoder.users = selectedUser;
      await updateEncoder(encoder);
      this.setState({ addUserDialog: false })
    }

  }

  addConfig = encoder => {
    this.props.history.push(`/dashboard/edit_encoder/${encoder._id}`);
  }

  getStatusChip = (encoder, status) => {
    if (encoder.isEnabled === false) {
      return <StatusChip label="Disabled" type="disabled" />
    }
    if (encoder.config) {
      if (status.includes(encoder._id)) {
        return <StatusChip label="Online" type="success" />
      }
      return <StatusChip label="Offline" type="error" />
    }
    return <StatusChip label="Not Configured" type="warning" />
  }

  updateDisabledStatus = async (id, isEnabled) => {
    const res = await updateEnabledStatus(id, isEnabled);
    if (res) {
      if (isEnabled) {
        await this.getStatus(id);
      }
      const index = this.state.encoders.findIndex(encoder => encoder._id === id);
      if (index !== -1) {
        const encoders = this.state.encoders;
        encoders[index].isEnabled = isEnabled;
        this.setState({ encoders });
      }
    }
  }

  getStatus = async (id = null) => {
    const status = [];
    let encoders = this.state.encoders;
    if (id) {
      encoders = encoders.filter(encoder => encoder._id === id);
    }
    await Promise.all(encoders.map(async encoder => {
      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();
      this.sources.push(source);
      if (encoder.config) {
        if (!encoder.hasOwnProperty('isEnabled')) {
          encoder.isEnabled = true;
        }
        if (encoder.isEnabled) {
          await getServiceStatusWithCancel(encoder._id, source.token).then(result => {
            if (result) {
              status.push(encoder._id)
              this.setState({ status })
            }
          });
        }
      }
    }));
  }

  handleOutputChangePage = async (event, newPage) => {
    this.props.setLoading(true);
    const { page, rowsPerPage, searchValue, groupId } = this.state
    this.setState({ page: newPage });
    const encoders = await getEncodersWithPagination({ page: newPage, rowsPerPage, searchValue, groupId });
    this.setState({ encoders: encoders.encoders, encodersPaginate: encoders });
    this.props.setLoading(false);
    this.getStatus();
  }

  handleSearch = async (value) => {
    const { searchText, groupId } = value;
    this.setState({ searchValue: searchText, groupId });
    const { rowsPerPage, page } = this.state;
    if (searchText.length > 0) {
      const encoders = await getEncodersWithPagination({ page: 0, rowsPerPage: 0, searchValue: searchText, groupId });
      this.setState({
        encoders: encoders.encoders.map((item) => {
          if (!item.hasOwnProperty('isEnabled')) {
            item.isEnabled = true;
          }
          return item;
        }), encodersPaginate: encoders
      });
    } else {
      const encoders = await getEncodersWithPagination({ page, rowsPerPage, searchValue: searchText, groupId });
      this.setState({
        encoders: encoders.encoders.map((item) => {
          if (!item.hasOwnProperty('isEnabled')) {
            item.isEnabled = true;
          }
          return item;
        }), encodersPaginate: encoders
      });
    }
    this.getStatus();
  }

  render() {
    const { encoders, addUserDialog, selectedUser, status, page, rowsPerPage, encodersPaginate } = this.state;
    return (
      <div className="service-manager">
        <SearchBar
          hasGroupSearch
          pageType='Encoder'
          hasForceRefresh
          groups={this.state.groups || []}
          onChange={this.handleSearch}
          onRefresh={() => this.componentDidMount()}
          onCreateNew={() => this.props.editEncoder()}
          hasAddNew
          newButtonTitle="Add encoder" />
        <Box sx={{ mt: 3, px: 3 }}>
          <TableContainer>
            <Table>
              <TableHead sx={{ bgcolor: '#ECEFF9' }}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>IP</TableCell>
                  <TableCell>port</TableCell>
                  <TableCell>email</TableCell>
                  <TableCell>Group</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell width="15%">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  encoders.length && encoders.map((encoder, index) => (
                    <TableRow key={encoder._id}>
                      <TableCell>{encoder.name}</TableCell>
                      <TableCell>{encoder.title}</TableCell>
                      <TableCell>{encoder.ip}</TableCell>
                      <TableCell>{encoder.port}</TableCell>
                      <TableCell>{encoder.email}</TableCell>
                      <TableCell>{encoder.groupInfo ? encoder.groupInfo.title : ''}</TableCell>
                      <TableCell>
                        {this.getStatusChip(encoder, status)}
                      </TableCell>
                      <TableCell>
                        {encoder.hasOwnProperty('isEnabled') && !encoder.isEnabled ? (
                          <ActionButton handleClick={() => this.updateDisabledStatus(encoder._id, true)} label="Enable" type='enable' />
                        ) : (
                          <ActionButton handleClick={() => this.updateDisabledStatus(encoder._id, false)} label="Disable" type='disable' />
                        )}
                        <ActionButton handleClick={() => this.addConfig(encoder)} label="Edit Config" type='add' />
                        <ActionButton handleClick={() => this.props.editEncoder(encoder._id, encoder)} type='edit' label="Edit Encoder" />
                        <ActionButton handleClick={() => this.openAddUserDialog(index)} type='addUser' label="Add Users" />
                        <ActionButton handleClick={() => this.removeEncoder(encoder._id)} type='delete' label="Remove Encoder" />
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <TablePagination
          rowsPerPageOptions={[12]}
          component="div"
          count={encodersPaginate.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={this.handleOutputChangePage}
        />
        <AddUserDialog
          open={addUserDialog}
          selectedUser={selectedUser}
          onSelect={this.addUser}
          onClose={() => this.setState({ addUserDialog: false })}
        />
      </div>
    )
  }

}

ManagerEncoder.propTypes = {

}

const mapStateToProps = ({ encoderReducer }) => ({
  encoderReducer,
});
export default withRouter(connect(mapStateToProps, {
  editEncoder, setEncoder, showAlert, setLoading
})(ManagerEncoder));
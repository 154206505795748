const ThemeColors = {
  primary: {
    light: '#0065c1',
    main: '#357EDD',
    dark: '#5a97fa',
    contrastText: '#fff',
  },
  secondary: {
    light: '#3bb36a',
    main: '#0B9852',
    dark: '#007d39',
    contrastText: '#fff',
  },
  success: {
    light: '#0D9547',
    main: '#0D9547',
    dark: '#007d39',
    contrastText: '#fff',
  },
  error: {
    light: '#EC1010',
    main: '#EC1010',
    dark: '#aa2e25',
    contrastText: '#fff',
  },
  contrast: {
    light: '#f7f7f7',
    main: '#ffffff',
    dark: '#f7f7f7',
    contrastText: '#000',
  },
}

export default ThemeColors;

export const styles = {
  '--panda-primary-light': ThemeColors.primary.light,
  '--panda-primary-dark': ThemeColors.primary.dark,
  '--panda-primary-main': ThemeColors.primary.main,
  '--panda-primary-contrastText': ThemeColors.primary.contrastText,
  '--panda-secondary-light': ThemeColors.secondary.light,
  '--panda-secondary-dark': ThemeColors.secondary.dark,
  '--panda-secondary-main': ThemeColors.secondary.main,
  '--panda-secondary-contrastText': ThemeColors.secondary.contrastText
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, TextField, MenuItem, Button, Box, Stack, Typography } from '@mui/material'
import './style.scss';
/**
 * edit audio setting
 * @augments {Component<Props, State>}
 */
class AudioStream extends Component {
  _isMounted = false;

  state = {
    audioStream: {}
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState()
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = (props = this.props) => {
    if (!this._isMounted) return;
    const { audioStream } = props;
    this.setState({ audioStream })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeValue = field => evt => {
    const { audioStream } = this.state;
    audioStream[field] = evt.target.value;
    this.setState({ audioStream }, this.onChange)
  }

  onChange = () => this.props.onChange && this.props.onChange(this.state.audioStream);

  changeAudioType = (evt, newValue) => {
    const { audioStream } = this.state;
    audioStream.format = newValue;
    if (newValue === 'mp2') {
      delete audioStream['aac-profile'];
      delete audioStream['aac-encap'];
      audioStream['mp2-mode'] = 'stereo';
      audioStream['audio-type'] = 'undefined';
    } else {
      audioStream['aac-profile'] = 'aac-lc';
      audioStream['aac-encap'] = 'adts';
      delete audioStream['mp2-mode'];
      delete audioStream['audio-type'];
    }
    this.setState({ audioStream }, this.onChange)
  }

  render() {
    const { audioStream } = this.state;
    const keys = audioStream.format === 'mp2' ?
      [
        { label: 'Bitrate', field: 'bitrate', type: 'number' },
        { label: 'MP2 mode', field: 'mp2-mode', type: 'select', children: ['stereo', 'joint-stereo', 'dual-channel'] },
        { label: 'Audio type', field: 'audio-type', type: 'select', children: ['undefined', 'clean-effects', 'hearing-impaired', 'visual-impaired'] },
        { label: 'Lang', field: 'lang' },
        { label: 'PID', field: 'pid', type: 'number' }
      ]
      :
      [
        { label: 'Bitrate', field: 'bitrate', type: 'number' },
        { label: 'AAC profile', field: 'aac-profile', type: 'select', children: ['aac-lc', 'he-aac-v1', 'he-aac-v2'] },
        { label: 'AAC encap', field: 'aac-encap', type: 'select', children: ['adts', 'latm'] },
        { label: 'Lang', field: 'lang' },
        { label: 'PID', field: 'pid', type: 'number' }
      ]
    const renderItem = ({ label, field, type = 'text', children = [] }) => (
      <Stack direction="column" spacing={1} key={`${audioStream.format}-${field}`}>
        <Typography variant='body1'>{label}</Typography>
        <Box display="flex">
          {
            type === 'select'
              ? <TextField
                fullWidth select
                value={audioStream[field] || ''}
                onChange={this.changeValue(field)} >
                {
                  children.map((child, index) => <MenuItem value={child} key={child}>{child}</MenuItem>)
                }
              </TextField>
              : <TextField
                fullWidth
                type={type}
                value={audioStream[field] || ''}
                onChange={this.changeValue(field)} />
          }
          {
            field === 'bitrate' ?
              <Button variant='contained' size='small' sx={{ textTransform: 'none' }} onClick={() => this.props.getRate && this.props.getRate()}>Caculate from mux</Button>
              : null
          }
        </Box>
      </Stack>
    );

    return (
      <div className="panda-audio-stream">
        <FormControl component="fieldset">
          <Typography variant='body1'>Audio Type</Typography>
          <RadioGroup row name="travel" onChange={this.changeAudioType} value={audioStream.format || ''}>
            <FormControlLabel
              label="MP2"
              value="mp2"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              label="AAC"
              value="aac"
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </FormControl>
        <Box
          my={3}
          gap={3}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)'
          }}
        >
          {
            keys.map(element => renderItem({ ...element }))
          }
        </Box>
      </div>
    )
  }
}

AudioStream.propTypes = {
  audioStream: PropTypes.object.isRequired
}

export default AudioStream;
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToastsStore } from 'react-toasts';
import { TableContainer, TableHead, TableRow, TableCell, TableBody, Table, Box } from '@mui/material'
import {getUsers, updateUser, addUserApi, removeUser, addUserToGroup, updateUserGroup} from '../../apis/Authontication';
import UserManagerDialog from '../../components/UserManagerDialog';
import GroupSelectorDialog from '../../components/AddUserDialog/GroupSelector';
import { showAlert, setLoading } from '../../action'
import { noParamGet, paramGet } from '../../apis/CommonApi';
import SearchBar from '../../components/Common/searchbar';
import ActionButton from '../../components/Common/action-button';


import './style.scss'
import config from "../../config";

const userMode = ['monitor', 'editor']
class UserManager extends Component {
  _isMounted = false;

  state = {
    openEditDialog: false,
    editId: '',
    users: [],
    groups: [],
    addGroupDialog: false,
    searchText: '',
    groupId: ''
  }

  async componentDidMount() {
    this._isMounted = true;
    this.props.setLoading(true);
    await this.propsToState();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    try {
      if (!this._isMounted) return;
      this._isMounted = false;
      const groups = await noParamGet("/api/group");
      this.setState({ groups: groups.data });
      this.props.setLoading(false)
    } catch (error) {
      this.props.setLoading(false)
    }
  }

  editUser = editId => {
    this.setState({ editId, openEditDialog: true });
  }
  onCloseDialog = () => this.setState({ openEditDialog: false });

  openAddGroupDialog = userId => {
    this.setState({ editId: userId, addGroupDialog: true })
  }

  handleCloseGroupDialog = async val => {
    if(!val) {
      return ToastsStore.warning("Please select a group")
    }
    if(val === 'exit') {
      return this.setState({ addGroupDialog: false })
    }
    const res = await addUserToGroup({groupId: val, userId: this.state.editId})
    if(res) {
      ToastsStore.success("User added to group successfully")
      this.setState({ addGroupDialog: false })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  addNewUser = () => {
    this.setState({ editId: '', openEditDialog: true });
  }

  onSave = async (userInfo, groupSelected = null) => {
    if(userInfo.newPassword && userInfo.newPassword.length > 0) {
      if(userInfo.newPassword !== userInfo.newConfirmPassword) {
        return ToastsStore.warning("New password and confirm password not match!");
      }
      delete userInfo.newConfirmPassword;
    }
    else {
      delete userInfo.newPassword;
      delete userInfo.newConfirmPassword;
    }
    // return;
    if (userInfo._id) {
      let result = await updateUser(userInfo);
      result && ToastsStore.success('Success update user information');
      if(result && groupSelected) {
        const rs = await updateUserGroup({groupId: groupSelected, userId: result.id});
      }
    } else {
      let result = await addUserApi(userInfo);
      result && ToastsStore.success('Success add user ')
      if(result && groupSelected) {
        await addUserToGroup({groupId: groupSelected, userId: result.id});
      }
    }
    await this.getUserList({ searchText: this.state.searchText, groupId: this.state.groupId });
    this.setState({ openEditDialog: false })
    return true;
  }

  removeUser = async id => {
    this.props.showAlert('Are you sure?', 'Are you sure you want to delete this User?', async () => {
      await removeUser(id);
      ToastsStore.success('Success remove user ')
      await this.getUserList({ searchText: this.state.searchText, groupId: this.state.groupId });
      this.setState({ openEditDialog: false })
    });
  }

  loginUser = () => {
    let user = localStorage.getItem(config.userLocalStorage)
    if(user) {
      user = JSON.parse(user)
      return user.mode.name
    }
    return null
  }

  getUserList = async (params) => {
    this.props.setLoading(true);
    try {
      const users = await paramGet("/api/users/getUsers", params);
      this.setState({ users });
      this.props.setLoading(false)
    } catch (error) {
      this.props.setLoading(false)
    }
  }

  handleSearch = async (value) => {
    const { searchText, groupId } = value;
    this.setState({ searchText, groupId });
    const params = { searchText, groupId };
    await this.getUserList(params);
  }

  render() {
    const { users, editId, openEditDialog, addGroupDialog, groups } = this.state;
    return (
      <div className="user-manager">
        <SearchBar 
          pageType='User'
          onChange={this.handleSearch}
          onRefresh={() => this.componentDidMount()}
          hasForceRefresh
          onCreateNew={this.addNewUser} 
          hasAddNew={!userMode.includes(this.loginUser())} 
          hasGroupSearch 
          groups={groups || []}
          newButtonTitle="Add user" />
        <Box sx={{ mt:3, px:3 }}>
          <TableContainer>
            <Table>
              <TableHead sx={{ bgcolor: '#ECEFF9' }}>
                <TableRow color='primary'>
                  <TableCell width="35%">User Name</TableCell>
                  <TableCell width="25%">Email</TableCell>
                  <TableCell width="20%">Role</TableCell>
                  <TableCell width="20%">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  users.map(user => (
                    <TableRow key={user._id}>
                      <TableCell>{user.fullName || ''}</TableCell>
                      <TableCell>{user.email || ''}</TableCell>
                      <TableCell>{user.modeInfo && user.modeInfo.name}</TableCell>
                      <TableCell>
                        <ActionButton handleClick={() => this.editUser(user._id)} type='edit' label='Edit User' />
                        {user.modeInfo && userMode.includes(user.modeInfo.name) && !userMode.includes(this.loginUser()) ? (
                          <ActionButton handleClick={() => this.openAddGroupDialog(user._id)} type='addUser' label='Add To Group' />
                        ) : (
                          // <IconButton 
                          //   size='small' 
                          //   sx={{ border: '1px solid rgba(227, 227, 227, 1)', borderRadius: 0, m: '0.25rem', color: '#535252' }}
                          //   className="color-disabled" style={{cursor: "default"}}>
                          //   <PersonAddAltOutlinedIcon />
                          // </IconButton>
                          <ActionButton handleClick={() => console.log('')} type='addUser' label='' />
                        )}
                        {
                          !userMode.includes(this.loginUser()) &&
                            <ActionButton handleClick={() => this.removeUser(user._id)} type='delete' label='Delete User' />
                        }
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <UserManagerDialog
          open={openEditDialog}
          onClose={this.onCloseDialog}
          onSave={this.onSave}
          userId={editId}
        />
        <GroupSelectorDialog
          open={addGroupDialog}
          groups={groups}
          onClose={this.handleCloseGroupDialog}
         />
      </div>
    )
  }
}

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, {
  showAlert, setLoading
})(UserManager);

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import EnhancedTableHead from '../../components/Contribution/EnhancedTableHead';
import EnhancedTableToolbar from '../../components/Contribution/ToolBar';
import {
    Box,
    Button,
    Grid,
    Icon, LinearProgress,
    MenuItem, Select,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ServerSelectingDialog from '../../components/Contribution/serverSelectModal';
import { noParamDelete, noParamGet, paramGet, paramPost } from '../../apis/CommonApi';
import ReplayIcon from '@mui/icons-material/Replay';
import { ToastsStore } from 'react-toasts'
import { setLoading, showAlert } from '../../action'
import SearchTextBar from '../../components/Common/search-text';
import { ContributionSelectingDialog } from "../../components/Contribution/ContributionSelectModal";
import Pagination from "@mui/material/Pagination";
import { Done } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchSuggest from "../../components/Contribution/SearchSuggest";
import { Destination } from "./destination";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import axios from "axios";
import AuthHeader from '../../apis/AuthHeader';

const mainHeader = ['Status', 'Name', 'Server', 'Input Port', 'Uptime', 'Actions']

const mainOutputHeader = ['Status', 'Name', 'Input', 'Destination', 'Uptime', 'Actions']

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    gridContainer: {
        padding: '2rem'
    },
    newButton: {
        float: 'right',
        // marginTop: '5px',
        // marginRight: '15px',
    },
    title: {
        textAlign: 'center',
        fontSize: '1.2rem',
        color: '#5a97fa',
        fontWeight: '600'
    },
    searchBar: {
        margin: '2rem 0 1rem'
    },
    searchForm: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        marginTop: '20px',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    outputFilter: {
        marginLeft: '10px',
        background: '#5a97fa',
        color: '#fff',
        height: '22px',
        marginTop: '5px',
    },
    miniNavbar: {
        marginTop: '20px',
        marginBottom: '20px',
        background: '#5a97fa',
        display: 'flex',
        height: '40px',
    },
    miniNavButton: {
        marginRight: '10px',
        height: '39px',
        boxShadow: 'none',
    },
    miniNavButtonActive: {
        marginRight: '10px',
        height: '39px',
        boxShadow: 'none',
        background: '#1136bd',
        '&:hover': {
            background: "#1136bd",
        },
    },
    mb18: {
        marginBottom: '18px',
    }
}));

export const Contribution = (props) => {
    const classes = useStyles();
    const [openSub, setOpenSub] = useState(-1);
    const [openOutputSub, setOpenOutputSub] = useState(-1);
    const [selected, setSelected] = useState([]);
    const [servers, setServers] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(localStorage.getItem('rows-per-page') ? localStorage.getItem('rows-per-page') : 12);
    const [searchValue, setSearchValue] = useState("");
    const [groups, setGroups] = useState([]);
    const [lists, setLists] = useState({
        contributions: [],
        total: 0
    });
    const [oldLists, setOldLists] = useState({
        contributions: [],
        total: 0
    });
    const [openContributionModal, setOpenContributionModal] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [inputFilterServer, setInputFilterServer] = useState('filter');

    const [outputSelected, setOutputSelected] = useState([]);
    const [listOutputs, setListOutputs] = useState({
        outputs: [],
        total: 0
    });
    const [oldlistOutputs, setOldListOutputs] = useState({
        outputs: [],
        total: 0
    });
    const [outputFilter, setOutputFilter] = useState('filter');
    const [listInputsFilter, setListInputsFilter] = useState([]);

    const [inlineEditInput, setInlineEditInput] = useState('');
    const [inlineEditOutput, setInlineEditOutput] = useState('');

    const [suggests, setSuggests] = useState([]);
    const [displaySuggests, setDisplaySuggests] = useState(false);

    const [displaySectionNav, setDisplaySectionNav] = useState('inputs');

    const [openDestinationModal, setOpenDestinationModal] = useState(false);
    const [typeDestinationModal, setTypeDestinationModal] = useState('');

    const [allDestination, setAllDestination] = useState([]);
    const [outputDestinationFilter, setOutputDestinationFilter] = useState('filter');

    const statusIsRunningRef = useRef(true);
    const statusOutputIsRunningRef = useRef(true);

    const [groupId, setGroupId] = useState('filter');

    const [inputCancelList, setInputCancelList] = useState([]);
    const [outputCancelList, setOutputCancelList] = useState([]);


    const inlineEditInputOnChange = (e) => {
        setInlineEditInput(e.target.value);
    }
    const inlineEditOutputOnChange = (e) => {
        setInlineEditOutput(e.target.value);
    }

    const initInlineEdit = () => {
        const allInputs = document.querySelectorAll('.inline-edit-input');
        const allTitles = document.querySelectorAll('.span-input-title');
        const allEdtiBtnAction = document.querySelectorAll('.inline-edit-input-action-btn');
        for (let i = 0; i < allInputs.length; i++) {
            allInputs[i].style.display = 'none';
            allTitles[i].style.display = 'block';
            allEdtiBtnAction[i].style.display = 'none';
        }
    }
    const initInlineEditOutput = () => {
        const allInputs = document.querySelectorAll('.inline-edit-output');
        const allTitles = document.querySelectorAll('.span-output-title');
        const allEdtiBtnAction = document.querySelectorAll('.inline-edit-output-action-btn');
        for (let i = 0; i < allInputs.length; i++) {
            allInputs[i].style.display = 'none';
            allTitles[i].style.display = 'block';
            allEdtiBtnAction[i].style.display = 'none';
        }
    }

    const inlineEditInputOnDoubleClick = (row) => {
        setInlineEditInput(row.title);
        initInlineEdit();
        const input = document.getElementById(row.id);
        const spanTitle = document.getElementById('span-input-title' + row.id);
        const inlineEditActionBtn = document.getElementById('inline-edit-input-action-btn-' + row.id);
        input.style.display = 'block';
        spanTitle.style.display = 'none';
        inlineEditActionBtn.style.display = 'flex';
    }
    const inlineEditOutputOnDoubleClick = (row) => {
        setInlineEditOutput(row.title);
        initInlineEditOutput();
        const input = document.getElementById(row.id);
        const spanTitle = document.getElementById('span-output-title' + row.id);
        const inlineEditActionBtn = document.getElementById('inline-edit-output-action-btn-' + row.id);
        input.style.display = 'block';
        spanTitle.style.display = 'none';
        inlineEditActionBtn.style.display = 'flex';
    }

    const inlineEditInputOnCancel = (row) => {
        setInlineEditInput('');
        initInlineEdit();
    }
    const inlineEditOutputOnCancel = (row) => {
        setInlineEditOutput('');
        initInlineEditOutput();
    }

    const onSubmitInlineEditTypeInput = async (row) => {
        props.setLoading(true);
        const postData = { inputId: row.id, title: inlineEditInput, type: 'input' };
        const rs = await paramPost('/api/contribution/update-title', postData);
        if (rs.data.status) {
            ToastsStore.success(rs.data.result);
            let inputs = lists.contributions;
            for (const [index, item] of inputs.entries()) {
                if (inputs[index].id === row.id) {
                    inputs[index].title = postData.title;
                }
            }
            setLists({ ...lists, contributions: inputs });
        } else {
            ToastsStore.error("Something went wrong. Please try again.");
        }
        initInlineEdit();
        props.setLoading(false);
    }
    const onSubmitInlineEditTypeOutput = async (row) => {
        props.setLoading(true);
        const postData = { inputId: row.id, title: inlineEditOutput, type: 'output' };
        const rs = await paramPost('/api/contribution/update-title', postData);
        if (rs.data.status) {
            ToastsStore.success(rs.data.result);
            let outputs = listOutputs.outputs;
            for (const [index, item] of outputs.entries()) {
                if (outputs[index].id === row.id) {
                    outputs[index].title = postData.title;
                }
            }
            setListOutputs({ ...listOutputs, outputs: outputs });
        } else {
            ToastsStore.error("Something went wrong. Please try again.");
        }
        initInlineEditOutput();
        props.setLoading(false);
    }

    const recheckStatusFromState = async (type) => {
        if (type === 'inputs') {
            const inputTotal = oldLists.total;
            const cons = oldLists.contributions;
            statusIsRunningRef.current = true;
            for (const [index, item] of cons.entries()) {
                if (statusIsRunningRef.current === false) {
                    break;
                }
                if (item.inputType !== 'Local' && !item.apiResult) {
                    try {
                        if (!item.hasOwnProperty("isEnabled")) {
                            item.isEnabled = true;
                        }
                        if (item.isEnabled) {
                            const status = await noParamGet(`/api/contribution/status/${item.id}`);
                            cons[index].apiResult = status.data;
                            if (status.data.status) {
                                cons[index].uptime = getUptimeFromResults(status.data.results);
                            }
                        } else {
                            cons[index].apiResult = { status: false, error: 'Disabled' };
                        }
                    } catch (error) {
                        cons[index].apiResult = { status: false, error: "unknown" };
                    }
                }
                setLists({ ...lists, contributions: cons, total: inputTotal });
            }
            // setLists({...lists, contributions: cons});
        } else if (type === 'outputs') {
            const outputTotal = oldlistOutputs.total;
            const cons = oldlistOutputs.outputs;
            statusOutputIsRunningRef.current = true;
            for (const [index, item] of cons.entries()) {
                if (statusOutputIsRunningRef.current === false) {
                    break;
                }
                if (!item.apiResult) {
                    if (!item.hasOwnProperty("isEnabled")) {
                        item.isEnabled = true;
                    }
                    if (item.isEnabled) {
                        const status = await getOutputStatus(item.contributionId, item.name);
                        cons[index].apiResult = status.data;
                        if (status.data.status) {
                            cons[index].uptime = getUptimeFromResults(status.data.results);
                        }
                    } else {
                        cons[index].apiResult = { status: false, error: 'Disabled' };
                    }
                }
                setListOutputs({ ...listOutputs, outputs: cons, total: outputTotal });
            }
            // setListOutputs({...listOutputs, outputs: cons});
        }
    }

    const getUptimeFromResults = (results) => {
        return results && results.length > 0 ? results.slice(results.indexOf("uptime") + "uptime".length) : '';
    }

    const getStatusTextFromApiResult = (apiResult) => {
        return apiResult && apiResult.status ? "Running" :
            apiResult && !apiResult.status && (!apiResult.error || apiResult.error === 'exit status 3') ? 'Stopped' :
                apiResult && !apiResult.status && apiResult.error === 'exit status 4' ? "Offline" :
                    "Offline"
    }

    const getInitialValues = async () => {
        const servers = await noParamGet("/api/servers");
        const groups = await noParamGet("/api/group");
        await getAllDestinations();
        setGroups(groups.data || []);
        setServers(servers.data);
        if ((props.location.state && props.location.state.displayOutput) || displaySectionNav === 'outputs') {
            setDisplaySectionNav('outputs');
            const outFilterInput = localStorage.getItem('output-filter-input-id');
            const outFilterDesti = localStorage.getItem('output-filter-destination-id');
            if (outFilterDesti) {
                setOutputDestinationFilter(outFilterDesti);
            }
            if (outFilterInput) {
                await onOutputFilter(outFilterInput);
            } else if (outFilterDesti) {
                await onOutputDestinationFilter(outFilterDesti);
            } else {
                await getOutputs({ page, rowsPerPage, searchValue });
            }
        } else if (displaySectionNav === 'inputs') {
            const groupId = localStorage.getItem('group-id');
            const serverId = localStorage.getItem('server-id');
            if (serverId) {
                setInputFilterServer(serverId);
            } else {
                setInputFilterServer('filter');
            }
            if (groupId) {
                setGroupId(groupId);
            } else {
                setGroupId('filter');
            }
            await getContributions({ page, rowsPerPage, searchValue, groupId, serverId });
        }
    }

    const refresh = async () => {
        if (displaySectionNav === 'inputs') {
            statusIsRunningRef.current = false;
        } else if (displaySectionNav === 'outputs') {
            statusOutputIsRunningRef.current = false;
        }
        props.setLoading(true);
        setTimeout(async () => {
            const outFilterInput = localStorage.getItem('output-filter-input-id');
            statusIsRunningRef.current = true;
            if (displaySectionNav === 'outputs') {
                if (outFilterInput) {
                    await onOutputFilter(outFilterInput);
                } else {
                    await getOutputs({ page, rowsPerPage, searchValue });
                }
            } else if (displaySectionNav === 'inputs') {
                await getInitialValues();
            } else if (displaySectionNav === 'destinations') {
                try {
                    props.setLoading(true);
                    await getAllDestinations();
                    // const destinations = await paramGet("/api/contribution/destinations/lists", {
                    //     page,
                    //     rowsPerPage,
                    //     searchValue
                    // });
                    // localStorage.setItem('list-destinations', JSON.stringify(destinations));
                    props.setLoading(false);
                } catch (error) {
                    props.setLoading(false);
                }
            }
            props.setLoading(false);
        }, 1500);
    }

    const onRowPerPageChange = async (e, type) => {
        setRowsPerPage(e.target.value);
        localStorage.setItem('rows-per-page', e.target.value);
        if (type === 1) {
            statusIsRunningRef.current = false;
            setTimeout(async () => {
                statusIsRunningRef.current = true;
                await getContributions({ page, rowsPerPage: e.target.value, searchValue, serverId: inputFilterServer === 'filter' ? '' : inputFilterServer, groupId: groupId === 'filter' ? '' : groupId });
                setDisplaySectionNav('inputs')
                props.history.push({ pathname: "/dashboard/contribution", state: { displayOutput: false } });
            }, 1500);
        } else if (type === 2) {
            statusOutputIsRunningRef.current = false;
            setTimeout(async () => {
                statusOutputIsRunningRef.current = true;
                await getOutputs({ page, rowsPerPage: e.target.value, searchValue });
                setDisplaySectionNav('outputs');
                props.history.push({ pathname: "/dashboard/contribution", state: { displayOutput: true } });
            }, 1500);
        }
    }

    const getContributions = async (params) => {
        try {
            props.setLoading(true);
            setLists({ contributions: [], total: 0 });
            const contributions = await paramGet("/api/contribution", params);
            setLists(contributions);
            if (!params.serverId) {
                setOldLists(contributions);
            }
            props.setLoading(false);
            setLists(contributions);
            await getContributionStatus(contributions);
        } catch (error) {
            props.setLoading(false);
        }
    }

    const getContributionStatus = async (contributions) => {
        console.log('=== getting contributions ===', contributions.contributions.length);
        if (contributions.contributions.length) {
            console.log('=== contributions ===', contributions);
            let cons = contributions.contributions;
            for (let i = 0; i < cons.length; i++) {
                if (statusIsRunningRef.current === false) {
                    break;
                }
                if (cons[i].inputType !== 'Local') {
                    try {
                        if (!cons[i].hasOwnProperty("isEnabled")) {
                            cons[i].isEnabled = true;
                        }
                        if (cons[i].isEnabled) {
                            console.log('=== request ===', cons[i].id);
                            // Add to cancel list
                            const cancelToken = axios.CancelToken;
                            const source = cancelToken.source();
                            const sources = inputCancelList;
                            sources.push(source);
                            setInputCancelList(sources);
                            const status = await noParamGet(`/api/contribution/status/${cons[i].id}`, AuthHeader().headers, source.token);
                            cons[i].apiResult = status.data;
                            if (status.data.status) {
                                cons[i].uptime = getUptimeFromResults(status.data.results);
                            }
                        } else {
                            console.log('=== request disabled ===', cons[i].id);
                            cons[i].apiResult = { status: false, error: 'Disabled' };
                        }
                    } catch (error) {
                        console.log('=== request error===', error);
                        cons[i].apiResult = { status: false, error: "unknown" };
                    }
                }
                setLists({ ...contributions, contributions: cons });
            }
        }
    }

    const setInputFilter = async () => {
        try {
            const contributions = await paramGet("/api/contribution", { page: 0, rowsPerPage: 0, searchValue: '' });
            setListInputsFilter(contributions.contributions || []);
        } catch (error) {
            props.setLoading(false);
        }
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = lists.contributions.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = async (event, newPage) => {
        statusIsRunningRef.current = false;
        setTimeout(async () => {
            setPage(newPage);
            newPage--;
            if (displaySectionNav === 'outputs') {
                await getOutputs({ page: newPage, rowsPerPage, searchValue });
            } else {
                statusIsRunningRef.current = true;
                await getContributions({ page: newPage, rowsPerPage, searchValue, serverId: inputFilterServer === 'filter' ? '' : inputFilterServer, groupId: groupId === 'filter' ? '' : groupId });
            }
        }, 1500);
    };

    const handleOutputChangePage = async (event, newPage) => {
        statusOutputIsRunningRef.current = false;
        setTimeout(async () => {
            setPage(newPage);
            newPage--;
            await getOutputs({ page: newPage, rowsPerPage, searchValue })
        }, 1100);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleOutputClick = (event, name) => {
        const selectedIndex = outputSelected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(outputSelected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(outputSelected.slice(1));
        } else if (selectedIndex === outputSelected.length - 1) {
            newSelected = newSelected.concat(outputSelected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                outputSelected.slice(0, selectedIndex),
                outputSelected.slice(selectedIndex + 1),
            );
        }

        setOutputSelected(newSelected);
    };

    const handleCollapse = async (index, row) => {
        if (index === openSub) {
            return setOpenSub(-1)
        }
        props.setLoading(true);
        let res = 'vide';
        if (row.inputType !== 'Local') {
            res = await actionTail(row.id, 1, 'tail');
        }
        let allLists = lists;
        allLists.contributions[index].tailInfo = res;
        setLists(allLists);
        props.setLoading(false);
        setOpenSub(index)
    }
    const handleOutputCollapse = async (index, row) => {
        if (index === openOutputSub) {
            return setOpenOutputSub(-1)
        }
        props.setLoading(true);
        const res = await actionTail(row.id, 12, 'tail');
        let allLists = listOutputs;
        allLists.outputs[index].tailInfo = res;
        setListOutputs(allLists);
        props.setLoading(false);
        setOpenOutputSub(index);
    }

    const actionTail = async (id, type, actionType) => {
        return await paramPost('/api/contribution/actions', { id, type, actionType })
            .then(async res => {
                if (res.data.status) {
                    let results = res.data.results.split('\n').filter(el => {
                        return el != null && el !== '';
                    });
                    results = results[results.length - 1];
                    if (results.toLowerCase().match(/(^|\W)connected($|\W)/) || results.toLowerCase().match(/(^|\W)accepted($|\W)/)) {
                        return 'connected';
                    } else if (results.toLowerCase().match(/(^|\W)disconnected($|\W)/)) {
                        return "disconnected";
                    } else return "vide";
                } else {
                    return 'vide';
                }
            })
            .catch(err => {
                ToastsStore.error(err.data.error || 'Something went wrong');
                props.setLoading(false);
                return 'vide';
            })
    }

    const handleClose = val => {
        if (val) {
            window.location.href = `/dashboard/${val.server}/${val.group}/contribution/new`
        }
        setOpenModal(false)
    }

    const handleSearch = async (searchValue) => {
        setDisplaySuggests(true);
        if (displaySectionNav === 'outputs') {
            let params = { page, rowsPerPage, searchValue };
            const outputFilterInputId = localStorage.getItem('output-filter-input-id');
            if (outputFilterInputId) {
                params.contributionId = outputFilterInputId;
            }
            await suggestContribution(searchValue, 'output');
            // applyOutputFilter();
        } else if (displaySectionNav === 'inputs') {
            await suggestContribution(searchValue, 'input');
        }
    }
    const suggestContribution = async (searchValue, type) => {
        const params = { page: 0, rowsPerPage: 12, searchValue: searchValue };
        if (!searchValue || searchValue === '') {
            setSuggests([]);
            setLists({ ...lists, contributions: oldLists.contributions, total: oldLists.total });
            setListOutputs({ ...listOutputs, outputs: oldlistOutputs.outputs, total: oldlistOutputs.total });
            recheckStatusFromState('inputs');
            recheckStatusFromState('outputs');
            setDisplaySuggests(false);
            return;
        }
        if (type === 'input') {
            const inputs = await paramGet("/api/contribution", params);
            setSuggests(inputs.contributions);
        } else if (type === 'output') {
            const outputs = await paramGet("/api/contribution/outputs/list-outputs", params);
            setSuggests(outputs.outputs);
        }
    }
    const handleSearchSelect = async (row, type) => {
        type = row.contributionId ? 'output' : 'input';
        props.setLoading(true);
        if (type === 'input') {
            statusIsRunningRef.current = false;
            setTimeout(async () => {
                setLists({ ...lists, contributions: [row] });
                if (row.inputType !== 'Local') {
                    if (!row.hasOwnProperty("isEnabled")) {
                        row.isEnabled = true;
                    }
                    if (row.isEnabled) {
                        const status = await noParamGet(`/api/contribution/status/${row.id}`);
                        row.apiResult = status.data;
                        if (status.data.status) {
                            row.uptime = getUptimeFromResults(status.data.results);
                        }
                    } else {
                        row.apiResult = { status: false, error: 'Disabled' };
                    }
                }
                setLists({ ...lists, contributions: [row], total: 0 });
                props.setLoading(false);
            }, 1100);
        } else if (type === 'output') {
            statusOutputIsRunningRef.current = false;
            setTimeout(async () => {
                setListOutputs({ ...listOutputs, outputs: [row] });
                if (!row.hasOwnProperty("isEnabled")) {
                    row.isEnabled = true;
                }
                if (row.isEnabled) {
                    const status = await getOutputStatus(row.contributionId, row.name);
                    row.apiResult = status.data;
                    if (status.data.status) {
                        row.uptime = getUptimeFromResults(status.data.results);
                    }
                } else {
                    row.apiResult = { status: false, error: 'Disabled' };
                }
                setListOutputs({ ...listOutputs, outputs: [row], total: 1 });
                props.setLoading(false);
            }, 1100);
        }
    }

    const dismissToast = () => {
        const ele = document.getElementsByClassName('toasts-container');
        if (ele.length > 0) {
            // All Children
            const children = ele[0].childNodes;
            children.forEach((child) => {
                child.style.display = 'none';
            });
        }
    }

    const showSelectedOutput = (contributionId, fromToast = false) => {
        setDisplaySectionNav('outputs');
        onOutputFilter(contributionId);
        if (fromToast) {
            dismissToast();
        }
    }

    const handleRemoveContribution = async (contribution, type) => {
        const id = contribution.id;
        if (contribution.outputIds && contribution.outputIds.length > 0) {
            ToastsStore.error(<Stack direction="row" spacing={2} alignItems={"center"}>
                <Typography sx={{ color: 'white' }}> This input cannot be deleted because it has {contribution.outputIds.length} number of Outputs. </Typography>
                <Stack direction={"column"} spacing={1}>
                    <Button color="secondary" sx={{ textTransform: "none" }} variant='contained' onClick={() => showSelectedOutput(id, true)}>Show outputs</Button>
                    <Button sx={{ textTransform: "none" }} variant='contained' onClick={dismissToast}>Close</Button>
                </Stack>
            </Stack>, 7000);
            return;
        }
        let url = ""
        if (type === 1) {
            url = `/api/contribution/${id}`
        } else {
            url = `/api/contribution/output/${id}`
        }
        return await noParamDelete(url)
            .then(async res => {
                ToastsStore.success('Stream deleted successfully');
                return true;
            })
            .catch(err => {
                ToastsStore.error(err.data.error || 'Something went wrong!');
                return false;
            });
    }

    const removeContribution = async (contribution, type) => {
        const contributionId = contribution.id;
        props.showAlert('Are you sure?', 'Are you sure you want to delete?', async () => {
            const rs = await handleRemoveContribution(contribution, type);
            if (rs) {
                if (type === 1) {
                    const inputs = lists.contributions;
                    for (const [index, item] of inputs.entries()) {
                        if (item.id === contributionId) {
                            inputs.splice(index, 1);
                            break;
                        }
                    }
                    setLists({ ...lists, contributions: inputs, total: lists.total - 1 });
                } else {
                    const outputs = listOutputs.outputs;
                    for (const [index, item] of outputs.entries()) {
                        if (item.id === contributionId) {
                            outputs.splice(index, 1);
                            break;
                        }
                    }
                    setListOutputs({ ...listOutputs, outputs: outputs, total: listOutputs.total - 1 });
                }
            }
        });
    }

    const editContribution = async (con) => {
        window.location.href = `/dashboard/${con.serverId}/${con.groupId}/contribution/${con.id}`
    }

    const addOutput = async (con) => {
        window.location.href = `/dashboard/${con.serverId}/output/${con.id}`
    }

    const toggleInlineLoading = (id, display) => {
        const inlineElement = document.getElementById(`status-progress-${id}`);
        if (inlineElement) {
            inlineElement.style.display = `${display}`;
            const statusText = document.getElementById(`status-text-${id}`);
            if (display === 'block') {
                statusText.style.display = `none`;
            } else {
                statusText.style.display = `block`;
            }
        }
    }

    const handleAction = async (id, type, actionType) => {
        toggleInlineLoading(id, 'block');
        await paramPost('/api/contribution/actions', { id, type, actionType })
            .then(async res => {
                if (res.data.status) {
                    ToastsStore.success('Action worked success');
                    toggleInlineLoading(id, 'none');
                    if (type === 1) {
                        const inputs = lists.contributions;
                        for (const [index, item] of inputs.entries()) {
                            if (item.id === id) {
                                inputs[index].apiResult = res.data;
                                if (actionType === 'start' || actionType === 'restart') {
                                    inputs[index].apiResult.status = true;
                                } else {
                                    inputs[index].apiResult.status = false;
                                }
                                if (!item.hasOwnProperty("isEnabled")) {
                                    item.isEnabled = true;
                                }
                                if (item.isEnabled) {
                                    const status = await noParamGet(`/api/contribution/status/${item.id}`)
                                    inputs[index].uptime = getUptimeFromResults(status.data.results);
                                } else {
                                    inputs[index].apiResult = { status: false, error: 'Disabled' };
                                }
                                break;
                            }
                        }
                        setLists({ ...lists, contributions: inputs });

                    } else {
                        const outputs = listOutputs.outputs;
                        for (const [index, item] of outputs.entries()) {
                            if (item.id === id) {
                                outputs[index].apiResult = res.data;
                                if (actionType === 'start' || actionType === 'restart') {
                                    outputs[index].apiResult.status = true;
                                } else {
                                    outputs[index].apiResult.status = false;
                                }
                                if (!item.hasOwnProperty("isEnabled")) {
                                    item.isEnabled = true;
                                }
                                if (item.isEnabled) {
                                    const status = await getOutputStatus(item.contributionId, item.name);
                                    outputs[index].uptime = getUptimeFromResults(status.data.results);
                                } else {
                                    outputs[index].apiResult = { status: false, error: 'Disabled' };
                                }
                                break;
                            }
                        }
                        setListOutputs({ ...listOutputs, outputs: outputs });
                    }
                } else {
                    ToastsStore.error(res.data.error || 'Something went wrong');
                }
            })
            .catch(err => {
                ToastsStore.error(err.data.error || 'Something went wrong');
            });
        toggleInlineLoading(id, 'none');
    }

    const handleOutputEdit = (value) => {
        const val = listInputsFilter.find(el => el.id === value.contributionId);
        if (val) {
            props.history.push(`/dashboard/${val.serverId}/output_edit/${value.id}`)
        }
    }

    const handleOutputActionChange = async (values) => {
        const { id, type, actionType } = values;
        await handleAction(id, type, actionType);
    }

    const handleOutputDelete = async (value) => {
        await removeContribution(value.contribution, value.type)
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const isOutputSelected = (name) => outputSelected.indexOf(name) !== -1;

    const onInputFilterByServer = async (value) => {
        // Cancel all requests
        inputCancelList.forEach(source => {
            source.cancel();
        });
        setInputCancelList([]);
        //
        statusIsRunningRef.current = false;
        setInputFilterServer(value);
        const realGroupId = groupId === 'filter' ? '' : groupId;
        setTimeout(async () => {
            statusIsRunningRef.current = true;
            if (value && value !== 'filter') {
                localStorage.setItem('server-id', value);
                props.history.push({ pathname: "/dashboard/contribution", state: { displayOutput: false } });
                await getContributions({ page: 0, rowsPerPage: 12, searchValue: '', serverId: value, groupId: realGroupId, reloadPage: true });
            } else {
                localStorage.removeItem('server-id');
                await getContributions({ page, rowsPerPage, searchValue, groupId: realGroupId });
            }
        }, 1100)
    }

    const handleSelectedGroupChange = async (value) => {
        setGroupId(value);
        const serverId = inputFilterServer === 'filter' ? '' : inputFilterServer;
        // Cancel all requests
        inputCancelList.forEach(source => {
            source.cancel();
        });
        setInputCancelList([]);
        //
        if (value && value !== 'filter') {
            localStorage.setItem('group-id', value);
            if (displaySectionNav === 'outputs') {
                props.history.push({ pathname: "/dashboard/contribution", state: { displayOutput: true } });
                await getContributions({ page: 0, rowsPerPage, searchValue, serverId, groupId: value });
            } else if (displaySectionNav === 'inputs') {
                props.history.push({ pathname: "/dashboard/contribution", state: { displayOutput: false } });
                await getContributions({ page: 0, rowsPerPage, searchValue, serverId, groupId: value });
            } else {
                return;
            }
        } else {
            localStorage.removeItem('group-id');
            await getContributions({ page, rowsPerPage, searchValue, serverId });
        }
    }

    /**
     * Outputs sections
     */
    const getOutputs = async (params) => {
        try {
            props.setLoading(true);
            const outputs = await paramGet("/api/contribution/outputs/list-outputs", params);
            setListOutputs(outputs);
            if (!params.contributionId && !params.destinationId) {
                setOldListOutputs(outputs);
            }
            props.setLoading(false);
            setListOutputs(outputs);
            await getAllOutputStatus(params, outputs);
            setInputFilter();
        } catch (error) {
            setInputFilter();
            props.setLoading(false);
        }
    }

    const getAllOutputStatus = async (params, outputs) => {
        if (outputs.outputs.length) {
            let outs = outputs.outputs;
            setListOutputs({ ...outputs, outputs: outs });
            statusOutputIsRunningRef.current = true;
            await Promise.all(outs.map(async (item, index) => {
                if (statusOutputIsRunningRef.current === false) {
                    if (!params.contributionId && !params.destinationId) {
                        setOldListOutputs(outputs);
                    }
                    return;
                }
                try {
                    if (!item.hasOwnProperty("isEnabled")) {
                        item.isEnabled = true;
                    }
                    if (item.isEnabled) {
                        const status = await getOutputStatus(item.contributionId, item.name);
                        outs[index].apiResult = status.data;
                        if (status.data.status) {
                            outs[index].uptime = getUptimeFromResults(status.data.results);
                        }
                    } else {
                        outs[index].apiResult = { status: false, error: 'Disabled' };
                    }
                } catch (error) {
                    outs[index].apiResult = { status: false, error: "unknown" };
                }
            }));
            const listLen = listOutputs.outputs.length;
            const outLen = outputs.outputs.length;
            if (listLen === outLen && outputs.outputs[outLen - 1].id === listOutputs.outputs[listLen - 1].id) {
                setListOutputs({ ...outputs, outputs: outs });
            }
        }
    }

    const getOutputStatus = async (contributionId, name) => {
        const source = axios.CancelToken.source();
        const newOutputCancelList = outputCancelList;
        newOutputCancelList.push(source);
        setOutputCancelList(newOutputCancelList);
        return await noParamGet(`/api/contribution/output/status-by-name/${contributionId}/${name}`, AuthHeader().headers, source.token);
    }

    const onChangeTableDisplay = async (type) => {
        setSearchValue("");
        setOutputFilter('filter');
        if (type === 1) {
            // Cancel requests
            inputCancelList.forEach(source => {
                source.cancel();
            });
            setInputCancelList([]);
            //
            setDisplaySectionNav('outputs');
            const outputFilterInputId = localStorage.getItem('output-filter-input-id');
            const outFilterDesti = localStorage.getItem('output-filter-destination-id');
            if (outFilterDesti) {
                setOutputDestinationFilter(outFilterDesti);
            }
            if (outputFilterInputId || outFilterDesti) {
                applyOutputFilter();
            } else {
                if (oldlistOutputs.outputs.length > 0) {
                    setOldListOutputs(oldlistOutputs);
                } else {
                    setPage(0);
                    getOutputs({ page, rowsPerPage, searchValue });
                }
            }
        } else if (type === 0) {
            // Cancel requests
            setDisplaySectionNav('inputs')
            outputCancelList.forEach(source => {
                source.cancel();
            });
            setOutputCancelList([]);
            //
            getContributions({ page, rowsPerPage, searchValue, serverId: inputFilterServer === 'filter' ? '' : inputFilterServer, groupId: groupId === 'filter' ? '' : groupId });
            // if (oldLists.contributions.length > 0) {
            //     setLists(oldLists);
            // } else {
            //     setPage(0);
            //     getContributions({ page, rowsPerPage, searchValue, serverId: inputFilterServer === 'filter' ? '' : inputFilterServer, groupId: groupId === 'filter' ? '' : groupId });
            // }
        } else if (type === 2) {
            inputCancelList.forEach(source => {
                source.cancel();
            });
            outputCancelList.forEach(source => {
                source.cancel();
            });
            setInputCancelList([]);
            setOutputCancelList([]);
            setDisplaySectionNav('destinations');
        }
    }
    const applyOutputFilter = () => {
        const outputFilterInputId = localStorage.getItem('output-filter-input-id');
        const outputFilterDestiId = localStorage.getItem('output-filter-destination-id');
        if (outputFilterInputId) {
            onOutputFilter(outputFilterInputId);
        } else if (outputFilterDestiId) {
            onOutputDestinationFilter(outputFilterDestiId);
        }
    }

    const handleOutputSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = listOutputs.outputs.map((n) => n.name);
            setOutputSelected(newSelecteds);
            return;
        }
        setOutputSelected([]);
    };

    const onOutputFilter = async (value) => {
        // Cancel all requests
        outputCancelList.forEach(source => {
            source.cancel();
        });
        setOutputCancelList([]);
        //
        statusOutputIsRunningRef.current = false;
        setOutputFilter(value);
        const outDestiFilter = localStorage.getItem('output-filter-destination-id');
        if (value && value !== 'filter') {
            localStorage.setItem('output-filter-input-id', value);
            if (outDestiFilter) {
                await getOutputs({
                    page: 0,
                    rowsPerPage: 12,
                    searchValue: '',
                    contributionId: value,
                    destinationId: outDestiFilter
                });
            } else {
                await getOutputs({ page: 0, rowsPerPage: 12, searchValue: '', contributionId: value });
            }
        } else {
            localStorage.removeItem('reload-output-list-after-filter')
            localStorage.removeItem('output-filter-input-id');
            recheckStatusFromState('outputs');
            if (oldlistOutputs.outputs.length > 0 && !outDestiFilter) {
                setListOutputs(oldlistOutputs);
            } else {
                if (outDestiFilter) {
                    await getOutputs({ page, rowsPerPage, searchValue, destinationId: outDestiFilter });
                } else {
                    await getOutputs({ page, rowsPerPage, searchValue });
                }
            }
        }
    }

    const onOutputDestinationFilter = async (value) => {
        // Cancel all requests
        outputCancelList.forEach(source => {
            source.cancel();
        });
        setOutputCancelList([]);
        //
        statusOutputIsRunningRef.current = false;
        setOutputDestinationFilter(value);
        const inputFilter = localStorage.getItem('output-filter-input-id');
        if (value && value !== 'filter') {
            localStorage.setItem('output-filter-destination-id', value);
            if (inputFilter) {
                await getOutputs({
                    page: 0,
                    rowsPerPage: 12,
                    searchValue: '',
                    contributionId: inputFilter,
                    destinationId: value
                });
            } else {
                await getOutputs({ page: 0, rowsPerPage: 12, searchValue: '', destinationId: value });
            }
        } else {
            localStorage.removeItem('output-filter-destination-id');
            localStorage.removeItem('reload-output-list-after-destination-filter');
            recheckStatusFromState('outputs');
            if (oldlistOutputs.outputs.length > 0 && !inputFilter) {
                setListOutputs(oldlistOutputs);
            } else {
                if (inputFilter) {
                    await getOutputs({ page, rowsPerPage, searchValue, contributionId: inputFilter });
                } else {
                    await getOutputs({ page, rowsPerPage, searchValue });
                }
            }
        }
    }

    const handleCloseContriModal = () => {
        setOpenContributionModal(false);
    }

    const handleUpdateStateAfterBulkDelete = async (selected, type) => {
        if (type === 1) {
            let contributions = lists.contributions;
            for (const element of selected) {
                for (const [index, item] of contributions.entries()) {
                    if (item && item.name === element) contributions.splice(index, 1);
                }
            }
            setLists({ ...lists, contributions, total: lists.total - selected.length });
            setSelected([]);
        } else if (type === 2) {
            let outputs = listOutputs.outputs;
            for (const element of selected) {
                for (const [index, item] of outputs.entries()) {
                    if (item && item.name === element) outputs.splice(index, 1);
                }
            }
            setListOutputs({ ...listOutputs, outputs, total: listOutputs.total - selected.length });
            setOutputSelected([]);
        } else if (type === 3) {
            refresh();
        }
    }

    const closeSuggest = () => {
        setDisplaySuggests(false);
    }

    /** DESTINATIONS SECTION **/

    const onCloseDestinationModal = () => {
        setOpenDestinationModal(false);
    }

    const onOpenDestinationModal = (type) => {
        setOpenDestinationModal(true);
        if (type === 'create') {
            setTypeDestinationModal('create');
        } else if (type === 'edit') {
            setTypeDestinationModal('edit');
        }
    }

    const refreshAfterBulkAsignDestination = async () => {
        await getOutputs({ page, rowsPerPage, searchValue });
    }

    const getAllDestinations = async () => {
        const destinations = await paramGet("/api/contribution/destinations/lists", {
            page: 0,
            rowsPerPage: 0,
            searchValue: ''
        });
        setAllDestination(destinations.destinations);
    }

    useEffect(() => {
        getInitialValues();
        setInputFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage])

    useEffect(() => {
        return () => {
            inputCancelList.forEach(source => {
                source.cancel();
            });
            outputCancelList.forEach(source => {
                source.cancel();
            });
        }
    }, [inputCancelList, outputCancelList]);

    return (
        <div className={classes.root}>
            <Grid container className={classes.gridContainer}>
                <Grid item xs={12} className={classes.miniNavbar}>
                    <Button variant="contained" color="primary" onClick={() => onChangeTableDisplay(0)}
                        className={displaySectionNav === 'inputs' ? classes.miniNavButtonActive : classes.miniNavButton}>INPUTS
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => onChangeTableDisplay(1)}
                        className={displaySectionNav === 'outputs' ? classes.miniNavButtonActive : classes.miniNavButton}>Outputs
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => onChangeTableDisplay(2)}
                        className={displaySectionNav === 'destinations' ? classes.miniNavButtonActive : classes.miniNavButton}>Destinations
                    </Button>
                </Grid>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} md={2} className='search-bar' sx={{ my: 1, mr: { xs: 0, sm: 1 } }}>
                        <SearchTextBar onChange={handleSearch} />
                        <SearchSuggest suggests={suggests} handleSearchSelect={handleSearchSelect}
                            type={displaySectionNav === 'outputs' ? 'output' : displaySectionNav === 'inputs' ? 'input' : 'destination'}
                            display={displaySuggests}
                            closeSuggest={closeSuggest} />
                        {isSearching ? <LinearProgress /> : ''}
                    </Grid>
                    {/* Filter by group */}
                    {(displaySectionNav === 'inputs') && (
                        <Grid item xs={12} md={2} sx={{ my: 1, mx: { xs: 0, sm: 1 } }}>
                            <TextField
                                style={{ background: '#5a97fa' }}
                                inputProps={{ className: classes.outputFilter }}
                                fullWidth
                                select
                                size='small'
                                value={groupId}
                                onChange={(e) => handleSelectedGroupChange(e.target.value)}>
                                <MenuItem value={'filter'} key="group-filter">
                                    By Group
                                </MenuItem>
                                {
                                    groups.map((child, index) =>
                                        <MenuItem value={child.id || ''} key={child.id || ''}>
                                            {child.title || ''}
                                        </MenuItem>
                                    )
                                }
                            </TextField>
                        </Grid>
                    )}
                    {displaySectionNav === 'outputs' ? (
                        <>
                            <Grid item xs={12} md={2} sx={{ my: 1, mx: { xs: 0, sm: 1 } }}>
                                <TextField
                                    sx={{ bgcolor: '#5a97fa' }}
                                    inputProps={{ className: classes.outputFilter }}
                                    fullWidth
                                    size='small'
                                    select
                                    value={outputFilter}
                                    onChange={(e) => {
                                        // localStorage.setItem('reload-output-list-after-filter', 'true');
                                        onOutputFilter(e.target.value)
                                    }}>
                                    <MenuItem value={'filter'} key="filter">
                                        By Input
                                    </MenuItem>
                                    {
                                        listInputsFilter.map((child, index) =>
                                            <MenuItem value={child.id || ''} key={child.id || ''}>
                                                {child.title || ''}
                                            </MenuItem>
                                        )
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={2} sx={{ my: 1, mx: { xs: 0, sm: 1 } }}>
                                <TextField
                                    style={{ background: '#5a97fa' }}
                                    inputProps={{ className: classes.outputFilter }}
                                    fullWidth
                                    select
                                    size='small'
                                    value={outputDestinationFilter}
                                    onChange={(e) => {
                                        // localStorage.setItem('reload-output-list-after-destination-filter', 'true');
                                        onOutputDestinationFilter(e.target.value);
                                    }}>
                                    <MenuItem value={'filter'} key="filter">
                                        By Destination
                                    </MenuItem>
                                    {
                                        allDestination.map((child, index) =>
                                            <MenuItem value={child.id || ''} key={child.id || ''}>
                                                {child.title || ''}
                                            </MenuItem>
                                        )
                                    }
                                </TextField>
                            </Grid>
                        </>
                    ) : displaySectionNav === 'inputs' ? (
                        <Grid item xs={12} md={2} sx={{ my: 1, mx: { xs: 0, sm: 1 } }}>
                            <TextField
                                style={{ background: '#5a97fa' }}
                                inputProps={{ className: classes.outputFilter }}
                                fullWidth
                                select
                                size='small'
                                value={inputFilterServer}
                                onChange={(e) => onInputFilterByServer(e.target.value)}>
                                <MenuItem value={'filter'} key="filter">
                                    By Server
                                </MenuItem>
                                {
                                    servers.map((child, index) =>
                                        <MenuItem value={child.id || ''} key={child.id || ''}>
                                            {child.title || ''}
                                        </MenuItem>
                                    )
                                }
                            </TextField>
                        </Grid>
                    ) : ''}
                    {displaySectionNav === 'inputs' ? (
                        <Grid item xs={12} md={5} className="search-bar">
                            <div className={classes.newButton}>
                                <Button variant="outlined" style={{ marginRight: '20px' }}
                                    onClick={() => refresh()}>
                                    <AutorenewIcon />
                                </Button>
                                <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
                                    Add Input
                                </Button>
                            </div>
                        </Grid>
                    )
                        :
                        displaySectionNav === 'outputs' ?
                            <Grid item xs={12} md={5} className="search-bar">
                                <div className={classes.newButton}>
                                    <Button variant="outlined" style={{ marginRight: '20px' }}
                                        onClick={() => refresh()}>
                                        <AutorenewIcon />
                                    </Button>
                                    <Button variant="contained" color="primary"
                                        onClick={() => setOpenContributionModal(true)}>
                                        Add Output
                                    </Button>
                                </div>
                            </Grid>
                            :
                            <Grid item xs={12} md={8} className="search-bar">
                                <div className={classes.newButton}>
                                    <Button variant="outlined" style={{ marginRight: '20px' }}
                                        onClick={() => refresh()}><AutorenewIcon /></Button>
                                    <Button variant="contained" color="primary"
                                        onClick={() => onOpenDestinationModal('create')}>
                                        Add Destination
                                    </Button>
                                </div>
                            </Grid>
                    }
                </Grid>
                {displaySectionNav === 'outputs' ? (
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <EnhancedTableToolbar numSelected={outputSelected.length} selected={outputSelected}
                                isOutput={true} servers={servers} getContributions={getContributions}
                                contributions={lists.contributions}
                                bulkDeleteRefresh={handleUpdateStateAfterBulkDelete}
                                destinations={allDestination}
                                refreshAfterBulkAsignDestination={refreshAfterBulkAsignDestination}
                                getAllDestinations={getAllDestinations}
                            />
                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="outPutTableTitle"
                                    size={'medium'}
                                    aria-label="output enhanced table"
                                >
                                    <EnhancedTableHead
                                        classes={classes}
                                        numSelected={outputSelected.length}
                                        onSelectAllClick={handleOutputSelectAllClick}
                                        rowCount={listOutputs.outputs.length}
                                        headerList={mainOutputHeader}
                                        isSubHeader={false}
                                    />
                                    <TableBody>
                                        {listOutputs.outputs.length ? listOutputs.outputs.map((row, index) => {
                                            const isItemSelected = isOutputSelected(row.name);
                                            const labelId = `output-enhanced-table-checkbox-${index}`;
                                            const apiResult = row.apiResult || null;

                                            return (
                                                <React.Fragment key={'out-' + row.id}>
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell>
                                                            <IconButton aria-label="expand row" size="small"
                                                                onClick={() => handleOutputCollapse(index, row)}>
                                                                {openOutputSub === index ? <KeyboardArrowUpIcon /> :
                                                                    <KeyboardArrowDownIcon />}
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={isItemSelected}
                                                                onClick={(event) => handleOutputClick(event, row.name)}
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                            />
                                                        </TableCell>
                                                        <TableCell id={labelId} scope="row" padding="none"
                                                            className={row && row.apiResult && row.apiResult.status ? 'color-green' : 'color-danger'}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <Typography id={`status-text-${row.id}`}
                                                                className={classes.mb18}>
                                                                {/*{row.apiResult ? row.apiResult.status ?*/}
                                                                {/*        "Online" :*/}
                                                                {/*        "Offline" :*/}
                                                                {/*    <LinearProgress size="sm"*/}
                                                                {/*                    className={classes.mb18}/>}*/}


                                                                {apiResult && apiResult.status ? "Running" :
                                                                    apiResult && !apiResult.status && (!apiResult.error || apiResult.error === 'exit status 3') ? 'Stopped' :
                                                                        apiResult && !apiResult.status && apiResult.error === 'exit status 4' ? "Offline" :
                                                                            apiResult && !apiResult.status && apiResult.error === 'unknown' ? "Error" :
                                                                                apiResult && !apiResult.status && apiResult.error === 'Disabled' ? "Disabled" :
                                                                                    <LinearProgress size="sm" />}
                                                            </Typography>
                                                            {/* <LinearProgress size="sm" className={`d-none`}
                                                                style={{
                                                                    marginBottom: '18px',
                                                                }} id={`status-progress-${row.id}`} /> */}
                                                        </TableCell>
                                                        <TableCell align="left"
                                                            onDoubleClick={(e) => inlineEditOutputOnDoubleClick(row)}>
                                                            <span id={'span-output-title' + row.id}
                                                                className='span-output-title'>
                                                                {row.title}
                                                            </span>
                                                            <div style={{
                                                                display: "flex",
                                                                flexWrap: "wrap",
                                                                alignItems: "center"
                                                            }}>
                                                                <div style={{ width: '60%' }}>
                                                                    <input
                                                                        type="text"
                                                                        className='inline-edit-output'
                                                                        value={inlineEditOutput}
                                                                        id={row.id}
                                                                        style={{
                                                                            display: 'none',
                                                                            width: '90%',
                                                                            height: '30px'
                                                                        }}
                                                                        onChange={(e) => inlineEditOutputOnChange(e)}
                                                                    />
                                                                </div>
                                                                <div style={{
                                                                    display: "none",
                                                                    justifyContent: "space-between",
                                                                }}
                                                                    id={'inline-edit-output-action-btn-' + row.id}
                                                                    className='inline-edit-output-action-btn'>
                                                                    <IconButton color='secondary'
                                                                        onClick={(e) => onSubmitInlineEditTypeOutput(row)}>
                                                                        <Done />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        onClick={(e) => inlineEditOutputOnCancel(row)}>
                                                                        <CancelIcon />
                                                                    </IconButton>
                                                                    {/*<Button variant="contained"*/}
                                                                    {/*        onClick={(e) => onSubmitInlineEditTypeOutput(row)}>Modifier</Button>*/}
                                                                    {/*<Button variant="text"*/}
                                                                    {/*        onClick={(e) => inlineEditOutputOnCancel(row)}>Annuler</Button>*/}
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell
                                                            align="left">
                                                            {row.contributionInfo ? row.contributionInfo.title : ''}
                                                            <br />
                                                            <Typography
                                                                style={{ fontSize: '12px' }}>{row.contributionInfo ? row.contributionInfo.config.multicast : ''}</Typography>
                                                        </TableCell>
                                                        <TableCell
                                                            align="left">
                                                            {row.config.mode === 'Push' ? row.config.host + `:${row.config.port}` : 'Listen'}
                                                        </TableCell>
                                                        <TableCell
                                                            align="left">{row.uptime ? row.uptime : getStatusTextFromApiResult(apiResult)}</TableCell>
                                                        <TableCell align="left">
                                                            {row.apiResult ? row.apiResult.status ? (
                                                                <>
                                                                    <Tooltip title="Stop" className='color-danger'>
                                                                        <IconButton
                                                                            onClick={() => handleOutputActionChange({
                                                                                id: row.id,
                                                                                type: 2,
                                                                                actionType: "stop"
                                                                            })}>
                                                                            <StopIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Restart">
                                                                        <IconButton color="secondary"
                                                                            onClick={() => handleOutputActionChange({
                                                                                id: row.id,
                                                                                type: 2,
                                                                                actionType: "restart"
                                                                            })}>
                                                                            <ReplayIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Tooltip title="Start">
                                                                        <IconButton color="secondary"
                                                                            onClick={() => handleOutputActionChange({
                                                                                id: row.id,
                                                                                type: 2,
                                                                                actionType: "start"
                                                                            })}>
                                                                            <PlayArrowIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Restart">
                                                                        <IconButton color="disabled"
                                                                            onClick={() => console.log("OFFLINE")}>
                                                                            <ReplayIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Tooltip title="Start">
                                                                        <IconButton color="disabled"
                                                                            onClick={() => console.log("Waiting for load")}>
                                                                            <PlayArrowIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Restart">
                                                                        <IconButton color="disabled"
                                                                            onClick={() => console.log("Waiting for load")}>
                                                                            <ReplayIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>
                                                            )}
                                                            <Tooltip title="Edit">
                                                                <IconButton color="primary"
                                                                    onClick={() => handleOutputEdit(row)}>
                                                                    <Icon>edit</Icon>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete">
                                                                <IconButton className='color-danger'
                                                                    onClick={() => handleOutputDelete({
                                                                        contribution: row,
                                                                        type: 2
                                                                    })}>
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}
                                                            colSpan={8}>
                                                            <Collapse in={openOutputSub === index} timeout="auto"
                                                                unmountOnExit>
                                                                {row.tailInfo && row.tailInfo.length ? (
                                                                    <TableRow style={{ height: 53 }}>
                                                                        <TableCell colSpan={8}>
                                                                            <Typography
                                                                                className={
                                                                                    row.tailInfo && row.tailInfo === 'connected' ? 'color-green' :
                                                                                        row.tailInfo && row.tailInfo === 'disconnected' ? 'color-danger' :
                                                                                            ''
                                                                                }>
                                                                                {
                                                                                    (row.tailInfo && row.tailInfo) === 'connected' ?
                                                                                        "TARGET STATUS: CONNECTED" :
                                                                                        (row.tailInfo && row.tailInfo) === 'disconnected' ?
                                                                                            "TARGET STATUS: DISCONNECTED" :
                                                                                            "TARGET STATUS:"
                                                                                }
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ) : (
                                                                    <TableRow style={{ height: 53 }}>
                                                                        <TableCell colSpan={8} />
                                                                    </TableRow>
                                                                )}
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                </React.Fragment>
                                            );
                                        }) : (
                                            <TableRow style={{ height: 53 }}>
                                                <TableCell colSpan={8} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box my={2} display="flex" justifyContent="right" alignItems='center'>
                                <Box>Rows per page: &nbsp;&nbsp;
                                    <Select
                                        labelId="output-rows-per-page"
                                        value={rowsPerPage}
                                        label="Rows per page"
                                        onChange={(e) => onRowPerPageChange(e, 2)}
                                    >
                                        <MenuItem value={12}>12</MenuItem>
                                        <MenuItem value={24}>24</MenuItem>
                                        <MenuItem value={36}>36</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={75}>75</MenuItem>
                                        <MenuItem value={0}>All</MenuItem>
                                    </Select>
                                </Box>
                                &nbsp;&nbsp;
                                <Pagination
                                    color="primary"
                                    count={rowsPerPage > 0 ? Math.ceil(listOutputs.total / rowsPerPage) : Math.ceil(listOutputs.total / listOutputs.total)}
                                    page={page > 0 ? page : 1}
                                    defaultPage={1}
                                    onChange={handleOutputChangePage}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                ) :
                    displaySectionNav === 'inputs' ? (
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <EnhancedTableToolbar numSelected={selected.length} selected={selected} isInput={true}
                                    servers={servers} getContributions={getContributions}
                                    contributions={lists.contributions}
                                    bulkDeleteRefresh={handleUpdateStateAfterBulkDelete} />
                                <TableContainer>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        aria-label="enhanced table"
                                    >
                                        <EnhancedTableHead
                                            classes={classes}
                                            numSelected={selected.length}
                                            onSelectAllClick={handleSelectAllClick}
                                            rowCount={lists.contributions.length}
                                            headerList={mainHeader}
                                            isSubHeader={false}
                                        />
                                        <TableBody>
                                            {lists.contributions.length ? lists.contributions.map((row, index) => {
                                                const isItemSelected = isSelected(row.name);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                const apiResult = row.apiResult || null;

                                                return (
                                                    <React.Fragment key={row.id}>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            selected={isItemSelected}
                                                        >
                                                            <TableCell>
                                                                <IconButton aria-label="expand row" size="small"
                                                                    onClick={() => handleCollapse(index, row)}>
                                                                    {openSub === index ? <KeyboardArrowUpIcon /> :
                                                                        <KeyboardArrowDownIcon />}
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    onClick={(event) => handleClick(event, row.name)}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </TableCell>
                                                            <TableCell scope="row" padding="none" id={labelId}>
                                                                {
                                                                    row.inputType === 'Local' ? 'Local' :
                                                                        <>
                                                                            <Typography id={`status-text-${row.id}`}
                                                                                className={row.apiResult && row.apiResult.status ? 'color-green' : 'color-danger'}>
                                                                                {/*{row.apiResult ? row.apiResult.status ? "Online" : "Offline" :*/}
                                                                                {/*    <LinearProgress size="sm"/>}*/}

                                                                                {apiResult && apiResult.status ? "Running" :
                                                                                    apiResult && !apiResult.status && (!apiResult.error || apiResult.error === 'exit status 3') ? 'Stopped' :
                                                                                        apiResult && !apiResult.status && apiResult.error === 'exit status 4' ? "Offline" :
                                                                                            apiResult && !apiResult.status && apiResult.error === 'unknown' ? "Error" :
                                                                                                apiResult && !apiResult.status && apiResult.error === 'Disabled' ? "Disabled" :
                                                                                                    <LinearProgress size="sm" />}
                                                                            </Typography>

                                                                            {/* <LinearProgress size="sm"
                                                                                className={`d-none`}
                                                                                id={`status-progress-${row.id}`} /> */}
                                                                        </>
                                                                }
                                                            </TableCell>
                                                            <TableCell align="left"
                                                                onDoubleClick={(e) => inlineEditInputOnDoubleClick(row)}>
                                                                <span id={'span-input-title' + row.id}
                                                                    className='span-input-title'>
                                                                    {row.title} <br />
                                                                    <span
                                                                        style={{ fontSize: '12px' }}>{row.config.multicast}</span> <br />
                                                                    <Typography sx={{ cursor: 'pointer' }} onClick={() => showSelectedOutput(row.id)}><b>{row.outputIds.length || 0} Outputs</b></Typography>
                                                                </span>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexWrap: "wrap",
                                                                    alignItems: "center"
                                                                }}>
                                                                    <div style={{ width: '60%' }}>
                                                                        <input
                                                                            type="text"
                                                                            className='inline-edit-input'
                                                                            value={inlineEditInput}
                                                                            id={row.id}
                                                                            style={{
                                                                                display: 'none',
                                                                                width: '90%',
                                                                                height: '30px'
                                                                            }}
                                                                            onChange={(e) => inlineEditInputOnChange(e)}
                                                                        />
                                                                    </div>
                                                                    <div style={{
                                                                        display: "none",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                        id={'inline-edit-input-action-btn-' + row.id}
                                                                        className='inline-edit-input-action-btn'>
                                                                        <IconButton color='secondary'
                                                                            onClick={(e) => onSubmitInlineEditTypeInput(row)}>
                                                                            <Done />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={(e) => inlineEditInputOnCancel(row)}>
                                                                            <CancelIcon />
                                                                        </IconButton>
                                                                        {/*<Button variant="contained"*/}
                                                                        {/*        onClick={(e) => onSubmitInlineEditTypeInput(row)}>Modifier</Button>*/}
                                                                        {/*<Button variant="text"*/}
                                                                        {/*        onClick={(e) => inlineEditInputOnCancel(row)}>Annuler</Button>*/}
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell align="left">{row.serverInfo.title}</TableCell>
                                                            <TableCell align="left">{row.config.port}</TableCell>
                                                            <TableCell
                                                                align="left">{row.uptime ? row.uptime : getStatusTextFromApiResult(apiResult)}</TableCell>
                                                            <TableCell align="left">
                                                                {row.apiResult ? row.apiResult.status ? (
                                                                    <>
                                                                        <Tooltip title="Stop" className='color-danger'>
                                                                            <IconButton
                                                                                onClick={() => handleAction(row.id, 1, "stop")}>
                                                                                <StopIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Restart">
                                                                            <IconButton color="secondary"
                                                                                onClick={() => handleAction(row.id, 1, "restart")}>
                                                                                <ReplayIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Tooltip title="Start">
                                                                            <IconButton color="secondary"
                                                                                onClick={() => handleAction(row.id, 1, "start")}>
                                                                                <PlayArrowIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Restart">
                                                                            <IconButton color="disabled"
                                                                                onClick={() => console.log("OFFLINE")}>
                                                                                <ReplayIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Tooltip title="Start">
                                                                            <IconButton color="disabled"
                                                                                onClick={() => console.log("Waiting for load")}>
                                                                                <PlayArrowIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title="Restart">
                                                                            <IconButton color="disabled"
                                                                                onClick={() => console.log("Waiting for load")}>
                                                                                <ReplayIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </>
                                                                )}
                                                                <Tooltip title="Edit">
                                                                    <IconButton color="primary"
                                                                        onClick={() => editContribution(row)}>
                                                                        <Icon>edit</Icon>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Delete">
                                                                    <IconButton
                                                                        onClick={() => removeContribution(row, 1)}
                                                                        className='color-danger'>
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Add new output">
                                                                    <IconButton color="primary"
                                                                        onClick={() => addOutput(row)}>
                                                                        <AddCircleIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}
                                                                colSpan={8}>
                                                                <Collapse in={openSub === index} timeout="auto"
                                                                    unmountOnExit>
                                                                    {row.tailInfo && row.tailInfo.length ? (
                                                                        <TableRow style={{ height: 53 }}>
                                                                            <TableCell colSpan={8}>
                                                                                {row.inputType === 'Local' ?
                                                                                    'TARGET STATUS: Source multicast' :
                                                                                    <Typography
                                                                                        className={
                                                                                            row.tailInfo && row.tailInfo === 'connected' ? 'color-green' :
                                                                                                row.tailInfo && row.tailInfo === 'disconnected' ? 'color-danger' :
                                                                                                    ''
                                                                                        }>
                                                                                        {
                                                                                            (row.tailInfo && row.tailInfo) === 'connected' ?
                                                                                                "TARGET STATUS: CONNECTED" :
                                                                                                (row.tailInfo && row.tailInfo) === 'disconnected' ?
                                                                                                    "TARGET STATUS: DISCONNECTED" :
                                                                                                    "TARGET STATUS:"
                                                                                        }
                                                                                    </Typography>
                                                                                }
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ) : (
                                                                        <TableRow style={{ height: 53 }}>
                                                                            <TableCell colSpan={8} />
                                                                        </TableRow>
                                                                    )}
                                                                </Collapse>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                );
                                            }) : (
                                                <TableRow style={{ height: 53 }}>
                                                    <TableCell colSpan={8} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box my={2} display="flex" justifyContent="right" alignItems='center'>
                                    <Box>Rows per page: &nbsp;&nbsp;
                                        <Select
                                            labelId="input-rows-per-page"
                                            value={rowsPerPage}
                                            label="Rows per page"
                                            onChange={(e) => onRowPerPageChange(e, 1)}
                                        >
                                            <MenuItem value={12}>12</MenuItem>
                                            <MenuItem value={24}>24</MenuItem>
                                            <MenuItem value={36}>36</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                            <MenuItem value={75}>75</MenuItem>
                                            <MenuItem value={0}>All</MenuItem>
                                        </Select>
                                    </Box>
                                    &nbsp;&nbsp;
                                    <Pagination
                                        color="primary"
                                        count={rowsPerPage > 0 ? Math.ceil(lists.total / rowsPerPage) : Math.ceil(lists.total / lists.total)}
                                        page={page > 0 ? page : 1}
                                        defaultPage={1}
                                        onChange={handleChangePage}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    ) : (
                        <Destination getContributions={getContributions} contributions={lists.contributions}
                            showAlert={props.showAlert} setLoading={props.setLoading}
                            openModal={openDestinationModal} onClose={onCloseDestinationModal}
                            addingModal={typeDestinationModal} servers={servers} groups={groups}
                            onOpenDestinationModal={onOpenDestinationModal} />
                    )}
            </Grid>
            <ServerSelectingDialog open={openModal} onClose={handleClose} servers={servers} groups={groups} />
            <ContributionSelectingDialog open={openContributionModal} onClose={handleCloseContriModal}
                addOutput={addOutput} contributions={listInputsFilter} />
        </div>
    );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { showAlert, setLoading }

export default connect(mapStateToProps, mapDispatchToProps)(Contribution)

import axios from 'axios'
import AuthHeader from './AuthHeader'

const noParamPost = async (url, headers = AuthHeader().headers) => {
  const config = {
    method: 'post',
    url: url,
    headers,
  }
  return axios(config).then(response => {
    return response.data;
  }).catch(err => {
    throw err.response
  });
}

const paramPost = async (url, data, headers = AuthHeader().headers) => {
  const config = {
    method: 'post',
    url: url,
    headers,
    data: data
  }
  return axios(config).then(response => {
    return response;
  }).catch(err => {
    throw err.response
  });
}

const noParamGet = async (url, headers = AuthHeader().headers, cancelToken = null) => {
  const config = {
    method: 'get',
    url: url,
    headers
  }
  if (cancelToken) {
    config.cancelToken = cancelToken;
  }
  return axios(config).then(response => {
    return response;
  }).catch(err => {
    throw err.response
  });
}

const paramGet = async (url, params) => {
  const config = {
    method: 'get',
    url: url,
    headers: AuthHeader().headers,
    params
  }
  return axios(config).then(response => {
    return response.data;
  }).catch(err => {
    throw err.response
  });
}

const paramPut = async (url, data) => {
  const config = {
    method: 'put',
    url: url,
    headers: AuthHeader().headers,
    data: data
  }
  return axios(config).then(response => {
    return response;
  }).catch(err => {
    throw err.response
  });
}

const noParamDelete = (url) => {
  const config = {
    method: 'delete',
    url: url,
    headers: AuthHeader().headers,
  }
  return axios(config).then(response => {
    return response;
  }).catch(err => {
    throw err.response
  });
}

export {noParamPost, paramPost, noParamGet, paramGet, paramPut, noParamDelete}

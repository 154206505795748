import React from "react";
import IconButton from "@mui/material/IconButton";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import AddIcon from '@mui/icons-material/Add';
import ToolTip from '@mui/material/Tooltip';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import BlockIcon from "@mui/icons-material/Block";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const ActionButton = ({ handleClick, type, label }) => {

  const getIcon = () => {
    switch (type) {
      case 'edit':
        return <BorderColorOutlinedIcon />
      case 'add':
        return <AddIcon />
      case 'delete':
        return <DeleteOutlineOutlinedIcon />
      case 'addUser':
        return <PersonAddAltOutlinedIcon />
      case 'disable':
        return <BlockIcon />
      case 'enable':
        return <RestartAltIcon />
      default:
        return <BorderColorOutlinedIcon />
    }
  }

  const getStyle = () => {
    switch (type) {
      case 'edit':
        return { border: '1px solid #0D954780', borderRadius: 0, m: '0.25rem', color: '#0D9547' }
      case 'add':
        return { border: '1px solid rgba(248, 154, 14, 0.3)', borderRadius: 0, m: '0.25rem', color: '#F89A0E' }
      case 'delete':
        return { border: '1px solid #EC10104D', borderRadius: 0, m: '0.25rem', color: '#EC1010' }
      case 'addUser':
        return { border: '1px solid rgba(227, 227, 227, 1)', borderRadius: 0, m: '0.25rem', color: '#535252' }
      case 'disable':
        return { border: '1px solid rgba(128, 128, 128, 0.5)', borderRadius: 0, m: '0.25rem', color: '#808080' }
      case 'enable':
        return { border: '1px solid #ADD8E6', borderRadius: 0, m: '0.25rem', color: '#ADD8E6' }
      default:
        return { border: '1px solid #0D954780', borderRadius: 0, m: '0.25rem', color: '#0D9547' }
    }
  }

  return (
    <ToolTip title={label || ""}>
      <IconButton
        size='small'
        onClick={() => handleClick()}
        sx={getStyle()}
      >
        {getIcon()}
      </IconButton>
    </ToolTip>
  );
}

export default ActionButton;

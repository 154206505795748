import React, {useState} from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  gridContainer: {
    padding: '2rem'
  },
  newButton: {
    float: 'right'
  },
  title: {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: '#5a97fa',
    fontWeight: '600'
  },
  searchBar: {
    margin: '2rem 0 1rem'
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

function SearchTextBar(props) {
  const {onChange} = props;
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = async (e) => {
    const letter = e.target.value;
    setSearchValue(letter);
    if(letter.length > 3) {
      onChange(searchValue);
      setIsSearching(true);
    }
    if(letter.length === 0) {
      await clearBar();
    }
  }

  const handleSearchForm = async (e) => {
    e.preventDefault();
    onChange(searchValue);
    setIsSearching(true);
  }

  const handleCancel = async (e) => {
    e.preventDefault();
    await clearBar();
  }

  const clearBar = async () => {
    setSearchValue('');
    setIsSearching(false);
    onChange('')
  }

  return (
    <Paper component="form" className={classes.searchForm} onSubmit={handleSearchForm}>
      <InputBase
        className={classes.input}
        style={{paddingLeft: '1rem'}}
        value={searchValue}
        onChange={handleSearch}
        placeholder="Search..."
        inputProps={{ 'aria-label': 'search google maps' }}
        sx={{ height: '43px' }}
      />
      {!isSearching ? 
        ( <IconButton type="submit"  className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>)
      : (
        <IconButton type="button" onClick={(e) => handleCancel(e)}  className={classes.iconButton} aria-label="search">
          <CancelIcon />
        </IconButton>
      )}
      
    </Paper>
  )
}

SearchTextBar.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default SearchTextBar